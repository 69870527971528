import React from 'react'
import PageLayout from '../../shared/components/PageLayout'

const PrivacyPolicy = () => {
  return (
    <PageLayout title='Privacy Policy'>
      <h2 class='text-1xl font-bold mt-6 mb-4'>Introduction</h2>
      <p class='mb-4'>
        Gay Camping Friends provides a community platform at gaycampingfriends.com for users to discover, share, and discuss campground information,
        experiences, and related user-generated content. This Privacy Policy details the handling, use, and sharing of information collected through
        our website.
      </p>

      <div class='bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4' role='alert'>
        <p class='font-bold'>Beta Version Notice</p>
        <p>
          Please note that Gay Camping Friends is currently in a beta testing phase. Features, both usability and legal, may not be fully developed.
          Features and functionality may be subject to change. The site is continuously being improved, and user feedback is vital for these
          enhancements. We appreciate your understanding and patience during this testing period. Use of this beta version is at your own risk. If
          using this site would violate laws in your country, do not use it.
        </p>
      </div>

      <h2 class='text-1xl font-bold mt-6 mb-4'>Information Collection</h2>
      <p class='mb-2'>
        <strong>Public Data:</strong> We collect information that you choose to share publicly, including but not limited to:
      </p>
      <ul class='list-disc list-inside mb-4'>
        <li>User profile data (e.g., display name, profile photos, interests)</li>
        <li>Trip details including locations</li>
        <li>Uploaded photos and content</li>
        <li>Display names and preferred pronouns</li>
        <li>User-generated content in public forums</li>
      </ul>

      <p class='mb-2'>
        <strong>Secure Data:</strong> This includes sensitive information that is not made publicly visible:
      </p>
      <ul class='list-disc list-inside mb-4'>
        <li>Email addresses</li>
        <li>First and last names (unless part of the display name)</li>
        <li>Passwords</li>
      </ul>

      <p class='mb-2'>
        <strong>Additional Data:</strong> Any other information users may choose to provide that could become public based on their settings or the
        nature of the interactions.
      </p>

      <h2 class='text-1xl font-bold mt-6 mb-4'>Use of Information</h2>
      <p class='mb-4'>
        The information collected is used to enhance and personalize user experience on our site, enable user interaction and community engagement,
        provide insights and social features related to camping sites, and administer and improve the website.
      </p>

      <h2 class='text-1xl font-bold mt-6 mb-4'>Information Sharing and Disclosure</h2>
      <p class='mb-4'>
        User information may be disclosed under the following circumstances:
        <ul class='list-disc list-inside'>
          <li>To partners or third parties if a user consents to such sharing.</li>
          <li>As part of a corporate merger, acquisition, or other transfer of assets.</li>
          <li>If required by law, such as in response to a subpoena or legal process.</li>
        </ul>
        While your email and personal information are not currently used for promotional purposes, we reserve the right to change this policy. We will
        update our users via this Privacy Policy if plans change.
      </p>

      <h2 class='text-1xl font-bold mt-6 mb-4'>User Rights and Consent</h2>
      <p class='mb-4'>
        Users can review, modify, or delete their public and private information through their profile settings. By using our site, you agree to the
        collection and use of information in accordance with this policy.
      </p>

      <h2 class='text-1xl font-bold mt-6 mb-4'>Data Security</h2>
      <p class='mb-4'>
        We employ data storage and security measures on Firebase to protect against unauthorized access to or alteration of user data.
      </p>

      <h2 class='text-1xl font-bold mt-6 mb-4'>Ads and Tracking</h2>
      <p class='mb-4'>
        We utilize advertising services such as AdSense to support our site. Advertisements are served based on content relevance and user
        preferences.
      </p>

      <h2 class='text-1xl font-bold mt-6 mb-4'>Children's Privacy</h2>
      <p class='mb-4'>
        Our service is not directed to individuals under the age of 18. We do not knowingly collect personal identifiable information from children
        under 18. If it is discovered that a child under 18 has provided us with personal information, we immediately delete this from our servers.
      </p>

      <h2 class='text-1xl font-bold mt-6 mb-4'>International Use</h2>
      <p class='mb-4'>
        Our website operates globally and may not be designed to comply with specific local laws outside of the United States. If you are accessing
        our site from other jurisdictions, you are agreeing to comply with all local laws regarding your right to use the service.
      </p>

      <h2 class='text-1xl font-bold mt-6 mb-4'>Changes to Privacy Policy</h2>
      <p class='mb-4'>
        We may update our Privacy Policy periodically without notice. We encourage users to frequently check this page for any changes. Continued use
        of the site after any change will constitute your acceptance of such changes.
      </p>

      <h2 class='text-1xl font-bold mt-6 mb-4'>Effective Date</h2>
      <p class='mb-4'>The policies stated here are effective as of 4/13/2024.</p>
    </PageLayout>
  )
}

export default PrivacyPolicy
