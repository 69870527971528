import React, { useState, useEffect } from 'react'
import tripRepository from '../../../../../repositories/firestore/trips.firestore.repository'
import CalendarDisplay from './CalendarDisplay'
import { startOfMonth, endOfMonth, eachDayOfInterval, format, addMonths, subMonths, parseISO } from 'date-fns'

const formatMonth = (yearMonth) => {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  const [year, month] = yearMonth.split('-')
  const monthIndex = parseInt(month, 10) - 1 // Convert "04" to 3 for April
  return `${monthNames[monthIndex]} ${year}` // Output "April 2024"
}

const CampgroundTripSummary = ({ campgroundId }) => {
  const [tripSummaryByMonth, setTripSummaryByMonth] = useState({})
  const [currentMonth, setCurrentMonth] = useState(format(new Date(), 'yyyy-MM'))
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    console.log('Current Month:', currentMonth)
    fetchTrips()
  }, [campgroundId, currentMonth])

  const createUTCDate = (dateString) => {
    const date = new Date(dateString)
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  }

  const createMonthlySummary = (trips, year, month) => {
    // Start and end dates at the beginning and end of the month
    const start = startOfMonth(new Date(year, month - 1))
    const end = endOfMonth(new Date(year, month - 1))

    // Generate dates for each day of the month
    let summary = eachDayOfInterval({ start, end }).map((day) => ({
      date: format(day, 'yyyy-MM-dd'), // Format each day as "YYYY-MM-DD"
      count: 0,
      userIds: [],
    }))

    trips.forEach((trip) => {
      // Start date and end date converted to JavaScript Date objects and then to YYYY-MM-DD format
      let currentDate = new Date(trip.startDate) // Assuming startDate is already an ISO string
      const endDate = new Date(trip.endDate) // Assuming endDate is already an ISO string

      // Loop through each day from start date to end date
      while (currentDate <= endDate) {
        let formattedDate = currentDate.toISOString().split('T')[0] // Get the date part only in YYYY-MM-DD format
        summary.forEach((dayObj) => {
          if (dayObj.date === formattedDate) {
            dayObj.count += 1
            if (!dayObj.userIds.includes(trip.userId)) {
              dayObj.userIds.push(trip.userId)
            }
          }
        })

        // Increment the date by one day
        currentDate.setDate(currentDate.getDate() + 1)
      }
    })
    console.log('summary:', JSON.stringify(summary))

    return summary
  }

  const fetchTrips = async () => {
    setLoading(true)
    const [year, month] = currentMonth.split('-').map(Number)

    try {
      const trips = await tripRepository.getTripsByCampgroundByMonthYear(campgroundId, year, month)
      console.log(JSON.stringify(trips))
      const summary = createMonthlySummary(trips, year, month)
      setTripSummaryByMonth({ [currentMonth]: summary })
      setLoading(false)
    } catch (error) {
      console.error('Failed to fetch trips:', error)
      setLoading(false)
    }
  }

  const handleNextMonth = (event) => {
    event.preventDefault() // Prevents the default form submission if wrapped in a form
    const current = parseISO(currentMonth + '-01') // Ensures the date is treated as ISO format
    const nextMonthDate = addMonths(current, 1) // Adds one month
    const nextMonthStr = format(nextMonthDate, 'yyyy-MM', { awareOfUnicodeTokens: true })
    console.log('Next Month:', nextMonthStr)
    setCurrentMonth(nextMonthStr)
  }

  const handlePreviousMonth = (event) => {
    event.preventDefault() // Prevents the default form submission
    const current = parseISO(currentMonth + '-01')
    const prevMonthDate = subMonths(current, 1) // Subtracts one month
    const prevMonthStr = format(prevMonthDate, 'yyyy-MM', { awareOfUnicodeTokens: true })
    console.log('Previous Month:', prevMonthStr)
    setCurrentMonth(prevMonthStr)
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className='mx-auto w-full py-6 px-4 sm:px-6 lg:px-8 bg-white rounded-lg shadow-md mb-8'>
      <h3 className='text-lg font-semibold mb-4'>Members Planning to Visit</h3>
      <div className='grid grid-cols-3 items-center mb-4'>
        <button onClick={handlePreviousMonth} type='button' className={`px-4 py-2 rounded-lg font-medium text-blue-600`}>
          Previous
        </button>

        <h3 className='text-lg font-semibold text-center'>{formatMonth(currentMonth)}</h3>

        <button onClick={handleNextMonth} type='button' className={`px-4 py-2 rounded-lg font-medium text-blue-600`}>
          Next
        </button>
      </div>
      {tripSummaryByMonth[currentMonth] ? (
        <div>
          <CalendarDisplay summary={tripSummaryByMonth[currentMonth]} />
        </div>
      ) : (
        'No Trips this Month'
      )}
    </div>
  )
}

export default CampgroundTripSummary
