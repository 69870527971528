import React, { createContext, useContext, useEffect, useState } from 'react'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import { getApp } from 'firebase/app'
import userRepository from '../repositories/firestore/users.firestore.repository'

const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  // Separate state for public and secure user details
  const [publicUserDetails, setPublicUserDetails] = useState({})
  const [secureUserDetails, setSecureUserDetails] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const auth = getAuth(getApp())
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userData = await userRepository.getUserDataById(user.uid)
        if (userData) {
          const { public: publicData, secure: secureData } = userData
          // Separate handling for public and secure data

          setPublicUserDetails(publicData)
          setSecureUserDetails(secureData)

          // Handle login points and last login date updates here or elsewhere as needed
        }
        setCurrentUser(user)
      } else {
        setCurrentUser(null)
        setPublicUserDetails({})
        setSecureUserDetails({})
      }
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  const logout = async () => {
    const app = getApp()
    const auth = getAuth(app)
    await signOut(auth)
    setCurrentUser(null)
    setPublicUserDetails({})
    setSecureUserDetails({})
  }

  const value = {
    currentUser,
    userDetails: { public: publicUserDetails, secure: secureUserDetails },
    logout,
  }

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>
}
