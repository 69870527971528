import React, { useEffect, useState } from 'react'
import EventItem from './EventItem'
import eventsRepository from '../../../../repositories/firestore/events.firestore.repository'
import { startOfWeek, endOfWeek, addWeeks, subWeeks, format } from 'date-fns'

const ThisWeekEventList = () => {
  const [currentDate, setCurrentDate] = useState(new Date())
  const [events, setEvents] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  // This function will give us the string representing the current week range
  const formatWeekRange = (date) => {
    const start = startOfWeek(date, { weekStartsOn: 0 })
    const end = endOfWeek(date, { weekStartsOn: 0 })
    return `${format(start, 'MMM dd, yyyy')} - ${format(end, 'MMM dd, yyyy')}`
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await eventsRepository.getEventsByWeek(currentDate)
        console.log(data)
        setEvents(data)
        setLoading(false)
      } catch (error) {
        setError(error)
        setLoading(false)
      }
    }

    fetchData()
  }, [currentDate])

  const handlePreviousWeek = () => {
    setCurrentDate(subWeeks(currentDate, 1))
  }

  const handleNextWeek = () => {
    setCurrentDate(addWeeks(currentDate, 1))
  }

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <>
      <div className='flex justify-between items-center mb-4 px-4 sm:px-0'>
        <button className='text-white bg-blue-500 hover:bg-blue-700 font-medium py-1 px-2 rounded' onClick={handlePreviousWeek}>
          Previous
        </button>
        <span className='text-sm sm:text-base font-semibold'>{formatWeekRange(currentDate)}</span> {/* Displaying the week range */}
        <button className='text-white bg-blue-500 hover:bg-blue-700 font-medium py-1 px-2 rounded' onClick={handleNextWeek}>
          Next
        </button>
      </div>
      {events && events.length > 0 ? (
        <div className='flex flex-col'>
          {events.map((event) => (
            <EventItem
              key={event.id}
              campgroundName={event.campgroundName}
              campgroundId={event.campgroundId}
              campgroundCity={event.campgroundCity}
              campgroundState={event.campgroundState}
              title={event.title}
              description={event.description}
              startDate={new Date(event.startDate).toLocaleDateString('en-US')}
              endDate={new Date(event.endDate).toLocaleDateString('en-US')}
              graphicSrc={event.coverImage}
              id={event.id}
            />
          ))}
        </div>
      ) : (
        <div className='mx-auto w-full py-6 px-4 sm:px-6 lg:px-8 bg-white rounded-lg shadow-md mb-4'>
          <h3 className='text-lg font-semibold mb-4'>No Events ☹️</h3>
          <p className='mt-2'>There are no events scheduled this week. Please contact campgrounds directly or visit their website.</p>
          <p className='mt-2'>
            Campgrounds, please contact info@gaycampingfriends.com to learn how to manage your campground and events on our website.
          </p>
        </div>
      )}
    </>
  )
}

export default ThisWeekEventList
