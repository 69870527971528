import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import AdComponent from './AdComponent'
import ChatAdComponent from './ChatAdComponent'
import Header from './Header'
import { sanitizeHTML, trimText } from '../../../utils/messageUtils'

const PageLayout = ({ children, title, metaTitle, metaDescription, metaImage, showAds = true }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const defaultTitle = 'Gay Campground Directory, Map, Information, and Events - Gay Camping Friends'
  const defaultDescription =
    'Gay Camping Friends is your ultimate guide to LGBTQ+ camping. Connect with a vibrant community, find inclusive campgrounds, and share memorable outdoor experiences with friends old and new.'
  const defaultImage = 'https://gaycampingfriends.com/default-image.png'

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // Sanitize and trim content for SEO
  const seoTitle = sanitizeHTML(trimText(metaTitle || defaultTitle, 70)) // Titles are best kept under 70 characters
  const seoDescription = sanitizeHTML(trimText(metaDescription || defaultDescription, 160)) // Descriptions should be under 160 characters

  return (
    <div className='w-full'>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name='description' content={seoDescription} />
        <meta property='og:title' content={seoTitle} />
        <meta property='og:description' content={seoDescription} />
        <meta property='og:image' content={metaImage || defaultImage} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content={seoTitle} />
        <meta name='twitter:description' content={seoDescription} />
        <meta name='twitter:image' content={metaImage || defaultImage} />
      </Helmet>
      {showAds && <div className='mb-4'>{isMobile ? <ChatAdComponent slot='6518132610' key='top-ad' /> : <AdComponent slot='9099397642' />}</div>}
      {title && <Header title={title} />}
      {children}
      {showAds && (
        <div className='mt-4'>
          <AdComponent slot='6421834235' />
        </div>
      )}
    </div>
  )
}

export default PageLayout
