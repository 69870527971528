import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import eventsRepository from '../../../../repositories/firestore/events.firestore.repository'

const EventsList = () => {
  const [events, setEvents] = useState([])

  const fetchEvents = async () => {
    try {
      const fetchedEvents = await eventsRepository.getAllEvents()
      if (Array.isArray(fetchedEvents)) {
        setEvents(fetchedEvents)
      } else {
        // If fetched data is not an array, log an error or handle as appropriate
        console.error('Fetched events is not an array:', fetchedEvents)
        setEvents([]) // Ensure state is set to an empty array to avoid errors
      }
    } catch (error) {
      console.error('Error fetching events:', error)
      setEvents([]) // Ensure state is reset in case of error
    }
  }

  useEffect(() => {
    fetchEvents()
  }, [])

  const deleteEvent = async (eventId) => {
    // Confirm with the user
    const isConfirmed = window.confirm('Are you sure you want to delete this event?')
    if (isConfirmed) {
      try {
        await eventsRepository.deleteEvent(eventId)
        fetchEvents() // Refresh the events list after deletion
        console.log(`Event with ID ${eventId} deleted successfully.`)
      } catch (error) {
        console.error(`Error deleting event with ID ${eventId}:`, error)
      }
    }
  }

  return (
    <div className='w-full px-4 mt-10'>
      {/* Header and Add New Event Button Container */}
      <div className='flex justify-between items-center mb-4'>
        <h2 className='text-3xl font-bold'>Manage Events</h2>
        <div>
          <Link to='/admin/event' className='bg-green-500 text-white py-2 px-6 mx-2 rounded hover:bg-green-700 transition-colors duration-200'>
            Add New Event
          </Link>
          <Link to='/admin/campground-event' className='bg-green-500 text-white py-2 px-6 rounded hover:bg-green-700 transition-colors duration-200'>
            Add New Campground Event
          </Link>
        </div>
      </div>
      <div className='space-y-2'>
        <div className='mb-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
          {events.map((event) => (
            <div className='bg-white rounded-lg shadow-md p-6' key={event.id}>
              <Link to={`/admin/event/${event.id}`}>
                <img src={event.coverImage} alt={event.title} className='w-full h-auto mb-4 rounded-lg' />
                <h3 className='text-xl font-semibold mb-2'>{event.title}</h3>
                <p className='text-gray-500 mb-2'>
                  {event.startDate.toDate().toLocaleDateString('en-US')} - {event.endDate.toDate().toLocaleDateString('en-US')}
                </p>
                <p className='text-gray-500 mb-4'>{event.location}</p>
              </Link>
              <button
                onClick={() => deleteEvent(event.id)}
                className='bg-red-500 text-white py-2 px-6 rounded hover:bg-red-700 transition-colors duration-200 mt-4 md:mt-0'>
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default EventsList
