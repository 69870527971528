import React, { useEffect, useState } from 'react'
import { useParams, Navigate } from 'react-router-dom'
import campgroundRepository from '../repositories/firestore/campgrounds.firestore.repository'

function RedirectCampgroundToSlug() {
  const { campgroundId } = useParams()
  const [slug, setSlug] = useState('')

  useEffect(() => {
    const fetchSlug = async () => {
      try {
        const campgroundData = await campgroundRepository.getCampgroundById(campgroundId)
        if (campgroundData && campgroundData.slug) {
          setSlug(campgroundData.slug)
        } else {
          console.error('Campground not found')
          // Optionally handle the error case, maybe redirect to a 'not found' page or back to a default page
        }
      } catch (error) {
        console.error('Failed to fetch campground slug:', error)
        // Handle errors appropriately
      }
    }

    fetchSlug()
  }, [campgroundId])

  return slug ? <Navigate to={`/campground/${slug}`} replace /> : <div>Loading...</div>
}

export default RedirectCampgroundToSlug
