import React from 'react'
import PageLayout from '../../shared/components/PageLayout'

const TermsConditions = () => {
  return (
    <PageLayout title='Terms and Conditions'>
      <div className='space-y-4'>
        <h2 className='text-lg font-bold'>Introduction</h2>
        <p>
          Welcome to Gay Camping Friends! Our website is dedicated to providing a vibrant community platform where campers can share experiences,
          discover campgrounds, and interact through user-generated content. We aim to facilitate a rich, engaging experience for all our members.
        </p>

        <h2 className='text-lg font-bold'>Acceptance of Terms</h2>
        <p>
          By accessing and using our website, you signify your agreement to be bound by these terms and conditions. If you do not agree with any part
          of the terms, you should discontinue use of our site immediately.
        </p>

        <h2 className='text-lg font-bold'>Data Collection and Usage</h2>
        <p>
          We collect and use your data as detailed in our{' '}
          <a href='/privacy-policy' className='text-blue-600 hover:text-blue-800'>
            Privacy Policy
          </a>
          . By using Gay Camping Friends, you consent to such collection and use of your information. We use this data to enhance your experience,
          manage our platform, and communicate with you, among other purposes outlined in our Privacy Policy. Your data is used not only to support
          the functionality and accessibility of our platform but also for internal analytics, service improvement, and, as outlined in our Privacy
          Policy, potential sharing with third parties under specific circumstances. This sharing will only occur with your explicit consent or as
          legally required.
        </p>

        <h2 className='text-lg font-bold'>Consent for Changes</h2>
        <p>By continuing to use our services after changes to these terms, you consent to the revised policies.</p>

        <h2 className='text-lg font-bold'>Advertisements and Third-Party Links</h2>
        <p>
          Our website displays advertisements from third parties such as AdSense, which may collect user data through tracking technologies. We are
          not responsible for the content or privacy practices of these third-party websites. For more details on how advertising data is handled,
          please refer to our{' '}
          <a href='/privacy-policy' className='text-blue-600 hover:text-blue-800'>
            Privacy Policy
          </a>
          .
        </p>

        <h2 className='text-lg font-bold'>Privacy Practices</h2>
        <p>
          Please refer to our{' '}
          <a href='/privacy-policy' className='text-blue-600 hover:text-blue-800'>
            Privacy Policy
          </a>{' '}
          for detailed information on how we collect, use, and protect your personal information, and how you can manage your preferences and rights
          regarding this information.
        </p>

        <h2 className='text-lg font-bold'>User Accounts</h2>
        <h3 className='text-md font-semibold'>Registration Requirements</h3>
        <p>
          You agree to provide accurate, complete, and up-to-date information during registration and to update such information to keep it accurate,
          complete, and up-to-date..
        </p>

        <h3 className='text-md font-semibold'>Account Security</h3>
        <p>
          You are responsible for all activities that occur under your account and agree to maintain the security of your password. You must notify us
          immediately upon discovering any breach of security or unauthorized use of your account.
        </p>

        <h3 className='text-md font-semibold'>User Responsibility</h3>
        <p>
          You are responsible for your own actions and the content you post. You agree to comply with all local laws regarding online conduct and
          acceptable content.
        </p>

        <h2 className='text-lg font-bold'>User Conduct</h2>
        <h3 className='text-md font-semibold'>Content Standards</h3>
        <p>
          Content must be respectful, relevant, and legal. Explicitly banned content includes hate speech, harassment, explicit content, and any
          illegal activities.
        </p>

        <h3 className='text-md font-semibold'>Prohibited Activities</h3>
        <p>
          Prohibited activities include, but are not limited to, impersonating others, spreading malware, unauthorized advertising, and infringing on
          others' intellectual property rights.
        </p>

        <h2 className='text-lg font-bold'>User Content</h2>

        <h3 className='text-md font-semibold'>User Generated Content</h3>
        <p>
          Your interactions on the site, including content creation, comments, and participation in discussions, are governed by both these Terms and
          our Community Guidelines as detailed in the Privacy Policy. Please ensure your contributions adhere to these guidelines to maintain a
          respectful and legal online community.
        </p>

        <h3 className='text-md font-semibold'>Ownership and Rights</h3>
        <p>
          While users retain ownership over their content, posting content on our site grants us a non-exclusive, royalty-free, perpetual, and global
          license to use, reproduce, modify, publish, translate, create derivative works from, distribute, perform, and display such content
          throughout the world in any media.
        </p>

        <h3 className='text-md font-semibold'>Content Review</h3>
        <p>
          All content posted on our platform may be reviewed by site administrators to ensure compliance with our content standards. However, user
          content is generally posted to the site directly without prior moderation.
        </p>

        <h3 className='text-md font-semibold'>Removal of Content</h3>
        <p>
          We reserve the right to remove any content at any time without prior notice for any reason, including content that we determine violates our
          community standards or these terms.
        </p>

        <h2 className='text-lg font-bold'>Intellectual Property</h2>
        <h3 className='text-md font-semibold'>Site Content Ownership</h3>
        <p>
          All original content on Gay Camping Friends, including text, graphics, logos, images, as well as the compilation thereof, and any software
          used on the site, is the exclusive property of Gay Camping Friends and is protected by copyright, trademark legislation, and other
          intellectual property laws.
        </p>

        <h3 className='text-md font-semibold'>User Licenses</h3>
        <p>
          By posting, uploading, inputting, providing, or submitting your Submission, you are granting Gay Camping Friends, its affiliated companies,
          and necessary sublicensees a non-exclusive, worldwide, perpetual, royalty-free, right and license to use your Submission in connection with
          the operation of their Internet businesses including, without limitation, the rights to copy, distribute, transmit, publicly display,
          publicly perform, reproduce, edit, translate, and reformat your Submission; and to publish your name in connection with your Submission. The
          licenses granted by you to Gay Camping Friends for the use of your user-generated content are detailed in our Privacy Policy. These licenses
          help us to promote, operate, and improve the services and extend the reach of your content across our platforms and partner networks.
        </p>

        <h2 className='text-lg font-bold'>Privacy Policy</h2>
        <p>
          We value your privacy and encourage you to read our{' '}
          <a href='/privacy-policy' className='text-blue-600 hover:text-blue-800'>
            Privacy Policy
          </a>{' '}
          to understand how we collect, use, and safeguard the personal information you provide to us.
        </p>

        <h2 className='text-lg font-bold'>Disclaimers and Limitation of Liability</h2>
        <h3 className='text-md font-semibold'>No Warranty</h3>
        <p>
          The services provided by Gay Camping Friends are available on an "as is" and "as available" basis without any warranties of any kind, either
          express or implied, including, but not limited to, implied warranties of merchantability or fitness for a particular purpose. We do not
          warrant that the site will always be available, uninterrupted, timely, secure, or free from errors.
        </p>

        <h3 className='text-md font-semibold'>Limitation of Liability</h3>
        <p>
          In no event will Gay Camping Friends, its affiliates, agents, directors, employees, suppliers, or licensors be liable for any indirect,
          punitive, incidental, special, consequential, or exemplary damages, including without limitation damages for loss of profits, goodwill, use,
          data, or other intangible losses that result from the use of, or inability to use, this service. Under no circumstances will Gay Camping
          Friends be responsible for any damage, loss, or injury resulting from hacking, tampering, or other unauthorized access or use of the service
          or your account or the information contained therein.
        </p>

        <h2 className='text-lg font-bold'>Changes to Terms</h2>
        <p>
          We reserve the right to modify these terms at any time. Notice of modifications will be provided on this page and updated in the Privacy
          Policy as applicable. Your continued use of our website after any such changes constitutes your acceptance of the new terms. If you do not
          agree to any updates, you should stop using our services immediately.
        </p>

        <h2 className='text-lg font-bold'>Governing Law and Jurisdiction</h2>
        <p>
          The Terms and Conditions of Gay Camping Friends are governed and construed in accordance with the laws of the State of Texas, United States.
          Any disputes arising from these terms will be subject to the exclusive jurisdiction of the state and federal courts located in Shelby
          County, Texas.
        </p>

        <h2 className='text-lg font-bold'>Dispute Resolution</h2>
        <p>
          We encourage you to contact us directly at info@gaycampingfriends.com to resolve any issues before resorting to formal legal channels. We
          are committed to resolving disputes amicably and efficiently. If a dispute cannot be resolved through our internal processes, you may
          consider pursuing claims as explained under the Governing Law and Jurisdiction section.
        </p>

        <h2 className='text-lg font-bold'>Severability and Waiver</h2>
        <p>
          If any provision of these Terms is found to be unlawful, void, or unenforceable, that provision will be deemed severable from these Terms
          and does not affect the validity and enforceability of any remaining provisions. A waiver by either party of any term or condition or any
          breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof.
        </p>

        <h2 className='text-lg font-bold'>Encforcement Actions</h2>
        <p>
          We reserve the right to take appropriate actions against any violations of these terms, which may include removing content, banning
          accounts, or contacting law enforcement authorities if the situation warrants such action.
        </p>

        <h2 className='text-lg font-bold'>Contact Information</h2>
        <p>For any questions about these Terms, the practices of this site, or your dealings with this site, please contact us at:</p>
        <ul className='list-disc pl-5'>
          <li>
            Email:{' '}
            <a href='mailto:info@gaycampingfriends.com' className='text-blue-600 hover:text-blue-800'>
              info@gaycampingfriends.com
            </a>
          </li>
        </ul>

        <h2 className='text-lg font-bold'>Effective Date</h2>
        <p>The policies stated here are effective as of 4/13/2024.</p>

        <h2 className='text-lg font-bold'>Conclusion</h2>
        <p>
          Thank you for choosing Gay Camping Friends. We value your participation in our community and are committed to providing an enjoyable and
          safe experience. If you have any questions or need assistance with your account, please do not hesitate to reach out to us at
          info@gaycampingfriends.com.
        </p>
      </div>
    </PageLayout>
  )
}

export default TermsConditions
