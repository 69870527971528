import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const EventItem = ({ title, startDate, endDate, location, description, graphicSrc, id }) => {
  const formatDate = (date) => {
    const newDate = new Date(date)
    return `${newDate.toLocaleString('en-US', { month: 'short' }).toUpperCase()} ${newDate.getDate()}`
  }

  const stripHtml = (html) => {
    if (!html) return ''
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = html
    return tempDiv.textContent || tempDiv.innerText || ''
  }

  return (
    <Link to={`/campground-event/${id}`} className='no-underline text-black'>
      <div className='bg-white rounded-lg shadow-md p-4 mb-2 flex'>
        <div className='flex-none w-16 h-16 md:w-20 md:h-20 mr-2 md:mr-4 flex flex-col justify-center items-center bg-gray-100 rounded-lg text-xs md:text-base'>
          <p className='font-semibold text-gray-700'>{formatDate(startDate)}</p>
          <p className='font-semibold text-gray-700'>{formatDate(endDate)}</p>
        </div>
        <div className='flex-grow flex'>
          <div className='flex-none w-24 h-24 md:w-32 md:h-32 mr-2 md:mr-4 overflow-hidden rounded-lg'>
            <img src={graphicSrc} alt={title} className='object-contain w-full h-full' style={{ objectPosition: 'top center' }} />
          </div>
          <div className='flex-grow'>
            <h3 className='text-lg md:text-xl font-semibold'>{title}</h3>
            <p className='text-gray-500 text-xs md:text-sm'>{location}</p>
            <p className='text-gray-500 mt-2 text-xs md:text-sm truncate-multi-line'>{stripHtml(description)}</p>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default EventItem
