import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <nav className="text-sm mb-4">
      <ol className="list-none p-0 inline-flex">
        {breadcrumbs.map((breadcrumb, index) => {
          const isLast = index === breadcrumbs.length - 1;

          return (
            <li
              key={breadcrumb.path}
              className={`flex items-center ${
                isLast ? "text-gray-600" : "text-blue-500"
              }`}
            >
              {isLast ? (
                <span>{breadcrumb.label}</span>
              ) : (
                <Link to={breadcrumb.path} className="hover:underline">
                  {breadcrumb.label}
                </Link>
              )}

              {!isLast && <span className="mx-2">{"/"}</span>}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
