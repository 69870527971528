import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom' // Import useParams hook
import userRepository from '../../../repositories/firestore/users.firestore.repository'
import ProfilePhoto from '../../shared/components/ProfilePhoto'
import { FaFacebook, FaInstagram, FaTwitter, FaTiktok, FaDiscord, FaSpotify, FaTwitch, FaSnapchatGhost } from 'react-icons/fa'
import { Country, State } from 'country-state-city'
import PageLayout from '../../shared/components/PageLayout'

const PublicProfile = () => {
  const { userId } = useParams() // Get userId from URL params
  const [userData, setUserData] = useState({})

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = await userRepository.getUserPublicDataById(userId)
        setUserData(user)
      } catch (error) {
        console.error('Error fetching user data:', error)
      }
    }

    fetchUserData()
  }, [userId])

  if (!userData) {
    return <div>Loading...</div>
  }

  const socialLinks = {
    facebook: { icon: <FaFacebook size={24} />, urlPrefix: 'https://www.facebook.com/' },
    instagram: { icon: <FaInstagram size={24} />, urlPrefix: 'https://www.instagram.com/' },
    twitter: { icon: <FaTwitter size={24} />, urlPrefix: 'https://twitter.com/' },
    tiktok: { icon: <FaTiktok size={24} />, urlPrefix: 'https://www.tiktok.com/@' },
    discord: { icon: <FaDiscord size={24} />, urlPrefix: 'https://discord.com/users/' },
    spotify: { icon: <FaSpotify size={24} />, urlPrefix: 'https://open.spotify.com/user/' },
    twitch: { icon: <FaTwitch size={24} />, urlPrefix: 'https://www.twitch.tv/' },
    snapchat: { icon: <FaSnapchatGhost size={24} />, urlPrefix: 'https://www.snapchat.com/add/' },
  }

  const getCountryName = (isoCode) => {
    const country = Country.getCountryByCode(isoCode)
    return country ? country.name : ''
  }

  const getStateName = (countryCode, stateCode) => {
    const state = State.getStateByCodeAndCountry(stateCode, countryCode)
    return state ? state.name : ''
  }

  return (
    <PageLayout title='User Profile'>
      <div className='bg-white shadow rounded-lg p-4 mb-8'>
        <div className='flex flex-col md:flex-row items-start md:items-center'>
          <ProfilePhoto src={userData.profilePhotoUrl} displayName={userData.displayName} size='24' className='mb-4 md:mb-0 md:mr-8' />

          <div className='flex-grow w-full'>
            <h1 className='text-2xl font-bold'>
              {userData.displayName} {userData.pronouns && '(' + userData.pronouns + ')'}
            </h1>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-4 md:mt-4'>
              <div className='flex flex-col justify-between'>
                <p>
                  <strong>GCF Points:</strong> {userData.totalPoints}
                </p>
                <p>
                  <strong>Location:</strong> {userData.country ? getCountryName(userData.country) : ''}
                  {userData.state ? ', ' + getStateName(userData.country, userData.state) : ''}
                </p>
                <p>
                  <strong>Last Active:</strong> {new Date(userData.lastLoginDate).toLocaleDateString()}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-white shadow rounded-lg p-4 mb-8'>
        <h2 className='text-1xl font-semibold mb-2'>About</h2>
        <p>{userData.bio}</p>
      </div>
      <div className='bg-white shadow rounded-lg p-4 mb-8'>
        <h2 className='text-1xl font-semibold mb-4'>Preferences</h2>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          <p>
            <strong className='mr-2'>Experience Level:</strong>
            <span>{userData.experienceLevel || 'N/A'}</span>
          </p>
          <p>
            <strong className='mr-2'>Relationship Status:</strong>
            <span>{userData.relationshipStatus || 'N/A'}</span>
          </p>
          <p>
            <strong className='mr-2'>Open To Preferences:</strong>
            <span>{Array.isArray(userData.openToPreferences) ? userData.openToPreferences.join(', ') : 'N/A'}</span>
          </p>
          <p>
            <strong className='mr-2'>Camping Environment Preferences:</strong>
            <span>{Array.isArray(userData.campingEnvironmentPreferences) ? userData.campingEnvironmentPreferences.join(', ') : 'N/A'}</span>
          </p>
          <p>
            <strong className='mr-2'>Camping Type Preferences:</strong>
            <span>{Array.isArray(userData.campingTypePreferences) ? userData.campingTypePreferences.join(', ') : 'N/A'}</span>
          </p>
          <p>
            <strong className='mr-2'>Regions Preferences:</strong>
            <span>{Array.isArray(userData.regionsPreferences) ? userData.regionsPreferences.join(', ') : 'N/A'}</span>
          </p>
        </div>
      </div>
      <div className='bg-white shadow rounded-lg p-4'>
        <h2 className='text-1xl font-semibold mb-2'>Social Media</h2>
        <div className='flex flex-wrap'>
          {Object.keys(socialLinks).map(
            (platform) =>
              userData[platform] && (
                <a
                  key={platform}
                  href={`${socialLinks[platform].urlPrefix}${userData[platform]}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='p-2'>
                  {socialLinks[platform].icon}
                </a>
              ),
          )}
        </div>
      </div>
    </PageLayout>
  )
}

export default PublicProfile
