import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext' // Adjust import path as needed

const ProtectedRoute = ({ roles, component: Component }) => {
  const { currentUser, userDetails } = useAuth()
  const userRoles = userDetails?.secure.roles || [] // Default to an empty array if roles are not defined

  // If there's no current user, redirect to login
  if (!currentUser) {
    return <Navigate to='/auth/login' />
  }

  // Check if roles were provided and if the user has one of the required roles
  const hasRequiredRole = roles ? roles.some((role) => userRoles.includes(role)) : true

  // If user doesn't have the required role, redirect to home
  if (!hasRequiredRole) {
    return <Navigate to='/' />
  }

  // User is authenticated and has required role(s), render the component
  return <Component />
}

export default ProtectedRoute
