import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import chatRoomRepository from '../../../../repositories/firestore/chatRoom.firestore.repository'

const ChatRoomsList = () => {
  const [chatRooms, setChatRooms] = useState([])

  const fetchChatRooms = async () => {
    try {
      const fetchedChatRooms = await chatRoomRepository.getChatRooms()
      setChatRooms(fetchedChatRooms)
    } catch (error) {
      console.error('Error fetching chat rooms:', error)
      setChatRooms([])
    }
  }

  useEffect(() => {
    fetchChatRooms()
  }, [])

  const deleteChatRoom = async (chatRoomId) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this chat room?')
    if (isConfirmed) {
      try {
        await chatRoomRepository.deleteChatRoom(chatRoomId)
        fetchChatRooms() // Refresh the list
      } catch (error) {
        console.error(`Error deleting chat room with ID ${chatRoomId}:`, error)
      }
    }
  }

  return (
    <div className='container mx-auto p-4'>
      <div className='flex justify-between items-center mb-4'>
        <h1 className='text-2xl font-bold'>Manage Chat Rooms</h1>
        <Link to='/admin/chatroom' className='bg-green-500 text-white py-2 px-6 rounded hover:bg-green-700 transition-colors duration-200'>
          Add New Chat Room
        </Link>
      </div>
      {chatRooms.length > 0 ? (
        chatRooms.map((room) => (
          <div key={room.id} className='border-b py-4'>
            <h2 className='text-lg'>{room.name}</h2>
            <p>{room.description}</p>
            <div className='flex mt-2'>
              <button onClick={() => deleteChatRoom(room.id)} className='bg-red-500 text-white px-4 py-2 rounded'>
                Delete
              </button>
              <Link to={`/admin/chatroom/${room.id}`} className='bg-blue-500 text-white px-4 py-2 rounded ml-2'>
                Edit
              </Link>
            </div>
          </div>
        ))
      ) : (
        <p>No chat rooms available. Add new chat rooms to manage.</p>
      )}
    </div>
  )
}

export default ChatRoomsList
