import React from 'react'
import { Link } from 'react-router-dom'
import gayIcon from '../../../../img/icons/gay.png'
import lesbianIcon from '../../../../img/icons/lesbian.png'
import lgbtIcon from '../../../../img/icons/lgbt.png'
import lgbtOwnedIcon from '../../../../img/icons/lgbt-owned.png'

const Legend = () => {
  return (
    <div className='grid grid-cols-2 md:grid-cols-3 gap-4 pb-4'>
      <Link to={`/campground-category/lgbtq-campgrounds`}>
        <div className='p-2 flex items-center justify-center'>
          <img
            src={lgbtIcon}
            alt='Icon 1'
            className='w-6 h-6 mr-2' // Replace with your desired fixed dimensions for the icon
          />
          <label class='block lg:hidden'>LGBTQ+</label>
          <label class='hidden lg:block'>LGBTQ+ Campgrounds</label>
        </div>
      </Link>
      <Link to={`/campground-category/gay-campgrounds`}>
        <div className='p-2 flex items-center justify-center'>
          <img
            src={gayIcon}
            alt='Icon 2'
            className='w-6 h-6 mr-2' // Replace with your desired fixed dimensions for the icon
          />
          <label class='block lg:hidden'>Gay</label>
          <label class='hidden lg:block'>Gay Campgrounds</label>
        </div>
      </Link>
      <Link to={`/campground-category/lesbian-campgrounds`}>
        <div className='p-2 flex items-center justify-center'>
          <img
            src={lesbianIcon}
            alt='Icon 3'
            className='w-6 h-6 mr-2' // Replace with your desired fixed dimensions for the icon
          />
          <label class='block lg:hidden'>Lesbian</label>
          <label class='hidden lg:block'>Lesbian Campgrounds</label>
        </div>
      </Link>
      {/* <Link to={`/campground-category/lgbtq-owned-campgrounds`}>
        <div className='p-2 flex items-center justify-center'>
          <img
            src={lgbtOwnedIcon}
            alt='Icon 4'
            className='w-6 h-6 mr-2' // Replace with your desired fixed dimensions for the icon
          />
          <label class='block lg:hidden'>LGBTQ+ Owned (Soon)</label>
          <label class='hidden lg:block'>LGBTQ+ Owned (Soon)</label>
        </div>
      </Link> */}
    </div>
  )
}

export default Legend
