import React from 'react'
import EventList from '../../home/components/EventList'
import AdSense from 'react-adsense'
import PageLayout from '../../../shared/components/PageLayout'

const Events = () => {
  return (
    <PageLayout
      title='Events Home'
      metaTitle={'List of Gay Camping Friends Regional and National events.'}
      metaDescription={'This is a list of the upcoming Gay Camping Friends regional and national events.'}>
      <EventList sinceDate={new Date(new Date().getFullYear(), 0, 1)} />
    </PageLayout>
  )
}

export default Events
