import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import blogsRepository from '../../../../repositories/firestore/blogs.firestore.repository'
import BlogPostList from '../../home/components/BlogPostList'
import PageLayout from '../../../shared/components/PageLayout'

const BlogDetails = (params) => {
  let { slug } = useParams()

  const [blog, setBlog] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const fetchData = async () => {
    // Example: Scroll to the top when the component mounts

    try {
      const data = await blogsRepository.getBlogBySlug(slug)

      if (data) {
        setBlog(data)
        setLoading(false)
      } else {
        throw new Error('The requested blog could not be found.')
      }
    } catch (error) {
      setError(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    scrollToTop()
    fetchData()
  }, [])

  useEffect(() => {
    scrollToTop()
    fetchData()
  }, [slug])

  if (loading) {
    return <div>Loading Blog...</div>
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <>
      {blog && (
        <PageLayout metaTitle={`Blog - ${blog.title}`} metaDescription={blog.content}>
          <div className='flex flex-col px-4'>
            <div className='hover:underline text-blue-500 mb-4'>
              <Link to='/blog'>Blog Home</Link>
            </div>
            <div className='flex flex-col-reverse md:flex-row md:justify-between items-center'>
              <div className='flex flex-col items-center md:items-start'>
                <h2 className='text-3xl font-bold mb-4'>{blog.title}</h2>
                <div className='mb-2 text-xl text-gray-600'>
                  {blog.author} - {blog.timestamp.toDate().toLocaleDateString('en-US')}
                </div>
              </div>
            </div>
          </div>
          <div className='container mx-auto'>
            <div className='flex flex-col md:flex-row'>
              <div className='flex flex-col w-full md:w-2/3'>
                <div className='mx-auto w-full py-6 px-4 sm:px-6 lg:px-8 bg-white rounded-lg shadow-md mb-4'>
                  <div className='w-full flex justify-center'>
                    <img src={blog.featuredImage} alt={blog.title} className='mb-6 rounded-lg shadow-lg' />
                  </div>
                  <div className='prose lg:prose-xl mb-8 flex flex-col space-y-4' dangerouslySetInnerHTML={{ __html: blog.content }}></div>
                </div>
              </div>
              <div className='flex flex-col w-full md:w-1/3 md:pl-4'>
                <h3 className='text-lg font-semibold mb-2'>Latest Blogs</h3>
                <BlogPostList count={3} vertical={true} />
              </div>
            </div>
          </div>
        </PageLayout>
      )}
    </>
  )
}

export default BlogDetails
