import { collection, query, orderBy, doc, addDoc, updateDoc, getDoc, deleteDoc, getDocs } from 'firebase/firestore'
import baseRepository from './base.firestore.repository'

const forumsCollectionRef = collection(baseRepository.getDb(), 'forums')

let forumsCache = {
  allForums: {
    data: null,
    lastFetch: 0,
    cacheDuration: 1000 * 60 * 5, // 5 minutes
  },
  byId: {},
}

const getForums = async () => {
  const now = Date.now()
  if (forumsCache.allForums.data && now - forumsCache.allForums.lastFetch < forumsCache.allForums.cacheDuration) {
    return forumsCache.allForums.data
  }

  try {
    const q = query(forumsCollectionRef, orderBy('name', 'asc'))
    const forums = await baseRepository.getDocsFromQuery(q)
    forumsCache.allForums.data = forums
    forumsCache.allForums.lastFetch = now
    return forums
  } catch (error) {
    console.error('Error retrieving forums:', error)
    throw error
  }
}

const getForumById = async (forumId) => {
  const now = Date.now()
  if (forumsCache.byId[forumId] && now - forumsCache.byId[forumId].lastFetch < forumsCache.allForums.cacheDuration) {
    return forumsCache.byId[forumId].data
  }

  try {
    const docRef = doc(forumsCollectionRef, forumId)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const forum = { id: docSnap.id, ...docSnap.data() }
      forumsCache.byId[forumId] = {
        data: forum,
        lastFetch: now,
      }
      return forum
    } else {
      throw new Error('Forum not found')
    }
  } catch (error) {
    console.error('Error retrieving forum:', error)
    throw error
  }
}

const addForum = async (forumData) => {
  try {
    const docRef = await addDoc(forumsCollectionRef, forumData)
    forumsCache.allForums.data = null // Invalidate the cache
    return docRef.id
  } catch (error) {
    console.error('Error adding forum:', error)
    throw error
  }
}

const updateForum = async (forumId, forumData) => {
  try {
    const forumRef = doc(forumsCollectionRef, forumId)
    await updateDoc(forumRef, forumData)
    forumsCache.byId[forumId] = undefined
    forumsCache.allForums.data = null // Invalidate cache
    return forumId
  } catch (error) {
    console.error(`Error updating forum with ID ${forumId}:`, error)
    throw error
  }
}

const deleteForum = async (forumId) => {
  try {
    const forumRef = doc(forumsCollectionRef, forumId)
    await deleteDoc(forumRef)
    forumsCache.byId[forumId] = undefined
    forumsCache.allForums.data = null // Invalidate cache
    return forumId
  } catch (error) {
    console.error(`Error deleting forum with ID ${forumId}:`, error)
    throw error
  }
}

const forumRepository = {
  getForums,
  getForumById,
  addForum,
  updateForum,
  deleteForum,
}

export default forumRepository
