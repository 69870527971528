import { getApps, initializeApp } from 'firebase/app'
import { getFirestore, collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore'
import { firebaseConfig } from '../../services/firebase/config'

if (!getApps().length) {
  initializeApp(firebaseConfig)
}

// Create a Firestore reference
const db = getFirestore()

const getDocsFromQuery = async (q) => {
  try {
    const querySnapshot = await getDocs(q)

    const results = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))

    return results
  } catch (error) {
    throw error
  }
}

const getDb = () => {
  return db
}

const repository = { getDb, getDocsFromQuery }

export default repository
