import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import tripRepository from '../../../repositories/firestore/trips.firestore.repository'
import userRepository from '../../../repositories/firestore/users.firestore.repository'
import campgroundRepository from '../../../repositories/firestore/campgrounds.firestore.repository'
import ProfilePhoto from '../../shared/components/ProfilePhoto'
import PageLayout from '../../shared/components/PageLayout'

const TripsFeed = () => {
  const [trips, setTrips] = useState([])
  const [isLoading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchTripsWithUserData = async () => {
      try {
        const fetchedTrips = await tripRepository.getAllUpcomingTrips()
        const userCache = {}

        const tripsWithUserData = await Promise.all(
          fetchedTrips.map(async (trip) => {
            if (!userCache[trip.userId]) {
              // Fetch user data if not already cached
              const userData = await userRepository.getUserPublicDataById(trip.userId)
              userCache[trip.userId] = userData // Cache it for any future use
            }

            return {
              ...trip,
              displayName: userCache[trip.userId].displayName,
              profilePhotoUrl: userCache[trip.userId].profilePhotoUrl,
            }
          }),
        )

        setTrips(tripsWithUserData)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching trips with user data:', error)
        setLoading(false)
      }
    }

    fetchTripsWithUserData()
  }, [])

  const handleCampgroundClick = async (campgroundId) => {
    const campground = await campgroundRepository.getCampgroundById(campgroundId)
    if (campground) {
      navigate(`/campground/${campground.slug}`)
    }
  }

  // Function to convert Firestore Timestamp to JavaScript Date object
  const convertTimestampToDate = (timestamp) => {
    return timestamp ? new Date(timestamp.seconds * 1000) : null
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <PageLayout title='Trip Feed'>
      <div className='space-y-4'>
        {trips.length > 0 ? (
          trips.map((trip, index) => (
            <div key={index} className='bg-white rounded-lg shadow p-4 mb-4 flex items-center'>
              <div className='profile-photo-container mr-2'>
                <a href={`/user/profile/${trip.userId}`} className='no-underline text-black hover:text-blue-600'>
                  <ProfilePhoto src={trip.profilePhotoUrl} displayName={trip.displayName} size='16' />
                </a>
              </div>
              <p className='flex-grow text-sm md:text-base ml-6'>
                <a href={`/user/profile/${trip.userId}`} className='no-underline text-black hover:text-blue-600'>
                  <strong className='font-semibold'>{trip.displayName}</strong>
                </a>
                &nbsp;is going to{' '}
                <a className='no-underline text-black hover:text-blue-600' onClick={() => handleCampgroundClick(trip.campgroundId)}>
                  <strong className='font-semibold'>{trip.campgroundName}</strong>
                </a>{' '}
                from
                <strong className='font-semibold'> {trip.startDate ? convertTimestampToDate(trip.startDate).toLocaleDateString() : 'N/A'}</strong> to
                <strong className='font-semibold'> {trip.endDate ? convertTimestampToDate(trip.endDate).toLocaleDateString() : 'N/A'}</strong>.
              </p>
            </div>
          ))
        ) : (
          <p className='text-center py-4'>No upcoming trips.</p>
        )}
      </div>
    </PageLayout>
  )
}

export default TripsFeed
