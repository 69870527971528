import { collection, query, where, orderBy, limit } from 'firebase/firestore'
import baseRepository from './base.firestore.repository'

let blogsCache = {
  allBlogs: {
    data: null,
    lastFetch: 0,
    cacheDuration: 1000 * 60 * 60, // 1 hour
  },
  latestBlogs: {},
  blogsBySlug: {},
}

const getBlogs = async () => {
  const now = Date.now()
  // Check if cached data exists and hasn't expired
  if (blogsCache.allBlogs.data && now - blogsCache.allBlogs.lastFetch < blogsCache.allBlogs.cacheDuration) {
    return blogsCache.allBlogs.data
  }

  try {
    const q = query(collection(baseRepository.getDb(), 'blogs'), orderBy('timestamp', 'desc'))
    const blogs = await baseRepository.getDocsFromQuery(q)
    // Update cache
    blogsCache.allBlogs.data = blogs
    blogsCache.allBlogs.lastFetch = now
    return blogs
  } catch (error) {
    console.error('Error retrieving blogs:', error)
    throw error
  }
}

const getLatestBlogs = async (count) => {
  const now = Date.now()
  const cacheKey = `latest-${count}`
  // Check cache first
  if (blogsCache.latestBlogs[cacheKey] && now - blogsCache.latestBlogs[cacheKey].lastFetch < blogsCache.allBlogs.cacheDuration) {
    return blogsCache.latestBlogs[cacheKey].data
  }

  try {
    const q = query(collection(baseRepository.getDb(), 'blogs'), orderBy('timestamp', 'desc'), limit(count))
    const blogs = await baseRepository.getDocsFromQuery(q)
    // Update cache
    blogsCache.latestBlogs[cacheKey] = {
      data: blogs,
      lastFetch: now,
    }
    return blogs
  } catch (error) {
    console.error(`Error retrieving latest ${count} blogs:`, error)
    throw error
  }
}

const getBlogBySlug = async (slug) => {
  const now = Date.now()
  // Check cache first
  if (blogsCache.blogsBySlug[slug] && now - blogsCache.blogsBySlug[slug].lastFetch < blogsCache.allBlogs.cacheDuration) {
    return blogsCache.blogsBySlug[slug].data
  }

  try {
    const q = query(collection(baseRepository.getDb(), 'blogs'), where('slug', '==', slug), limit(1))
    const result = await baseRepository.getDocsFromQuery(q)
    if (result.length > 0) {
      const blog = result[0]
      // Update cache
      blogsCache.blogsBySlug[slug] = {
        data: blog,
        lastFetch: now,
      }
      return blog
    } else {
      throw new Error(`Could not find blog with slug ${slug}`)
    }
  } catch (error) {
    console.error(`Error retrieving blog by slug:`, error)
    throw error
  }
}

const repository = { getBlogs, getLatestBlogs, getBlogBySlug }

export default repository
