import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import campgroundRepository from '../../../../repositories/firestore/campgrounds.firestore.repository'

const CampgroundsList = () => {
  const [campgrounds, setCampgrounds] = useState([])
  useEffect(() => {
    const fetchCampgrounds = async () => {
      try {
        const fetchedCampgrounds = await campgroundRepository.getAllCampgrounds()
        if (Array.isArray(fetchedCampgrounds)) {
          setCampgrounds(fetchedCampgrounds)
        } else {
          // If fetched data is not an array, log an error or handle as appropriate
          console.error('Fetched campgrounds is not an array:', fetchedCampgrounds)
          setCampgrounds([]) // Ensure state is set to an empty array to avoid errors
        }
      } catch (error) {
        console.error('Error fetching campgrounds:', error)
        setCampgrounds([]) // Ensure state is reset in case of error
      }
    }

    fetchCampgrounds()
  }, [])

  return (
    <div className='w-full px-4 mt-10'>
      {/* Header and Add New Event Button Container */}
      <div className='flex justify-between items-center mb-4'>
        <h2 className='text-3xl font-bold'>Manage Campgrounds</h2>
        <Link
          to='/admin/campground'
          className='inline-block bg-green-500 text-white py-2 px-6 rounded hover:bg-green-700 transition-colors duration-200'>
          Add New Campground
        </Link>
      </div>
      <div className='space-y-2'>
        <div className='mb-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
          {campgrounds.map((campground) => (
            <div key={campground.id} className='bg-white shadow-md rounded-lg overflow-hidden'>
              <div className='p-6'>
                <h2 className='font-bold text-xl mb-2'>{campground.name}</h2>
                <div className='text-right'>
                  <Link to={`/admin/campground/${campground.id}`} className='text-white bg-blue-500 hover:bg-blue-700 font-medium py-1 px-2 rounded'>
                    Edit
                  </Link>
                  <Link
                    to={`/campground-admin/${campground.id}`}
                    className='text-white bg-green-500 hover:bg-green-700 font-medium py-1 px-2 rounded ml-2'>
                    Manage
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CampgroundsList
