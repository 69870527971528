import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom' // Import useNavigate
import logoImage from '../../../img/logos/long_logo.png'
import { useAuth } from '../../../contexts/AuthContext'
import ProfilePhoto from './ProfilePhoto'

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { currentUser, userDetails, logout } = useAuth()

  const navigate = useNavigate() // Hook for programmatic navigation

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  // Function to calculate total points
  const getTotalGCFPoints = () => {
    const points = userDetails.public.points || {}
    return Object.values(points).reduce((total, value) => total + value, 0)
  }

  const handleLogout = async () => {
    try {
      await logout()
      navigate('/auth/login') // Ensure redirection to login after logout
    } catch (error) {
      console.error('Logout failed', error)
    }
  }

  const handleNavigate = (path) => {
    setIsMenuOpen(false) // Close the mobile menu
    navigate(path) // Navigate programmatically
  }

  const getGreeting = () => {
    const hour = new Date().getHours()
    return hour < 12 ? 'Good morning' : hour < 18 ? 'Good afternoon' : 'Good evening'
  }

  const iscam = () => {
    // Check if user has 'campgroundAdmin' role and a 'campgroundId'
    if (userDetails.secure.roles?.includes('campgroundAdmin') && userDetails.secure.campgroundId) {
      return (
        <Link
          to={`/campground-admin/${userDetails.secure.campgroundId}`}
          className='text-gray-700 hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium'>
          Manage Campground
        </Link>
      )
    }
    return null
  }

  return (
    <>
      <nav className='bg-white shadow'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className='flex justify-between h-16 items-center'>
            <div className='flex-shrink-0 flex items-center'>
              <Link to='/'>
                <img className='h-12 w-auto' src={logoImage} alt='Website Logo' />
              </Link>
            </div>
            <div className='hidden md:block'>
              <div className='ml-10 flex items-center justify-end space-x-4'>
                {/* Navigation Links */}
                <Link to='/' className='text-gray-700 hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium'>
                  Home
                </Link>
                <Link to='/blog' className='text-gray-700 hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium'>
                  Blog
                </Link>
                <Link to='/events' className='text-gray-700 hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium'>
                  Events
                </Link>
                <Link to='/this-week' className='text-gray-700 hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium'>
                  This Week
                </Link>
                <Link to='/resources' className='text-gray-700 hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium'>
                  Resources
                </Link>
                <Link to='/about' className='text-gray-700 hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium'>
                  About
                </Link>

                {!currentUser && (
                  <>
                    <Link to='/auth/signup' className='text-gray-700 hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium'>
                      Sign Up
                    </Link>
                    <Link to='/auth/login' className='text-gray-700 hover:bg-gray-200 ml-4 px-3 py-2 rounded-md text-sm font-medium'>
                      Login
                    </Link>
                  </>
                )}
              </div>
            </div>
            <div className='-mr-2 flex md:hidden'>
              <button
                onClick={handleToggleMenu}
                className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white'>
                {/* Icon */}
                <svg className='h-6 w-6' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
                  {isMenuOpen ? (
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12' />
                  ) : (
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h16m-7 6h7' />
                  )}
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* User Bar for Desktop, aligned within the main container */}
        {currentUser && (
          <div className='bg-gray-700 text-white py-2 hidden md:block'>
            <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center'>
              <div className='flex items-center'>
                <ProfilePhoto src={userDetails.public.profilePhotoUrl} displayName={userDetails.public.displayName} size='12' className='mr-4' />
                <span>
                  {getGreeting()}, <strong>{userDetails.public.displayName || currentUser.email}</strong>
                  <span className='block text-sm'>
                    {userDetails.public.pronouns ? ` (${userDetails.public.pronouns})` : ''} GCF Points: {getTotalGCFPoints()}
                  </span>
                </span>
              </div>
              <div>
                <Link to={`/user/dashboard`} className='px-3 py-2 rounded-md text-sm font-medium'>
                  My Dashboard
                </Link>
                {/* <Link to={`/user/chat`} className='px-3 py-2 rounded-md text-sm font-medium'>
                  Chat
                </Link> */}
                <Link to={`/user/profile/${currentUser.uid}`} className='px-3 py-2 rounded-md text-sm font-medium'>
                  My Profile
                </Link>
                {userDetails.secure.roles && userDetails.secure.roles.includes('admin') && (
                  <Link to='/admin' className='px-3 py-2 rounded-md text-sm font-medium'>
                    Admin
                  </Link>
                )}
                {userDetails.secure.roles?.includes('campgroundAdmin') && userDetails.secure.campgroundId && (
                  <Link to={`/campground-admin/${userDetails.secure.campgroundId}`} className='px-3 py-2 rounded-md text-sm font-medium'>
                    Manage Campground
                  </Link>
                )}
                <button onClick={handleLogout} className='px-3 py-2 rounded-md text-sm font-medium'>
                  Logout
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Mobile menu, show/hide based on menu state. */}
        <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
          {/* User Section */}
          {currentUser && (
            <div className='bg-gray-700 text-white'>
              <div className='px-2 pt-2 flex items-center'>
                <ProfilePhoto src={userDetails.public.profilePhotoUrl} displayName={userDetails.public.displayName} size='12' className='mr-4 ml-3' />
                <span>
                  {getGreeting()}, <strong>{userDetails.public.displayName || currentUser.email}</strong>
                  <span className='block text-sm'>
                    {userDetails.public.pronouns ? ` (${userDetails.public.pronouns})` : ''} GCF Points: {getTotalGCFPoints()}
                  </span>
                </span>
              </div>
              <div className='px-2 pt-2 pb-3 space-y-1 sm:px-3 flex flex-col items-end'>
                <button onClick={() => handleNavigate(`/user/dashboard`)} className='block text-left px-3 py-2 rounded-md text-base font-medium'>
                  My Dashboard
                </button>
                {/* <button onClick={() => handleNavigate(`/user/chat`)} className='block text-left px-3 py-2 rounded-md text-base font-medium'>
                  Chat
                </button> */}
                <button
                  onClick={() => handleNavigate(`/user/profile/${currentUser.uid}`)}
                  className='block text-left px-3 py-2 rounded-md text-base font-medium'>
                  My Profile
                </button>
                {userDetails.secure.roles && userDetails.secure.roles.includes('admin') && (
                  <button onClick={() => handleNavigate('/admin')} className='block text-left px-3 py-2 rounded-md text-base font-medium'>
                    Admin
                  </button>
                )}
                {userDetails.secure.roles?.includes('campgroundAdmin') && userDetails.secure.campgroundId && (
                  <button
                    onClick={() => handleNavigate(`/campground-admin/${userDetails.secure.campgroundId}`)}
                    className='block text-left px-3 py-2 rounded-md text-base font-medium'>
                    Manage Campground
                  </button>
                )}
                <button onClick={handleLogout} className='block text-left px-3 py-2 rounded-md text-base font-medium'>
                  Logout
                </button>
              </div>
            </div>
          )}
          <div className='px-2 pt-2 pb-3 space-y-1 sm:px-3  flex flex-col items-end'>
            <button onClick={() => handleNavigate('/')} className='text-gray-700 block px-3 py-2 rounded-md text-base font-medium'>
              Home
            </button>
            <button onClick={() => handleNavigate('/blog')} className='text-gray-700 block px-3 py-2 rounded-md text-base font-medium'>
              Blog
            </button>
            <button onClick={() => handleNavigate('/events')} className='text-gray-700 block px-3 py-2 rounded-md text-base font-medium'>
              Events
            </button>
            <button onClick={() => handleNavigate('/this-week')} className='text-gray-700 block px-3 py-2 rounded-md text-base font-medium'>
              This Week
            </button>
            <button onClick={() => handleNavigate('/resources')} className='text-gray-700 block px-3 py-2 rounded-md text-base font-medium'>
              Resources
            </button>
            <button onClick={() => handleNavigate('/about')} className='text-gray-700 block px-3 py-2 rounded-md text-base font-medium'>
              About
            </button>
            {/* Include User Links */}
            {!currentUser && (
              <>
                <button
                  onClick={() => handleNavigate('/auth/signup')}
                  className='text-gray-700 hover:bg-gray-200 block px-3 py-2 rounded-md text-base font-medium'>
                  Sign Up
                </button>
                <button
                  onClick={() => handleNavigate('/auth/login')}
                  className='text-gray-700 hover:bg-gray-200 block px-3 py-2 rounded-md text-base font-medium'>
                  Login
                </button>
              </>
            )}
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar
