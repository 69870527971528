import { doc, getDoc, setDoc, updateDoc, runTransaction, collection, query, orderBy, limit, getDocs, where, startAfter } from 'firebase/firestore'
import baseRepository from './base.firestore.repository'

// Cache configuration
const cacheTimeout = 1000 * 60 * 15 // 15 minutes

// Simple in-memory cache
const cache = {}

const buildCacheKey = (prefix, params) => `${prefix}_${JSON.stringify(params)}`

const isCacheValid = (key) => {
  return cache[key] && Date.now() - cache[key].timestamp < cacheTimeout
}

// Helper functions to get references to public and secure subcollections or documents
const getUsersDataRef = () => collection(baseRepository.getDb(), 'users')
const getPublicDataRef = (userId) => doc(baseRepository.getDb(), `users/${userId}`)
const getSecureDataRef = (userId) => doc(baseRepository.getDb(), `users/${userId}/secureData`, 'profile')

const getUsersList = async (limitCount = 10) => {
  try {
    const usersRef = getUsersDataRef()
    const q = query(usersRef, orderBy('displayName'), limit(limitCount))
    const querySnapshot = await getDocs(q)

    const usersList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))

    return usersList
  } catch (error) {
    console.error('Error retrieving users list:', error)
    throw error
  }
}

const getUsersLeaderboard = async () => {
  const cacheKey = buildCacheKey('leaderboard', {})
  if (isCacheValid(cacheKey)) {
    console.log('Returning cached leaderboard')
    return cache[cacheKey].data
  }

  try {
    const q = query(collection(baseRepository.getDb(), 'users'), orderBy('totalPoints', 'desc'), limit(25))
    const querySnapshot = await getDocs(q)
    const leaderboard = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))

    cache[cacheKey] = { data: leaderboard, timestamp: Date.now() } // Update cache
    return leaderboard
  } catch (error) {
    console.error('Error retrieving leaderboard:', error)
    throw error
  }
}

const getPagedUsers = async (limitCount, pageToken, filters = {}) => {
  const cacheKey = buildCacheKey('pagedUsers', { limitCount, pageToken, ...filters })
  if (isCacheValid(cacheKey)) {
    console.log('Returning cached paged users')
    return cache[cacheKey].data
  }

  try {
    let q = query(collection(baseRepository.getDb(), 'users'), orderBy('displayName'))
    if (filters.displayName) {
      q = query(q, where('displayName', '>=', filters.displayName), where('displayName', '<=', filters.displayName + '\uf8ff'))
    }
    if (filters.country) {
      q = query(q, where('country', '==', filters.country))
    }
    if (filters.state) {
      q = query(q, where('state', '==', filters.state))
    }
    if (pageToken) {
      q = query(q, startAfter(pageToken), limit(limitCount))
    } else {
      q = query(q, limit(limitCount))
    }

    const querySnapshot = await getDocs(q)
    const usersList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]

    const result = { usersList, lastVisible }
    cache[cacheKey] = { data: result, timestamp: Date.now() } // Update cache
    return result
  } catch (error) {
    console.error('Error retrieving paged users:', error)
    throw error
  }
}

const getUserDataById = async (userId) => {
  try {
    const publicDataRef = getPublicDataRef(userId)
    const secureDataRef = getSecureDataRef(userId)

    const publicDataSnap = await getDoc(publicDataRef)
    const secureDataSnap = await getDoc(secureDataRef)

    const publicData = publicDataSnap.exists() ? publicDataSnap.data() : {}
    const secureData = secureDataSnap.exists() ? secureDataSnap.data() : {}

    return {
      id: userId,
      public: publicData,
      secure: secureData,
    }
  } catch (error) {
    console.error(`Error retrieving data for user ${userId}:`, error)
    throw error
  }
}

const updateUserPointsById = async (userId, pointType, pointsToAdd) => {
  /// HOW TO USE: await userRepository.updateUserPointsById(userCredential.user.uid, 'testPoints', 5)

  const userRef = getPublicDataRef(userId)

  try {
    await runTransaction(baseRepository.getDb(), async (transaction) => {
      const userDoc = await transaction.get(userRef)
      if (!userDoc.exists()) {
        throw new Error('Document does not exist!')
      }

      const userData = userDoc.data()
      const currentPoints = userData.points || {}
      const currentTotalPoints = userData.totalPoints || 0

      const updatedPoints = { ...currentPoints, [pointType]: (currentPoints[pointType] || 0) + pointsToAdd }
      const updatedTotalPoints = Object.values(updatedPoints).reduce((sum, current) => sum + current, 0)

      transaction.update(userRef, {
        points: updatedPoints,
        totalPoints: updatedTotalPoints,
      })
    })
  } catch (error) {
    console.error('Error updating points:', error)
    throw error
  }
}

const getUserPublicDataById = async (userId) => {
  const cacheKey = buildCacheKey('publicData', { userId })

  // Check if the data is in cache and is still valid
  if (isCacheValid(cacheKey)) {
    console.log('Returning cached public data for user:', userId)
    return cache[cacheKey].data
  }

  try {
    const publicDataRef = getPublicDataRef(userId)
    const publicDataSnap = await getDoc(publicDataRef)

    if (!publicDataSnap.exists()) {
      console.error('No public user data found:', userId)
      return null // Handle non-existent data gracefully
    }

    const publicData = publicDataSnap.data()

    // Store the fetched data in cache
    cache[cacheKey] = {
      data: { id: userId, ...publicData },
      timestamp: Date.now(),
    }

    return cache[cacheKey].data
  } catch (error) {
    console.error(`Error retrieving public data for user ${userId}:`, error)
    throw error
  }
}

const addUser = async (userId, publicUserData, secureUserData) => {
  try {
    // Save public user data directly to the user's document
    await setDoc(getPublicDataRef(userId), publicUserData)
    await setDoc(getSecureDataRef(userId), secureUserData)
  } catch (error) {
    console.error('Error adding new user to Firestore:', error)
    throw error
  }
}

const updatePublicUserData = async (userId, publicUserData) => {
  try {
    await updateDoc(getPublicDataRef(userId), publicUserData)
  } catch (error) {
    console.error(`Error updating public data for user ${userId}:`, error)
    throw error
  }
}

const assignUserRole = async (userId, roles, campgroundId = null) => {
  try {
    const secureDataRef = getSecureDataRef(userId)
    const updateData = { roles }
    if (campgroundId && roles.includes('campgroundAdmin')) {
      updateData.campgroundId = campgroundId // Only set campgroundId if the role is campgroundAdmin
    }
    await updateDoc(secureDataRef, updateData)
  } catch (error) {
    console.error(`Error assigning roles to user ${userId}:`, error)
    throw error
  }
}

const updateSecureUserData = async (userId, secureUserData) => {
  try {
    const secureDataRef = getSecureDataRef(userId)
    await updateDoc(secureDataRef, secureUserData)
  } catch (error) {
    console.error(`Error updating secure data for user ${userId}:`, error)
    throw error
  }
}

const userRepository = {
  getUsersList,
  getPagedUsers,
  getUsersLeaderboard,
  getUserDataById,
  updateUserPointsById,
  getUserPublicDataById,
  addUser,
  updatePublicUserData,
  updateSecureUserData,
  assignUserRole,
}

export default userRepository
