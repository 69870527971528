import React from "react";

const InfoFooter = () => {
  return (
    <div className="py-8">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">
              About Us
            </h3>
            <p>
              Meeting new people, finding new places, making new memories. That
              is what this is all about. Exploring Together.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">
              Join Us on Discord
            </h3>
            <p>
              <ul>
                <li>Regional Gay Chat</li>
                <li>Photo and Video Sharing</li>
                <li>Tons of Channels.</li>
                <li>Nudist Friendly.</li>
                <li>Completely Free.</li>
              </ul>
              <a href="https://discord.com/invite/s7EgAYEq9E">
                Accept Your Invite Here
              </a>
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">
              Contact Us
            </h3>
            <p>
              Have a question, comment, or suggestion? Please feel free to
              contact us.
            </p>
            <a href="mailto:info@gaycampingfriends.com">
              info@gaycampingfriends.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoFooter;
