import React from 'react'
import { Link } from 'react-router-dom'
import ProfilePhoto from '../../shared/components/ProfilePhoto'
import threadRepository from '../../../repositories/firestore/thread.firestore.repository'
import { FaTrash } from 'react-icons/fa'
import { formatTimestampAsDate } from '../../../utils/formatDateUtil'

const ThreadItem = ({ thread, forumId, onThreadDeleted, canDelete }) => {
  const handleDeleteThread = async () => {
    if (window.confirm('Are you sure you want to delete this thread?')) {
      try {
        await threadRepository.deleteThread(forumId, thread.id)
        onThreadDeleted(thread.id) // Call the passed function to handle deletion in the parent component
        alert('Thread deleted successfully.')
      } catch (error) {
        console.error('Error deleting thread:', error)
      }
    }
  }

  return (
    <div className='break-words mb-2 p-2 bg-white shadow rounded flex items-start justify-between'>
      <div
        className='flex cursor-pointer'
        onClick={() => {
          /* Navigate to thread details or show profile */
        }}>
        <ProfilePhoto src={thread.profilePhotoUrl} displayName={thread.displayName} size='10' className='mr-2' />
        <div className='flex flex-col flex-grow min-w-0'>
          <Link to={`/user/forums/${forumId}/threads/${thread.id}`} className='text-lg font-semibold text-blue-600 hover:text-blue-800'>
            {thread.title}
          </Link>
          <span className='text-gray-800'>{thread.content}</span>
          <span className='text-sm text-gray-500'>{formatTimestampAsDate(thread.createdAt)}</span>
        </div>
      </div>
      {canDelete && (
        <button onClick={handleDeleteThread} className='ml-4'>
          <FaTrash className='text-gray-500 hover:text-gray-700 text-sm' />
        </button>
      )}
    </div>
  )
}

export default ThreadItem
