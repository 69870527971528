import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from '../../../contexts/AuthContext'
import userRepository from '../../../repositories/firestore/users.firestore.repository'
import { toast } from 'react-toastify'
import { uploadUserProfileImage } from '../../../repositories/storage/users.storage.repository'
import { Country, State } from 'country-state-city'
import ProfilePhoto from '../../shared/components/ProfilePhoto'
import PageLayout from '../../shared/components/PageLayout'

const EditProfileForm = () => {
  const { currentUser, userDetails } = useAuth()
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: userDetails.public || {}, // Initialize form with public user details
  })
  const [isLoading, setIsLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const watchCountry = watch('country')
  const selectedVibes = watch('campingVibePreferences', [])

  useEffect(() => {
    if (userDetails && userDetails.public) {
      Object.keys(userDetails.public).forEach((key) => {
        setValue(key, userDetails.public[key])
      })
    }
  }, [userDetails, setValue])

  useEffect(() => {
    setCountries(Country.getAllCountries())
  }, [])

  useEffect(() => {
    if (watchCountry) {
      setStates(State.getStatesOfCountry(watchCountry))
    }
  }, [watchCountry])

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      resizeImage(file, 800, 800, (resizedBlob) => {
        setSelectedFile(resizedBlob)
      })
    }
  }

  const resizeImage = (file, maxWidth, maxHeight, callback) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      const img = new Image()
      img.onload = () => {
        let canvas = document.createElement('canvas')
        let width = img.width
        let height = img.height

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width
            width = maxWidth
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height
            height = maxHeight
          }
        }

        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, width, height)
        ctx.canvas.toBlob((blob) => callback(blob), 'image/jpeg', 0.7)
      }
      img.src = e.target.result
    }
    reader.readAsDataURL(file)
  }

  const onSubmit = async (publicData) => {
    if (!currentUser) return
    setIsLoading(true)

    if (selectedFile) {
      const profilePhotoUrl = await uploadUserProfileImage(selectedFile, currentUser.uid)
      publicData.profilePhotoUrl = profilePhotoUrl // Assuming profilePhotoUrl is part of public data
    }

    try {
      await userRepository.updatePublicUserData(currentUser.uid, publicData) // Update only public data
      toast.success('Profile updated successfully')
    } catch (error) {
      console.error('Error updating profile:', error)
      toast.error('Failed to update profile.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <PageLayout title='Edit Profile'>
      <p className='mb-3'>Note that this information may be shared with other users of the site.</p>
      <div className='space-y-2'>
        <form onSubmit={handleSubmit(onSubmit)} className='space-y-4'>
          <div className='bg-white p-4 rounded-lg shadow'>
            <h3 className='text-1xl font-bold mb-4'>About Me</h3>

            {/* Profile Photo Section */}
            <div className='mb-6'>
              <label htmlFor='profilePhoto' className='block text-sm font-medium text-gray-700'>
                Profile Photo
              </label>
              <div className='mt-1 flex flex-col md:flex-row items-center'>
                <ProfilePhoto
                  src={userDetails.public.profilePhotoUrl}
                  displayName={userDetails.public.displayName}
                  size='24'
                  className='mb-4 md:mb-0 md:mr-8'
                />
                <input
                  type='file'
                  onChange={handleFileChange}
                  className='block w-full text-sm text-gray-900 border border-gray-300 rounded-md shadow-sm cursor-pointer focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500'
                />
              </div>
            </div>

            {/* Other Details */}
            <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
              <div>
                <label htmlFor='displayName' className='block text-sm font-medium text-gray-700'>
                  Display Name
                </label>
                <input
                  {...register('displayName', { required: true })}
                  type='text'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                />
                {errors.displayName && <p className='text-red-500'>Display name is required.</p>}
              </div>
              <div>
                <label htmlFor='pronouns' className='block text-sm font-medium text-gray-700'>
                  Pronouns
                </label>
                <input
                  {...register('pronouns')}
                  type='text'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                />
              </div>
              <div>
                <label htmlFor='country' className='block text-sm font-medium text-gray-700'>
                  Country
                </label>
                <select
                  {...register('country', { required: false })}
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'>
                  <option value=''>Select a country</option>
                  {countries.map((country) => (
                    <option key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor='state' className='block text-sm font-medium text-gray-700'>
                  State/Province
                </label>
                <select
                  {...register('state', { required: false })}
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'>
                  <option value=''>Select a state</option>
                  {states.map((state) => (
                    <option key={state.isoCode} value={state.isoCode}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className='md:col-span-2'>
                <label htmlFor='bio' className='block text-sm font-medium text-gray-700'>
                  Bio
                </label>
                <textarea
                  {...register('bio')}
                  rows='4' // Sets a minimum height by specifying the number of lines.
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'></textarea>
              </div>
            </div>
          </div>

          <div className='bg-white p-4 rounded-lg shadow'>
            <h3 className='text-1xl font-bold mb-4'>Social Media</h3>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
              <div>
                <label htmlFor='facebook' className='block text-sm font-medium text-gray-700'>
                  Facebook Username
                </label>
                <input
                  {...register('facebook', { required: false })}
                  type='text'
                  placeholder='Your Facebook username'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                />
              </div>

              <div>
                <label htmlFor='instagram' className='block text-sm font-medium text-gray-700'>
                  Instagram Username
                </label>
                <input
                  {...register('instagram', { required: false })}
                  type='text'
                  placeholder='Your Instagram username'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                />
              </div>

              <div>
                <label htmlFor='tiktok' className='block text-sm font-medium text-gray-700'>
                  TikTok Handle
                </label>
                <input
                  {...register('tiktok', { required: false })}
                  type='text'
                  placeholder='Your TikTok handle'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                />
              </div>

              <div>
                <label htmlFor='twitter' className='block text-sm font-medium text-gray-700'>
                  Twitter Handle
                </label>
                <input
                  {...register('twitter', { required: false })}
                  type='text'
                  placeholder='Your Twitter handle'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                />
              </div>

              <div>
                <label htmlFor='discord' className='block text-sm font-medium text-gray-700'>
                  Discord Tag (e.g., User#1234)
                </label>
                <input
                  {...register('discord', {
                    required: false,
                    pattern: {
                      value: /^.{3,32}#[0-9]{4}$/,
                      message: 'Enter a valid Discord tag (e.g., User#1234)',
                    },
                  })}
                  type='text'
                  placeholder='Your Discord tag'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                />
                {errors.discord && <p className='text-red-500'>{errors.discord.message}</p>}
              </div>

              <div>
                <label htmlFor='spotify' className='block text-sm font-medium text-gray-700'>
                  Spotify Username
                </label>
                <input
                  {...register('spotify', { required: false })}
                  type='text'
                  placeholder='Your Spotify username'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                />
              </div>

              <div>
                <label htmlFor='twitch' className='block text-sm font-medium text-gray-700'>
                  Twitch Username
                </label>
                <input
                  {...register('twitch', { required: false })}
                  type='text'
                  placeholder='Your Twitch username'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                />
              </div>

              <div>
                <label htmlFor='snapchat' className='block text-sm font-medium text-gray-700'>
                  Snapchat Username
                </label>
                <input
                  {...register('snapchat', { required: false })}
                  type='text'
                  placeholder='Your Snapchat username'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                />
              </div>
            </div>
          </div>

          <div className=' bg-white p-4 rounded-lg shadow'>
            <h3 className='text-1xl font-bold mb-4'>Preferences</h3>
            {/* Experience Level Dropdown */}
            <div>
              <label htmlFor='experienceLevel' className='block text-sm font-medium text-gray-700'>
                Experience Level
              </label>
              <select
                {...register('experienceLevel')}
                className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'>
                <option value=''>Select your experience level</option>
                <option value='Experienced Camper'>Experienced Camper</option>
                <option value='Occasional Camper'>Occasional Camper</option>
                <option value='I Rarely Go Camping'>I Rarely Go Camping</option>
                <option value='I Have Never Camped'>I Have Never Camped</option>
              </select>
            </div>

            {/* Relationship Status Dropdown */}
            <div>
              <label htmlFor='relationshipStatus' className='block text-sm font-medium text-gray-700'>
                Relationship Status
              </label>
              <select
                {...register('relationshipStatus')}
                className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'>
                <option value=''>Select your status</option>
                <option value='Single'>Single</option>
                <option value='Dating'>Dating</option>
                <option value='Partnered'>Partnered</option>
                <option value='Engaged'>Engaged</option>
                <option value='Married'>Married</option>
              </select>
            </div>

            {/* Open to (Checkboxes) */}
            <fieldset className='mt-4'>
              <legend className='block text-sm font-medium text-gray-700'>Open To</legend>
              <div className='mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                {['Dates', 'Fun', 'Sex', 'Friends'].map((activity, index) => (
                  <div key={index} className='flex items-center mr-4 mb-2'>
                    <input
                      {...register('openToPreferences')}
                      type='checkbox'
                      value={activity}
                      id={`openTo-${activity}`}
                      className='form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2'
                    />
                    <label htmlFor={`openTo-${activity}`} className='ml-2 block text-sm text-gray-900'>
                      {activity}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>

            {/* Camping Type Preferences (Checkboxes) */}
            <fieldset className='mt-4'>
              <legend className='block text-sm font-medium text-gray-700'>Camping Type Preferences</legend>
              <div className='mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                {[
                  'Tent',
                  'Van (Class B)',
                  'Cabin',
                  'Travel Trailer',
                  '5th Wheel',
                  'Popup',
                  'Teardrop',
                  'Motorhome (Class A, C)',
                  'Class C',
                  'Rooftop Tent',
                  'Car Camping',
                ].map((type, index) => (
                  <div key={index} className='flex items-center mr-4 mb-2'>
                    <input
                      {...register('campingTypePreferences')}
                      type='checkbox'
                      value={type}
                      id={`type-${type}`}
                      className='form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2'
                    />
                    <label htmlFor={`type-${type}`} className='ml-2 block text-sm text-gray-900'>
                      {type}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>

            {/* Camping Environment Preferences (Checkboxes) */}
            <fieldset className='mt-4'>
              <legend className='block text-sm font-medium text-gray-700'>Camping Environment Preferences</legend>
              <div className='mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                {['Forest', 'City/Urban', 'Fields', 'Beach', 'Desert', 'Mountains', 'Hot Weather', 'Winter Weather'].map((environment, index) => (
                  <div key={index} className='flex items-center mr-4 mb-2'>
                    <input
                      {...register('campingEnvironmentPreferences')}
                      type='checkbox'
                      value={environment}
                      id={`environment-${environment}`}
                      className='form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2'
                    />
                    <label htmlFor={`environment-${environment}`} className='ml-2 block text-sm text-gray-900'>
                      {environment}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>

            <fieldset>
              <legend className='block text-sm font-medium text-gray-700'>Camping Vibe Preferences (Select up to 5)</legend>
              <div className='mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                {[
                  'Camping alone in silence',
                  "Anything as long as it's quiet and relaxing",
                  'A campground with a ton of amenities',
                  'No frills is fine with me',
                  'Camping at a busy campground',
                  'Boondocking or going off-grid',
                  'Camping where ever the party is',
                  'Camping only with people I know',
                  'I camp mostly at gay campgrounds',
                  'I camp mostly at straight campgrounds',
                  'I would not go to a gay campground',
                  'I like to be nude outside',
                  'I like to hook up while camping',
                ].map((vibe, index) => (
                  <div key={index} className='flex items-center mr-4 mb-2'>
                    <input
                      name='campingVibePreferences'
                      type='checkbox'
                      value={vibe}
                      checked={selectedVibes.includes(vibe)}
                      onChange={(e) => {
                        let newSelection
                        if (e.target.checked) {
                          newSelection = [...selectedVibes, vibe]
                        } else {
                          newSelection = selectedVibes.filter((selectedVibe) => selectedVibe !== vibe)
                        }

                        if (newSelection.length <= 5) {
                          setValue('campingVibePreferences', newSelection, { shouldValidate: true })
                        } else {
                          // Prevent adding more than 5 vibes
                          e.preventDefault()
                        }
                      }}
                      disabled={!selectedVibes.includes(vibe) && selectedVibes.length >= 5}
                      className='form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out'
                    />
                    <label htmlFor={`campingVibePreferences-${index}`} className='ml-2 block text-sm text-gray-900'>
                      {vibe}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>

            {/* Regions I Am Interested In (Checkboxes) */}
            <fieldset className='mt-4'>
              <legend className='block text-sm font-medium text-gray-700'>Regions I'm Interested In</legend>
              <div className='mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                {[
                  'Southeast USA',
                  'Northeast USA',
                  'Midwest USA',
                  'South Central USA',
                  'Southwest USA',
                  'Pacific Northwest USA',
                  'Mountain West USA',
                  'Canada',
                ].map((region, index) => (
                  <div key={index} className='flex items-center mr-4 mb-2'>
                    <input
                      {...register('regionsPreferences')}
                      type='checkbox'
                      value={region}
                      id={`region-${region}`}
                      className='form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2'
                    />
                    <label htmlFor={`region-${region}`} className='ml-2 block text-sm text-gray-900'>
                      {region}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
          <button
            type='submit'
            className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            disabled={isLoading}>
            Update Profile
          </button>
        </form>
      </div>
    </PageLayout>
  )
}

export default EditProfileForm
