import React from 'react'
import AdSense from 'react-adsense'

const AdComponent = (slot) => {
  return (
    <AdSense.Google
      client='ca-pub-3505900880382517'
      slot={slot}
      style={{ display: 'block' }}
      layout='in-article'
      format='horizontal'
      responsive='true'
    />
  )
}

export default AdComponent
