import React from 'react'
import BlogPostList from '../../home/components/BlogPostList'
import Breadcrumbs from '../../campgrounds/campground/components/Breadcrumbs'
import PageLayout from '../../../shared/components/PageLayout'

const Blog = () => {
  const breadcrumbData = [{ label: 'Blog Home', path: '/blog' }]

  return (
    <PageLayout
      title='Our Blog'
      metaTitle={'Gay Camping Friends Blog'}
      metaDescription={'Our blog has camping related content, LGBTQ+ camping news, and user created articles.'}>
      <Breadcrumbs breadcrumbs={breadcrumbData} />
      <BlogPostList count={15} />
    </PageLayout>
  )
}

export default Blog
