import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import { Country, State } from 'country-state-city'
import { toast } from 'react-toastify'
import categoriesRepository from '../../../../repositories/categories.repository'
import amenitiesConfig from '../../../../config/amenitiesConfig'
import campgroundRepository from '../../../../repositories/firestore/campgrounds.firestore.repository'
import { uploadCampgroundLogo, uploadCampgroundImage, deleteCampgroundImage } from '../../../../repositories/storage/campgrounds.storage.repository'

const CampgroundForm = () => {
  const navigate = useNavigate()
  const { campgroundId } = useParams()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm()

  const [categories, setCategories] = useState([]) // State to hold categories
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [logo, setLogo] = useState('')
  const [images, setImages] = useState([])
  const [imagesToDelete, setImagesToDelete] = useState([])

  // Watch country field to update states dropdown
  const selectedCountry = watch('address.country')

  useEffect(() => {
    if (campgroundId) {
      const fetchCampgroundData = async () => {
        try {
          const data = await campgroundRepository.getCampgroundById(campgroundId)
          if (data) {
            // Populate form fields
            Object.keys(data).forEach((key) => {
              // Populate form fields
              if (key === 'amenities' && Array.isArray(data[key])) {
                data[key].forEach((amenityId) => {
                  setValue(`amenities.${amenityId}`, true)
                })
              } else {
                setValue(key, data[key])
              }
              // For nested objects like 'address', you might need to handle them separately
              if (key === 'address') {
                // Set country and then load states
                if (data.address.country) {
                  setValue('address.country', data.address.country)
                }
              }
            })
            setLogo(data.logo)
            setImages(data.images || [])
          }
        } catch (error) {
          console.error('Failed to fetch campground data:', error)
        }
      }

      fetchCampgroundData()
    }
  }, [campgroundId, setValue])

  useEffect(() => {
    const loadCategories = async () => {
      const fetchedCategories = await categoriesRepository.GetCategories()
      setCategories(fetchedCategories)
    }

    loadCategories()

    // Load countries
    setCountries(Country.getAllCountries())
  }, []) // Empty dependency array means this effect runs once on mount

  // Effect to load states when country changes
  useEffect(() => {
    if (selectedCountry) {
      const fetchedStates = State.getStatesOfCountry(selectedCountry)
      setStates(fetchedStates)

      // Set the state value from fetched data
      campgroundRepository.getCampgroundById(campgroundId).then((data) => {
        if (data.address && data.address.state) {
          setValue('address.state', data.address.state)
        }
      })
    }
  }, [selectedCountry, campgroundId, setValue])

  const onSubmit = async (data) => {
    try {
      const { logoFile, imageFiles, ...formData } = data

      // Handle Logo Upload
      const logoUrl = logoFile && logoFile.length > 0 ? await uploadLogo(logoFile[0]) : ''
      if (logoUrl) formData.logo = logoUrl // Update formData only if logo is uploaded

      // Handle Image Deletions and Uploads
      const remainingImages = await handleImageUpdates(imageFiles)

      // Prepare Final Form Data
      prepareFormData(formData, remainingImages)

      // Perform the Submission (Add/Update)
      await performSubmission(formData)

      // Cleanup and Navigation
      postSubmissionCleanup()
    } catch (error) {
      console.error('Form submission error:', error)
      toast.error('Failed to process the form.')
    }
  }

  const uploadLogo = async (file) => {
    try {
      return await uploadCampgroundLogo(file, campgroundId)
    } catch (error) {
      console.error('Error uploading logo:', error)
      toast.error('Failed to upload logo.')
      throw error // Rethrow to stop further execution
    }
  }

  const handleImageUpdates = async (imageFiles) => {
    await deleteMarkedImages() // Delete images marked for deletion

    // Ensure we have a FileList or an array to work with
    const files = imageFiles && imageFiles.length > 0 ? imageFiles : []

    const uploadedImageUrls = await uploadImages(files)

    // Combine remaining and newly uploaded images
    const remainingImages = images.filter((imageUrl) => !imagesToDelete.includes(imageUrl))
    return [...remainingImages, ...uploadedImageUrls]
  }

  const deleteMarkedImages = async () => {
    for (const imageUrl of imagesToDelete) {
      try {
        await deleteCampgroundImage(imageUrl)
      } catch (error) {
        console.error('Error deleting image:', error)
        // Optionally, handle the error, e.g., by showing a notification
      }
    }
  }

  const uploadImages = async (imageFiles) => {
    // Ensure imageFiles is treated as an array
    const filesArray = Array.from(imageFiles)

    const uploadPromises = filesArray.map((file) => uploadCampgroundImage(file, campgroundId))
    return Promise.all(uploadPromises)
  }

  const prepareFormData = (formData, images) => {
    formData.images = images // Assume images array is already prepared
    // Extract amenities as array of IDs
    const amenitiesIds = Object.keys(formData.amenities)
      .filter((key) => formData.amenities[key])
      .map((id) => id)

    // Include amenities in the form data
    formData.amenities = amenitiesIds
  }

  const performSubmission = async (formData) => {
    if (campgroundId) {
      await campgroundRepository.updateCampground(campgroundId, formData)
      toast.success('Campground updated successfully!')
    } else {
      await campgroundRepository.addCampground(formData)
      toast.success('Campground added successfully!')
    }
  }

  const postSubmissionCleanup = () => {
    reset() // Reset form fields after submission
    setImagesToDelete([]) // Clear the list of images marked for deletion
    navigate('/admin/campgrounds') // Redirect to the campgrounds list
  }

  const toggleDeleteImage = (imageUrl) => {
    setImagesToDelete((currentImages) => {
      if (currentImages.includes(imageUrl)) {
        return currentImages.filter((url) => url !== imageUrl)
      } else {
        return [...currentImages, imageUrl]
      }
    })
  }

  return (
    <div className='w-full px-4 mt-10'>
      <div>
        <h2 className='text-3xl font-bold mb-4'>Edit Campground</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='space-y-4'>
            {/* Group 1: Basic Information */}
            <div className='space-y-4 bg-white p-4 rounded-lg shadow'>
              <h2 className='text-xl font-semibold text-gray-900'>Basic Information</h2>
              <div className='grid md:grid-cols-2 gap-4'>
                <div>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700'>
                    Name
                  </label>
                  <input
                    {...register('name', { required: true })}
                    type='text'
                    id='name'
                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                  />
                  {errors.name && <p className='text-red-500 text-xs italic'>Name is required.</p>}
                </div>
                <div>
                  <label htmlFor='slug' className='block text-sm font-medium text-gray-700'>
                    Slug
                  </label>
                  <input
                    {...register('slug', { required: true })}
                    type='text'
                    id='slug'
                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                  />
                  {errors.slug && <p className='text-red-500 text-xs italic'>Slug is required.</p>}
                </div>
              </div>

              {/* Category */}
              <div>
                <label htmlFor='category' className='block text-sm font-medium text-gray-700'>
                  Category
                </label>
                <select
                  {...register('category', { required: true })}
                  id='category'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'>
                  <option value=''>Select a category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
                {errors.category && <p className='text-red-500 text-xs italic'>Category is required.</p>}
              </div>
            </div>
            <div className='space-y-4 bg-white p-4 rounded-lg shadow'>
              <h2 className='text-xl font-semibold text-gray-900'>About</h2>

              <div>
                <label htmlFor='tagline' className='block text-sm font-medium text-gray-700'>
                  Tagline
                </label>
                <input
                  {...register('tagline', { required: true })}
                  type='text'
                  id='tagline'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                />
              </div>

              <div>
                <label htmlFor='description' className='block text-sm font-medium text-gray-700'>
                  Description
                </label>
                <textarea
                  {...register('description')}
                  id='description'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                  rows='4'></textarea>
              </div>

              <div>
                <label htmlFor='whatToBring' className='block text-sm font-medium text-gray-700'>
                  What to Bring
                </label>
                <textarea
                  {...register('whatToBring')}
                  id='whatToBring'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                  rows='2'></textarea>
              </div>

              <div>
                <label htmlFor='nearby' className='block text-sm font-medium text-gray-700'>
                  Nearby Attractions
                </label>
                <textarea
                  {...register('nearby')}
                  id='nearby'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                  rows='2'></textarea>
              </div>
            </div>

            {/* Contact Information */}
            <div className='space-y-4 bg-white p-4 rounded-lg shadow'>
              <h2 className='text-xl font-semibold text-gray-900'>Contact Information</h2>
              <div>
                <label htmlFor='website' className='block text-sm font-medium text-gray-700'>
                  Website
                </label>
                <input
                  {...register('website')}
                  type='text'
                  id='website'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                />
              </div>
              {/* Address Fields */}
              <div className='space-y-4'>
                {/* Country, State/Province */}
                <div className='grid md:grid-cols-2 gap-4'>
                  <div>
                    <label htmlFor='country' className='block text-sm font-medium text-gray-700'>
                      Country
                    </label>
                    <select
                      {...register('address.country', { required: false })}
                      className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'>
                      <option value=''>Select a country</option>
                      {countries.map((country) => (
                        <option key={country.isoCode} value={country.isoCode}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label htmlFor='state' className='block text-sm font-medium text-gray-700'>
                      State/Province
                    </label>
                    <select
                      {...register('address.state', { required: false })}
                      className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'>
                      <option value=''>Select a state/province</option>
                      {states.map((state) => (
                        <option key={state.isoCode} value={state.isoCode}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* Address 1, Address 2, City, ZIP */}
                <div className='grid md:grid-cols-2 gap-4'>
                  <div>
                    <label htmlFor='address1' className='block text-sm font-medium text-gray-700'>
                      Address 1
                    </label>
                    <input
                      {...register('address.address1', { required: false })}
                      type='text'
                      id='address1'
                      className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                    />
                  </div>

                  <div>
                    <label htmlFor='address2' className='block text-sm font-medium text-gray-700'>
                      Address 2
                    </label>
                    <input
                      {...register('address.address2')}
                      type='text'
                      id='address2'
                      className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                    />
                  </div>
                </div>
                <div className='grid md:grid-cols-2 gap-4'>
                  <div>
                    <label htmlFor='city' className='block text-sm font-medium text-gray-700'>
                      City
                    </label>
                    <input
                      {...register('address.city', { required: false })}
                      type='text'
                      id='city'
                      className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                    />
                  </div>

                  <div>
                    <label htmlFor='zip' className='block text-sm font-medium text-gray-700'>
                      ZIP/Postal Code
                    </label>
                    <input
                      {...register('address.zip', { required: false })}
                      type='text'
                      id='zip'
                      className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                    />
                  </div>
                </div>
              </div>
              <div className='grid md:grid-cols-2 gap-4'>
                <div>
                  <label htmlFor='email' className='block text-sm font-medium text-gray-700'>
                    Email Address
                  </label>
                  <input
                    {...register('email', {
                      //required: 'Email is required',
                      pattern: {
                        value: /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/,
                        message: 'Invalid email address',
                      },
                    })}
                    type='email'
                    id='email'
                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                  />
                  {errors.email && <p className='text-red-500 text-xs italic'>{errors.email.message}</p>}
                </div>
                <div>
                  <label htmlFor='phone' className='block text-sm font-medium text-gray-700'>
                    Phone Number
                  </label>
                  <input
                    {...register('phone', {
                      //required: 'Phone number is required',
                      pattern: {
                        value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
                        message: 'Invalid phone number',
                      },
                    })}
                    type='tel'
                    id='phone'
                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                  />
                  {errors.phone && <p className='text-red-500 text-xs italic'>{errors.phone.message}</p>}
                </div>
              </div>
            </div>

            {/* Group 2: Geolocation */}
            <div className='space-y-4 bg-white p-4 rounded-lg shadow'>
              <h2 className='text-xl font-semibold text-gray-900'>Geolocation Information</h2>
              <div className='grid md:grid-cols-2 gap-4'>
                <div>
                  <label htmlFor='latitude' className='block text-sm font-medium text-gray-700'>
                    Latitude
                  </label>
                  <input
                    {...register('latitude', { required: true })}
                    type='text'
                    id='latitude'
                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                  />
                </div>
                <div>
                  <label htmlFor='longitude' className='block text-sm font-medium text-gray-700'>
                    Longitude
                  </label>
                  <input
                    {...register('longitude', { required: true })}
                    type='text'
                    id='longitude'
                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                  />
                </div>
              </div>
            </div>

            {/* Media Information */}
            <div className='space-y-4 bg-white p-4 rounded-lg shadow'>
              <h2 className='text-xl font-semibold text-gray-900'>Media Information</h2>
              <div className='space-y-4'>
                <div>
                  <label htmlFor='logo' className='block text-sm font-medium text-gray-700'>
                    {logo ? 'Replace Logo' : 'Upload Logo'}
                  </label>
                  <input
                    type='file'
                    id='logo'
                    {...register('logoFile')}
                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                  />
                  {logo && (
                    <div className='mt-2'>
                      <img src={logo} alt='Current Logo' style={{ width: '100px', height: '100px' }} />
                    </div>
                  )}
                </div>
                <div>
                  <div>
                    <label htmlFor='images' className='block text-sm font-medium text-gray-700'>
                      Upload Campground Images
                    </label>
                    <input
                      type='file'
                      id='images'
                      multiple
                      {...register('imageFiles')}
                      className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                    />
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    {images.map((imageUrl, index) => (
                      <div
                        key={index}
                        style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100px' }}>
                        <img src={imageUrl} alt={`Campground Image ${index + 1}`} style={{ width: '100%', height: 'auto', marginBottom: '5px' }} />
                        <button
                          type='button'
                          onClick={() => toggleDeleteImage(imageUrl)}
                          style={{ background: 'red', color: 'white', padding: '2px 5px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                          {imagesToDelete.includes(imageUrl) ? 'Undo' : 'X'}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className='space-y-4 bg-white p-4 rounded-lg shadow'>
              <h2 className='text-xl font-semibold text-gray-900'>Amenities</h2>
              <div className='mt-4 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4'>
                {amenitiesConfig.map((amenity) => (
                  <div key={amenity.id} className='flex items-center p-2 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors'>
                    <input
                      {...register(`amenities.${amenity.id}`)}
                      type='checkbox'
                      id={`amenity-${amenity.id}`}
                      className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded mr-3'
                    />
                    <label htmlFor={`amenity-${amenity.id}`} className='flex items-center text-sm font-medium text-gray-700'>
                      <amenity.icon className='text-lg mr-2' /> {amenity.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <button
              type='submit'
              className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
              Save Campground
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CampgroundForm
