import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ProfilePhoto from '../../shared/components/ProfilePhoto'
import { FaTrash, FaEdit, FaSave, FaTimes } from 'react-icons/fa'
import { toast } from 'react-toastify'
import postRepository from '../../../repositories/firestore/post.firestore.repository'
import { formatTimestampAsDate } from '../../../utils/formatDateUtil'

const PostItem = ({ post, forumId, threadId, canDelete, onDelete, currentUserId }) => {
  const navigate = useNavigate()
  const [editMode, setEditMode] = useState(false)
  const [editedContent, setEditedContent] = useState(post.content)

  const handleViewProfile = () => {
    navigate(`/user/profile/${post.userId}`)
  }

  const handleEdit = () => {
    setEditMode(true)
  }

  const handleSave = async () => {
    if (window.confirm('Are you sure you want to save changes?')) {
      const updatedPostData = {
        ...post,
        content: editedContent,
      }
      try {
        await postRepository.updatePost(forumId, threadId, post.id, updatedPostData)
        setEditMode(false)
        post.content = editedContent // Update local state to reflect the saved changes
        toast.success('Post saved successfully')
      } catch (error) {
        toast.error('Error saving post.')
        console.error('Error saving post:', error)
      }
    }
  }

  const handleCancel = () => {
    setEditedContent(post.content)
    setEditMode(false)
  }

  return (
    <div className='break-words mb-2 p-2 bg-white shadow rounded flex items-start justify-between'>
      <div className='flex cursor-pointer' onClick={!editMode ? handleViewProfile : undefined}>
        <ProfilePhoto src={post.profilePhotoUrl} displayName={post.displayName} size='10' className='mr-2' />
        <div className='flex flex-col flex-grow min-w-0'>
          {editMode ? (
            <textarea
              value={editedContent}
              onChange={(e) => setEditedContent(e.target.value)}
              className='flex-1 mt-1 p-2 border border-gray-300 rounded-md'
            />
          ) : (
            <>
              <span className='text-sm font-semibold no-underline text-black'>{post.displayName}</span>
              <span className='text-gray-800'>{post.content}</span>
              <span className='text-sm text-gray-500'>{formatTimestampAsDate(post.createdAt)}</span>
            </>
          )}
        </div>
      </div>
      <div className='flex items-center'>
        {canDelete && currentUserId === post.userId && (
          <>
            {editMode ? (
              <>
                <button onClick={handleSave} className='p-1 text-green-500 hover:text-green-700'>
                  <FaSave />
                </button>
                <button onClick={handleCancel} className='p-1 text-red-500 hover:text-red-700'>
                  <FaTimes />
                </button>
              </>
            ) : (
              <>
                <button onClick={handleEdit} className='p-1 text-blue-500 hover:text-blue-700'>
                  <FaEdit />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    if (window.confirm('Are you sure you want to delete this post?')) {
                      onDelete(post.id)
                    }
                  }}
                  className='p-1 text-gray-500 hover:text-gray-700'>
                  <FaTrash />
                </button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default PostItem
