import React from 'react'
import AdSense from 'react-adsense'

const ChatAdComponent = ({ slot, key }) => {
  return (
    <AdSense.Google
      // className='my-ad-class'
      style={{ display: 'inline-block', width: '320px', height: '50px' }}
      client='ca-pub-3505900880382517'
      slot={slot}
      format='' // potentially leave this empty or ensure it's set appropriately
      responsive='false' // ensure responsive behavior is turned off
      key={key}
    />
  )
}

export default ChatAdComponent
