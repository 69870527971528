import { collection, query, orderBy, doc, addDoc, updateDoc, getDoc, deleteDoc, getDocs, Timestamp, limit, startAfter } from 'firebase/firestore'
import baseRepository from './base.firestore.repository'

const threadsCollectionRef = (forumId) => collection(doc(collection(baseRepository.getDb(), 'forums'), forumId), 'threads')

let threadsCache = {
  allThreads: {},
  byId: {},
}

const getThreadsByForumId = async (forumId) => {
  const now = Date.now()
  const cacheKey = `threads-${forumId}`
  if (threadsCache.allThreads[cacheKey] && now - threadsCache.allThreads[cacheKey].lastFetch < threadsCache.allThreads[cacheKey].cacheDuration) {
    return threadsCache.allThreads[cacheKey].data
  }

  try {
    const q = query(threadsCollectionRef(forumId), orderBy('lastUpdated', 'desc'))
    const threads = await baseRepository.getDocsFromQuery(q)
    threadsCache.allThreads[cacheKey] = {
      data: threads,
      lastFetch: now,
      cacheDuration: 1000 * 60 * 5, // 5 minutes
    }
    return threads
  } catch (error) {
    console.error('Error retrieving threads:', error)
    throw error
  }
}

const getThreadById = async (forumId, threadId) => {
  const now = Date.now()
  const cacheKey = `thread-${forumId}-${threadId}`
  if (threadsCache.byId[cacheKey] && now - threadsCache.byId[cacheKey].lastFetch < threadsCache.byId[cacheKey].cacheDuration) {
    return threadsCache.byId[cacheKey].data
  }

  try {
    const docRef = doc(threadsCollectionRef(forumId), threadId)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const thread = { id: docSnap.id, ...docSnap.data() }
      threadsCache.byId[cacheKey] = {
        data: thread,
        lastFetch: now,
        cacheDuration: 1000 * 60 * 5, // 5 minutes
      }
      return thread
    } else {
      throw new Error('Thread not found')
    }
  } catch (error) {
    console.error('Error retrieving thread:', error)
    throw error
  }
}

const addThread = async (forumId, threadData) => {
  try {
    // Add a lastUpdated field with the current server timestamp
    const fullThreadData = {
      ...threadData,
      createdAt: Timestamp.now(), // Adds creation timestamp
      lastUpdated: Timestamp.now(), // Firestore server timestamp
    }

    const docRef = await addDoc(threadsCollectionRef(forumId), fullThreadData)
    const cacheKey = `threads-${forumId}` // Identify the correct cache key
    threadsCache.allThreads[cacheKey] = null // Invalidate the cache
    return docRef.id
  } catch (error) {
    console.error('Error adding thread:', error)
    throw error
  }
}

const updateThread = async (forumId, threadId, threadData) => {
  try {
    const threadRef = doc(threadsCollectionRef(forumId), threadId)
    const updatedData = {
      ...threadData,
      lastUpdated: Timestamp.now(), // Update the lastUpdated timestamp on each update
    }
    await updateDoc(threadRef, updatedData)
    const cacheKey = `thread-${forumId}-${threadId}`
    threadsCache.byId[cacheKey] = null // Invalidate specific thread cache
    return threadId
  } catch (error) {
    console.error(`Error updating thread with ID ${threadId}:`, error)
    throw error
  }
}

const deleteThread = async (forumId, threadId) => {
  try {
    const threadRef = doc(threadsCollectionRef(forumId), threadId)
    await deleteDoc(threadRef)
    const cacheKey = `thread-${forumId}-${threadId}`
    threadsCache.byId[cacheKey] = null // Invalidate specific thread cache
    return threadId
  } catch (error) {
    console.error(`Error deleting thread with ID ${threadId}:`, error)
    throw error
  }
}

const getPaginatedThreads = async (forumId, lastVisible = null, pageSize = 10) => {
  let q = query(threadsCollectionRef(forumId), orderBy('createdAt', 'desc'), limit(pageSize))

  if (lastVisible) {
    q = query(q, startAfter(lastVisible))
  }

  try {
    const snapshot = await getDocs(q)
    const threads = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    const lastVisibleThread = snapshot.docs[snapshot.docs.length - 1]
    return { threads, lastVisibleThread }
  } catch (error) {
    console.error('Error fetching paginated threads:', error)
    throw error
  }
}

const threadRepository = {
  getThreadsByForumId,
  getThreadById,
  addThread,
  updateThread,
  deleteThread,
  getPaginatedThreads,
}

export default threadRepository
