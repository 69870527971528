import React from 'react'
import { Link } from 'react-router-dom'

const CampgroundList = ({ campgrounds }) => {
  return (
    <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
      {campgrounds.map((campground) => (
        <div key={campground.id} className='p-4 bg-white shadow rounded-lg'>
          <div className='mt-4'>
            <Link to={`/campground/${campground.slug}`}>
              <img className='w-full h-48 object-contain rounded-t-lg' src={campground.logo} alt={campground.name} />
              <h3 className='text-lg font-semibold'>{campground.name}</h3>
              <p className='text-gray-500'>{campground.tagline}</p>
            </Link>
          </div>
        </div>
      ))}
    </div>
  )
}

export default CampgroundList
