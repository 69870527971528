import React, { useEffect, useState } from 'react'
import BlogPostItem from './BlogPostItem'
import blogsRepository from '../../../../repositories/firestore/blogs.firestore.repository'

const BlogPostList = ({ count, vertical = false }) => {
  const [blogs, setBlogs] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const containerStyle = vertical ? 'grid grid-cols-1 gap-4' : 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await blogsRepository.getLatestBlogs(count)
        setBlogs(data)
        setLoading(false)
      } catch (error) {
        setError(error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <div className={containerStyle}>
      {blogs &&
        blogs.map((blogPost) => (
          <BlogPostItem
            id={blogPost.id}
            slug={blogPost.slug}
            key={blogPost.id}
            title={blogPost.title}
            author={blogPost.author}
            date={blogPost.timestamp.toDate().toLocaleDateString('en-US')}
            content={blogPost.content}
            imageSrc={blogPost.featuredImage} // Replace with the actual image source for the blog post
            alt={blogPost.title} // Replace with the alt text for the image
          />
        ))}
    </div>
  )
}

export default BlogPostList
