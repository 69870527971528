import storageRepository from './base.storage.repository'

export const uploadCampgroundLogo = async (file, campgroundId) => {
  if (!file) throw new Error('No file provided for upload.')
  const directory = `campgrounds/logos/${campgroundId}`
  const filePath = `${directory}/${file.name}`
  const downloadURL = await storageRepository.uploadFile(filePath, file)
  return downloadURL
}

export const uploadCampgroundImage = async (file, campgroundId) => {
  if (!file) throw new Error('No file provided for upload.')
  const directory = `campgrounds/images/${campgroundId}`
  const filePath = `${directory}/${file.name}`
  const downloadURL = await storageRepository.uploadFile(filePath, file)
  return downloadURL
}

export const deleteCampgroundImage = async (filePath) => {
  try {
    await storageRepository.deleteFile(filePath)
    return true // Return true or some success indicator
  } catch (error) {
    console.error(`Error deleting image: ${filePath}`, error)
    throw error // Propagate the error or handle it as needed
  }
}
