import { query, orderBy, limit, addDoc, collection, onSnapshot, doc, deleteDoc } from 'firebase/firestore'
import baseRepository from './base.firestore.repository'

const getChatMessagesRef = (chatRoomName) => {
  return collection(baseRepository.getDb(), `chatRooms/${chatRoomName}/messages`)
}

// Function to subscribe to chat messages
const subscribeToMessages = (chatRoomName, callback) => {
  const messagesRef = getChatMessagesRef(chatRoomName)
  const q = query(messagesRef, orderBy('timestamp', 'desc'))

  // Listen for real-time updates
  const unsubscribe = onSnapshot(
    q,
    (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          callback({ type: 'added', message: { id: change.doc.id, ...change.doc.data() } })
        } else if (change.type === 'removed') {
          callback({ type: 'removed', id: change.doc.id })
        }
      })
    },
    (error) => {
      console.error('Error subscribing to messages:', error)
    },
  )

  return unsubscribe // Return the unsubscribe function to allow caller to stop listening
}

const fetchRecentMessages = async (chatRoomName, count = 50) => {
  const messagesRef = getChatMessagesRef(chatRoomName)
  const q = query(messagesRef, orderBy('timestamp', 'desc'), limit(count))
  try {
    const messages = await baseRepository.getDocsFromQuery(q)
    return messages // Now returning the messages
  } catch (error) {
    console.error('Error fetching messages:', error)
    throw error // Propagating error to be handled by the caller
  }
}

const postMessage = async (chatRoomName, messageData) => {
  const messagesRef = getChatMessagesRef(chatRoomName)
  try {
    const docRef = await addDoc(messagesRef, messageData)
    return docRef.id // Returns the ID of the newly created message
  } catch (error) {
    console.error('Error posting message:', error)
    throw error // Propagating error to be handled by the caller
  }
}

const deleteMessage = async (chatRoomName, messageId) => {
  const messageRef = doc(baseRepository.getDb(), `chatRooms/${chatRoomName}/messages`, messageId)
  try {
    await deleteDoc(messageRef)
  } catch (error) {
    console.error('Error deleting message:', error)
    throw error
  }
}

const chatRepository = {
  subscribeToMessages,
  fetchRecentMessages,
  postMessage,
  deleteMessage,
}

export default chatRepository
