import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import { Country, State } from 'country-state-city'
import { toast } from 'react-toastify'
import { Timestamp } from 'firebase/firestore'
import eventRepository from '../../../../repositories/firestore/events.firestore.repository'
import campgroundRepository from '../../../../repositories/firestore/campgrounds.firestore.repository'
import { uploadEventCoverImage, deleteEventImage } from '../../../../repositories/storage/events.storage.repository'

const EventForm = () => {
  const navigate = useNavigate()
  const { eventId } = useParams()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm()

  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [coverImage, setCoverImage] = useState('')
  const [campgrounds, setCampgrounds] = useState([])

  // Watch country field to update states dropdown
  const selectedCountry = watch('country')

  useEffect(() => {
    if (eventId) {
      const fetchEventData = async () => {
        try {
          const data = await eventRepository.getEventById(eventId)
          if (data) {
            // Populate form fields
            Object.keys(data).forEach((key) => {
              if (typeof data[key] === 'object' && data[key] !== null && key !== 'startDate' && key !== 'endDate') {
                // For nested objects, handle them separately
                Object.keys(data[key]).forEach((subKey) => {
                  setValue(`${key}.${subKey}`, data[key][subKey])
                })
              } else if (key === 'startDate' || key === 'endDate') {
                // Convert seconds to ISO string for date input
                const dateIso = new Date(data[key].seconds * 1000).toISOString().substring(0, 16)
                setValue(key, dateIso)
              } else {
                setValue(key, data[key])
              }
            })
            setCoverImage(data.coverImage || '')
          }
        } catch (error) {
          console.error('Failed to fetch event data:', error)
        }
      }

      fetchEventData()
    }
  }, [eventId, setValue])

  useEffect(() => {
    setCountries(Country.getAllCountries())
  }, [])

  useEffect(() => {
    const fetchCampgrounds = async () => {
      try {
        const camps = await campgroundRepository.getAllCampgrounds()
        setCampgrounds(camps)
      } catch (error) {
        console.error('Failed to fetch campgrounds:', error)
        toast.error('Failed to load campgrounds.')
      }
    }

    fetchCampgrounds()
  }, [])

  useEffect(() => {
    // Load states for selected country
    if (selectedCountry) {
      setStates(State.getStatesOfCountry(selectedCountry))
    }
  }, [selectedCountry])

  const onSubmit = async (data) => {
    console.log(data)
    try {
      const { coverImageFile, ...formData } = data
      let coverImageUrl = ''

      // Convert date strings to Firestore timestamp format
      formData.startDate = convertDateToFirestoreTimestamp(formData.startDate)
      formData.endDate = convertDateToFirestoreTimestamp(formData.endDate)

      let eventId = formData.id // Assume formData contains the event ID for existing events.

      // Step 1: If creating a new event, add it first to get an event ID.
      if (!eventId) {
        eventId = await eventRepository.addEvent(formData)
        toast.success('Event added successfully!')
      }

      // Step 2: Upload the new cover image if one has been selected.
      if (coverImageFile && coverImageFile.length > 0) {
        coverImageUrl = await uploadCoverImage(coverImageFile[0], eventId)
      }

      // Step 3: Update the event with the new cover image URL or existing formData.
      // This step is necessary for both new events and editing existing events where the cover image might change.
      const updateData = coverImageUrl ? { ...formData, coverImage: coverImageUrl } : formData
      await eventRepository.updateEvent(eventId, updateData)
      toast.success('Event updated successfully!')

      // Cleanup and Navigation
      postSubmissionCleanup()
    } catch (error) {
      console.error('Form submission error:', error)
      toast.error('Failed to process the form.')
    }
  }

  const uploadCoverImage = async (file, eventId) => {
    try {
      const coverImageUrl = await uploadEventCoverImage(file, eventId) // Ensure this uploads and returns the URL
      return coverImageUrl
    } catch (error) {
      console.error('Error uploading cover image:', error)
      toast.error('Failed to upload cover image.')
      throw error
    }
  }

  const convertDateToFirestoreTimestamp = (dateString) => {
    const date = new Date(dateString)
    return Timestamp.fromDate(date)
  }

  const postSubmissionCleanup = () => {
    reset() // Reset form fields after submission
    navigate('/admin/events') // Redirect to the events list
  }

  const generateSlug = (title) => {
    // Simple slug generation: lowercase, replace spaces with dashes, remove non-alphanumeric
    return title
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9-]/g, '')
  }

  // Rest of the component, including the form rendering, goes here...
  // You'll need to adapt the form fields to match those of an event, such as title, description, start and end dates, venue, etc.

  return (
    <div className='w-full px-4 mt-10'>
      <div>
        <h2 className='text-3xl font-bold mb-4'>Edit Event</h2>
        <div className='space-y-2'>
          {' '}
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Campground Dropdown */}
            <div>
              <label htmlFor='campgroundId' className='block text-sm font-medium text-gray-700'>
                Select Campground
              </label>
              <select
                {...register('campgroundId', { required: false })}
                className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'>
                <option value=''>Select a campground</option>
                {campgrounds.map((camp) => (
                  <option key={camp.id} value={camp.id}>
                    {camp.name}
                  </option>
                ))}
              </select>
            </div>
            {/* Event Title */}
            <div>
              <label htmlFor='title' className='block text-sm font-medium text-gray-700'>
                Event Title
              </label>
              <input
                {...register('title', { required: true })}
                type='text'
                id='title'
                onBlur={(e) => setValue('slug', generateSlug(e.target.value), { shouldValidate: true })}
                className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                placeholder='Enter event title'
              />
              {errors.title && <p className='text-red-500 text-xs italic'>Event title is required.</p>}
            </div>

            {/* Slug
            <div>
              <label htmlFor='slug' className='block text-sm font-medium text-gray-700'>
                Slug
              </label>
              <input
                {...register('slug', { required: true, pattern: /^[a-z0-9]+(?:-[a-z0-9]+)*$/ })}
                type='text'
                id='slug'
                className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                placeholder='unique-event-slug'
              />
              {errors.slug && <p className='text-red-500 text-xs italic'>Slug is required and must be URL-friendly.</p>}
            </div> */}

            {/* Event Description */}
            <div>
              <label htmlFor='description' className='block text-sm font-medium text-gray-700'>
                Description
              </label>
              <textarea
                {...register('description', { required: true })}
                id='description'
                className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                rows='4'
                placeholder='Describe the event'></textarea>
              {errors.description && <p className='text-red-500 text-xs italic'>Description is required.</p>}
            </div>

            {/* Event Type */}
            <div>
              <label htmlFor='type' className='block text-sm font-medium text-gray-700'>
                Event Type
              </label>
              <select
                {...register('type', { required: true })}
                id='type'
                className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'>
                <option value=''>Select event type</option>
                <option value='Regional'>Regional</option>
                <option value='National'>National</option>
                <option value='Local'>Local</option>
                <option value='International'>International</option>
                <option value='Campground'>Campground</option>
              </select>
              {errors.type && <p className='text-red-500 text-xs italic'>Event type is required.</p>}
            </div>

            {/* Start and End Dates */}
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div>
                <label htmlFor='startDate' className='block text-sm font-medium text-gray-700'>
                  Start Date & Time
                </label>
                <input
                  {...register('startDate', { required: true })}
                  type='datetime-local'
                  id='startDate'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                />
                {errors.startDate && <p className='text-red-500 text-xs italic'>Start date and time are required.</p>}
              </div>
              <div>
                <label htmlFor='endDate' className='block text-sm font-medium text-gray-700'>
                  End Date & Time
                </label>
                <input
                  {...register('endDate', { required: true })}
                  type='datetime-local'
                  id='endDate'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                />
                {errors.endDate && <p className='text-red-500 text-xs italic'>End date and time are required.</p>}
              </div>
            </div>

            {/* Venue and Address Information */}
            <div>
              <label htmlFor='venue' className='block text-sm font-medium text-gray-700'>
                Venue
              </label>
              <input
                {...register('venue', { required: false })}
                type='text'
                id='venue'
                className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                placeholder='Enter venue name'
              />
              {errors.venue && <p className='text-red-500 text-xs italic'>Venue is required.</p>}
            </div>

            {/* Country, State/Province, City, and Postal Code */}
            <div className='grid grid-cols-2 gap-4'>
              {/* Country */}
              <div>
                <label htmlFor='country' className='block text-sm font-medium text-gray-700'>
                  Country
                </label>
                <select
                  {...register('country', { required: false })}
                  id='country'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'>
                  <option value=''>Select a country</option>
                  {countries.map((country) => (
                    <option key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </option>
                  ))}
                </select>
                {errors.country && <p className='text-red-500 text-xs italic'>Country is required.</p>}
              </div>

              {/* State/Province */}
              <div>
                <label htmlFor='stateProvince' className='block text-sm font-medium text-gray-700'>
                  State/Province (There is a bug here you have to reselect it every time.)
                </label>
                <select
                  {...register('stateProvince', { required: false })}
                  id='stateProvince'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'>
                  <option value=''>Select a state/province</option>
                  {states.map((state) => (
                    <option key={state.isoCode} value={state.isoCode}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Address */}
            <div>
              <label htmlFor='address' className='block text-sm font-medium text-gray-700'>
                Address
              </label>
              <input
                {...register('address', { required: false })}
                type='text'
                id='address'
                className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                placeholder='1234 Main St'
              />
              {errors.address && <p className='text-red-500 text-xs italic'>Address is required.</p>}
            </div>

            <div className='grid grid-cols-2 gap-4'>
              {/* City */}
              <div>
                <label htmlFor='city' className='block text-sm font-medium text-gray-700'>
                  City
                </label>
                <input
                  {...register('city', { required: false })}
                  type='text'
                  id='city'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                  placeholder='Enter city'
                />
                {errors.city && <p className='text-red-500 text-xs italic'>City is required.</p>}
              </div>

              {/* Postal Code */}
              <div>
                <label htmlFor='postalCode' className='block text-sm font-medium text-gray-700'>
                  Postal Code
                </label>
                <input
                  {...register('postalCode', { required: false })}
                  type='text'
                  id='postalCode'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                  placeholder='Enter postal code'
                />
              </div>
            </div>

            {/* External Link */}
            <div>
              <label htmlFor='externalLink' className='block text-sm font-medium text-gray-700'>
                External Link
              </label>
              <input
                {...register('externalLink', { required: false })}
                type='url'
                id='externalLink'
                className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                placeholder='http://'
              />
            </div>

            {/* Cover Image Upload */}
            <div>
              <label htmlFor='coverImage' className='block text-sm font-medium text-gray-700'>
                {coverImage ? 'Replace Cover Image' : 'Upload Cover Image'}
              </label>
              <input
                type='file'
                id='coverImage'
                {...register('coverImageFile')}
                className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
              />
              {coverImage && (
                <div className='mt-2'>
                  <img src={coverImage} alt='Cover Image' style={{ width: '100px', height: '100px' }} />
                </div>
              )}
            </div>

            {/* Submit Button */}
            <button
              type='submit'
              className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
              Save Event
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EventForm
