import { collection, query, orderBy, doc, addDoc, updateDoc, getDoc, deleteDoc, getDocs, Timestamp, limit, startAfter } from 'firebase/firestore'
import baseRepository from './base.firestore.repository'

const postsCollectionRef = (forumId, threadId) =>
  collection(doc(collection(doc(baseRepository.getDb(), 'forums', forumId), 'threads'), threadId), 'posts')

let postsCache = {
  allPosts: {},
  byId: {},
}

const getPostsByThreadId = async (forumId, threadId) => {
  console.log('Forum ID:', forumId, 'Thread ID:', threadId)

  const now = Date.now()
  const cacheKey = `posts-${forumId}-${threadId}`
  if (postsCache.allPosts[cacheKey] && now - postsCache.allPosts[cacheKey].lastFetch < postsCache.allPosts[cacheKey].cacheDuration) {
    return postsCache.allPosts[cacheKey].data
  }

  try {
    const q = query(postsCollectionRef(forumId, threadId), orderBy('createdAt', 'asc'))
    const posts = await baseRepository.getDocsFromQuery(q)
    postsCache.allPosts[cacheKey] = {
      data: posts,
      lastFetch: now,
      cacheDuration: 1000 * 60 * 5, // 5 minutes
    }
    return posts
  } catch (error) {
    console.error('Error retrieving posts:', error)
    throw error
  }
}

const getPostById = async (forumId, threadId, postId) => {
  const now = Date.now()
  const cacheKey = `post-${forumId}-${threadId}-${postId}`
  if (postsCache.byId[cacheKey] && now - postsCache.byId[cacheKey].lastFetch < postsCache.byId[cacheKey].cacheDuration) {
    return postsCache.byId[cacheKey].data
  }

  try {
    const docRef = doc(postsCollectionRef(forumId, threadId), postId)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const post = { id: docSnap.id, ...docSnap.data() }
      postsCache.byId[cacheKey] = {
        data: post,
        lastFetch: now,
        cacheDuration: 1000 * 60 * 5, // 5 minutes
      }
      return post
    } else {
      throw new Error('Post not found')
    }
  } catch (error) {
    console.error('Error retrieving post:', error)
    throw error
  }
}

const addPost = async (forumId, threadId, postData) => {
  console.log('forumid:', forumId, 'threadid:', threadId, 'postdata:', postData)
  try {
    const completePostData = {
      ...postData,
      createdAt: Timestamp.now(), // Adds creation timestamp
      lastUpdated: Timestamp.now(), // Adds last updated timestamp
    }
    const docRef = await addDoc(postsCollectionRef(forumId, threadId), completePostData)
    const cacheKey = `posts-${forumId}-${threadId}`
    postsCache.allPosts[cacheKey] = null // Invalidate the cache
    return docRef.id
  } catch (error) {
    console.error('Error adding post:', error)
    throw error
  }
}

const updatePost = async (forumId, threadId, postId, postData) => {
  try {
    const updateData = {
      ...postData,
      lastUpdated: Timestamp.now(), // Updates the last updated timestamp
    }
    const postRef = doc(postsCollectionRef(forumId, threadId), postId)
    await updateDoc(postRef, updateData)
    const cacheKey = `post-${forumId}-${threadId}-${postId}`
    postsCache.byId[cacheKey] = null // Invalidate specific post cache
    return postId
  } catch (error) {
    console.error(`Error updating post with ID ${postId}:`, error)
    throw error
  }
}

const deletePost = async (forumId, threadId, postId) => {
  try {
    const postRef = doc(postsCollectionRef(forumId, threadId), postId)
    await deleteDoc(postRef)
    const cacheKey = `post-${forumId}-${threadId}-${postId}`
    postsCache.byId[cacheKey] = null // Invalidate specific post cache
    return postId
  } catch (error) {
    console.error(`Error deleting post with ID ${postId}:`, error)
    throw error
  }
}

const getPaginatedPosts = async (forumId, threadId, lastVisible = null, pageSize = 10) => {
  let q = query(postsCollectionRef(forumId, threadId), orderBy('createdAt', 'asc'), limit(pageSize))

  if (lastVisible) {
    q = query(q, startAfter(lastVisible))
  }

  try {
    const snapshot = await getDocs(q)
    const posts = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    const lastVisiblePost = snapshot.docs[snapshot.docs.length - 1]
    return { posts, lastVisiblePost }
  } catch (error) {
    console.error('Error fetching paginated posts:', error)
    throw error
  }
}

const postRepository = {
  getPostsByThreadId,
  getPostById,
  addPost,
  updatePost,
  deletePost,
  getPaginatedPosts,
}

export default postRepository
