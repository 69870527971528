import { collection, query, where, orderBy, doc, addDoc, updateDoc, getDoc, deleteDoc, limit, getDocs, Timestamp } from 'firebase/firestore'
import baseRepository from './base.firestore.repository'
import { startOfWeek, endOfWeek, addDays } from 'date-fns'
import campgroundRepository from './campgrounds.firestore.repository'

const eventsCollectionRef = collection(baseRepository.getDb(), 'events')

// Define a general cache duration for all events-related caching.
const generalCacheDuration = 1000 * 60 * 60 // 1 hour

let eventsCache = {
  allEvents: {
    data: null,
    lastFetch: 0,
    cacheDuration: 1000 * 60 * 60, // 1 hour
  },
  latestEvents: {},
  campgroundLatestEvents: {},
  eventsBySlug: {},
  eventsById: {},
}

let weeklyEventsCache = {}

const getAllEvents = async () => {
  //this is only used for admin screen so needs to be always up to date.

  const now = Date.now()
  // Check if cached data exists and hasn't expired
  // if (eventsCache.allEvents.data && now - eventsCache.allEvents.lastFetch < eventsCache.allEvents.cacheDuration) {
  //   console.log('Returning all events from cache')
  //   return eventsCache.allEvents.data
  // }

  try {
    const q = query(eventsCollectionRef, orderBy('startDate', 'desc'))
    const events = await baseRepository.getDocsFromQuery(q)
    // Update cache
    //eventsCache.allEvents.data = events
    //eventsCache.allEvents.lastFetch = now
    return events
  } catch (error) {
    console.error('Error retrieving all events:', error)
    throw error
  }
}

const getCampgroundAdminEvents = async (campgroundId) => {
  try {
    const q = query(eventsCollectionRef, where('type', '==', 'Campground'), where('campgroundId', '==', campgroundId), orderBy('startDate', 'desc'))
    const events = await baseRepository.getDocsFromQuery(q)
    return events
  } catch (error) {
    console.error('Error retrieving events for campground admin:', error)
    throw error
  }
}

const getLatestRegionalEvents = async (count, sinceDate = new Date()) => {
  const now = Date.now()
  const cacheKey = `latest-${count}-${sinceDate.toISOString()}`
  // Check cache first
  if (eventsCache.latestEvents[cacheKey] && now - eventsCache.latestEvents[cacheKey].lastFetch < eventsCache.allEvents.cacheDuration) {
    console.log('Returning latest events from cache')
    return eventsCache.latestEvents[cacheKey].data
  }

  try {
    const q = query(
      eventsCollectionRef,
      where('type', '==', 'Regional'),
      where('startDate', '>', sinceDate),
      orderBy('startDate', 'asc'),
      limit(count),
    )
    const events = await baseRepository.getDocsFromQuery(q)
    // Update cache
    eventsCache.latestEvents[cacheKey] = {
      data: events,
      lastFetch: now,
    }
    return events
  } catch (error) {
    console.error(`Error retrieving latest ${count} events:`, error)
    throw error
  }
}

const getLatestCampgroundEvents = async (campgroundId, count, sinceDate = new Date()) => {
  const now = Date.now()
  const cacheKey = `campground-latest-${campgroundId}-${count}-${sinceDate.toISOString()}`
  // Check cache first
  if (
    eventsCache.campgroundLatestEvents[cacheKey] &&
    now - eventsCache.campgroundLatestEvents[cacheKey].lastFetch < eventsCache.allEvents.cacheDuration
  ) {
    console.log('Returning latest events from cache')
    return eventsCache.campgroundLatestEvents[cacheKey].data
  }

  try {
    const q = query(
      eventsCollectionRef,
      where('campgroundId', '==', campgroundId),
      where('type', '==', 'Campground'),
      where('startDate', '>', sinceDate),
      orderBy('startDate', 'asc'),
      limit(count),
    )
    const events = await baseRepository.getDocsFromQuery(q)
    // Update cache
    eventsCache.campgroundLatestEvents[cacheKey] = {
      data: events,
      lastFetch: now,
    }
    return events
  } catch (error) {
    console.error(`Error retrieving latest ${count} events:`, error)
    throw error
  }
}

const getEventsByWeek = async (date) => {
  const startOfTheWeek = startOfWeek(date, { weekStartsOn: 0 })
  const endOfTheWeek = endOfWeek(date, { weekStartsOn: 0 })

  const startTimestamp = Timestamp.fromDate(startOfTheWeek)
  const endTimestamp = Timestamp.fromDate(endOfTheWeek)

  const cacheKey = `events-${startTimestamp.toDate().toISOString()}-${endTimestamp.toDate().toISOString()}`

  // Check if the data is in the cache and it is still valid
  if (weeklyEventsCache[cacheKey] && Date.now() - weeklyEventsCache[cacheKey].lastFetch < generalCacheDuration) {
    console.log('Returning cached events for this week')
    return weeklyEventsCache[cacheKey].data
  }

  try {
    const q = query(
      eventsCollectionRef,
      where('type', '==', 'Campground'),
      where('startDate', '>=', startTimestamp),
      where('startDate', '<=', endTimestamp),
      orderBy('startDate', 'asc'),
    )

    const snapshot = await getDocs(q)
    const events = await Promise.all(
      snapshot.docs.map(async (doc) => {
        const eventData = doc.data()
        const campground = await campgroundRepository.getCampgroundById(eventData.campgroundId)
        return {
          id: doc.id,
          ...eventData,
          startDate: eventData.startDate.toDate(), // Assuming startDate is a Timestamp
          endDate: eventData.endDate.toDate(), // Assuming endDate is a Timestamp
          campgroundName: campground ? campground.name : 'Unknown Campground',
          campgroundCity: campground ? campground.address?.city : '?',
          campgroundState: campground ? campground.address?.state : '?',
        }
      }),
    )

    // Sort events by startDate
    events.sort((a, b) => a.startDate - b.startDate)

    // Update cache
    weeklyEventsCache[cacheKey] = {
      data: events,
      lastFetch: Date.now(),
    }

    return events
  } catch (error) {
    console.error('Error fetching events for this week:', error)
    throw error
  }
}

// //Function to fetch events for the current week across all campgrounds
// const getAllCampgroundsEventsThisWeek = async () => {
//   const today = new Date()
//   const startOfThisWeek = startOfWeek(today, { weekStartsOn: 0 }) // Sunday as the start of the week
//   const endOfThisWeek = endOfWeek(today, { weekStartsOn: 0 }) // Saturday as the end of the week

//   const cacheKey = `events-this-week-${startOfThisWeek.toISOString()}-${endOfThisWeek.toISOString()}`

//   if (eventsCache[cacheKey] && Date.now() - eventsCache[cacheKey].lastFetch < eventsCache[cacheKey].cacheDuration) {
//     console.log('Returning cached events for this week')
//     return eventsCache[cacheKey].data
//   }

//   try {
//     const q = query(
//       eventsCollectionRef,
//       where('startDate', '>=', startOfThisWeek),
//       where('startDate', '<=', endOfThisWeek),
//       orderBy('startDate', 'asc'),
//     )

//     const snapshot = await getDocs(q)
//     let events = await Promise.all(
//       snapshot.docs.map(async (doc) => {
//         const eventData = doc.data()
//         const campground = await campgroundRepository.getCampgroundById(eventData.campgroundId)
//         return {
//           id: doc.id,
//           ...eventData,
//           startDate: eventData.startDate.toDate().toISOString(),
//           endDate: eventData.endDate.toDate().toISOString(),
//           campgroundName: campground ? campground.name : 'Unknown Campground',
//           campgroundCity: campground ? campground.address?.city : '?',
//           campgroundState: campground ? campground.address?.state : '?',
//         }
//       }),
//     )

//     // Sort events by campground name
//     events = events.sort((a, b) => a.campgroundName.localeCompare(b.campgroundName))

//     // Update cache
//     eventsCache[cacheKey] = {
//       data: events,
//       lastFetch: Date.now(),
//     }

//     return events
//   } catch (error) {
//     console.error('Error fetching events for this week:', error)
//     throw error
//   }
// }

const getRegionalEventBySlug = async (slug) => {
  const now = Date.now()
  // Check cache first
  if (eventsCache.eventsBySlug[slug] && now - eventsCache.eventsBySlug[slug].lastFetch < eventsCache.allEvents.cacheDuration) {
    console.log('Returning event by slug from cache')
    return eventsCache.eventsBySlug[slug].data
  }

  try {
    const q = query(eventsCollectionRef, where('slug', '==', slug), where('type', '==', 'Regional'), limit(1))
    const result = await baseRepository.getDocsFromQuery(q)
    if (result.length > 0) {
      const event = result[0]
      // Update cache
      eventsCache.eventsBySlug[slug] = {
        data: event,
        lastFetch: now,
      }
      return event
    } else {
      throw new Error(`Could not find event with slug ${slug}`)
    }
  } catch (error) {
    console.error(`Error retrieving event by slug:`, error)
    throw error
  }
}

const getEventById = async (eventId) => {
  const now = Date.now()
  // Check cache first
  if (eventsCache.eventsById[eventId] && now - eventsCache.eventsById[eventId].lastFetch < eventsCache.allEvents.cacheDuration) {
    console.log('Returning event by ID from cache')
    return eventsCache.eventsById[eventId].data
  }

  try {
    const docRef = doc(baseRepository.getDb(), 'events', eventId)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const eventData = { id: docSnap.id, ...docSnap.data() }
      // Update cache
      eventsCache.eventsById[eventId] = {
        data: eventData,
        lastFetch: now,
      }
      return eventData
    } else {
      console.log('No such document!')
      return null
    }
  } catch (error) {
    console.error(`Error retrieving event with ID ${eventId}:`, error)
    throw error
  }
}

const updateEvent = async (eventId, eventData) => {
  try {
    const eventRef = doc(baseRepository.getDb(), 'events', eventId)
    await updateDoc(eventRef, eventData)
    // Invalidate cache
    eventsCache.data = null
    return eventId // Optionally return the ID of the updated document
  } catch (error) {
    console.error(`Error updating event with ID ${eventId}:`, error)
    throw error // Propagate the error
  }
}

const addEvent = async (eventData) => {
  try {
    const docRef = await addDoc(collection(baseRepository.getDb(), 'events'), eventData)
    // Invalidate cache
    eventsCache.data = null
    return docRef.id // Returns the newly created document's ID
  } catch (error) {
    console.error('Error adding document: ', error)
    throw error
  }
}

const deleteEvent = async (eventId) => {
  try {
    const eventRef = doc(baseRepository.getDb(), 'events', eventId)
    await deleteDoc(eventRef)
    // Invalidate cache
    eventsCache.data = null
    return eventId // Optionally return the ID of the deleted document
  } catch (error) {
    console.error(`Error deleting event with ID ${eventId}:`, error)
    throw error // Propagate the error
  }
}

const repository = {
  getAllEvents,
  getCampgroundAdminEvents,
  getLatestRegionalEvents,
  getLatestCampgroundEvents,
  //getAllCampgroundsEventsThisWeek,
  getEventsByWeek,
  getRegionalEventBySlug,
  getEventById,
  updateEvent,
  addEvent,
  deleteEvent,
}

export default repository
