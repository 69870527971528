import React, { useState, useEffect } from 'react'
import gayIcon from '../../../../img/icons/gay-map-icon.png'
import lesbianIcon from '../../../../img/icons/lesbian-map-icon.png'
import lgbtIcon from '../../../../img/icons/lgbt-map-icon.png'
import lgbtOwnedIcon from '../../../../img/icons/lgbt-owned-map-icon.png'
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api'
import mapStyles from './mapStyles'
import campgroundsFirestoreRepository from '../../../../repositories/firestore/campgrounds.firestore.repository'
import { Link } from 'react-router-dom'

const MAP_CENTER = {
  lat: 39.8097343,
  lng: -98.5556199,
}

function CampgroundMap() {
  const [campgrounds, setCampgrounds] = useState(null)
  const [activeMarker, setActiveMarker] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await campgroundsFirestoreRepository.getAllCampgrounds()
        const newData = await setCampgrounds(data)
        //setLoading(false);
      } catch (error) {
        //setError(error);
        //setLoading(false);
      }
    }

    fetchData()
  }, [])

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return
    }
    setActiveMarker(marker)
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDhgtkMamsxbcA44ndTXN7avyRV8bvIxhY',
  })

  //const [map, setMap] = React.useState(null)

  const onLoad = (marker) => {
    // console.log("marker: ", marker);
  }

  const onUnmount = React.useCallback(function callback(map) {
    //setMap(null)
  }, [])

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={mapStyles.mapContainerStyle}
        center={MAP_CENTER}
        zoom={4}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={() => setActiveMarker(null)}
        options={{
          styles: mapStyles.campgroundMapStyle,
          mapTypeControl: false,
          streetViewControl: false,
        }}>
        {campgrounds &&
          campgrounds.map((campground) => (
            <Marker
              key={campground.id}
              title={campground.name}
              icon={{
                scaledSize: { width: 48, height: 48 },
                url:
                  campground.category === '2'
                    ? gayIcon
                    : campground.category === '4'
                    ? lgbtOwnedIcon
                    : campground.category === '3'
                    ? lesbianIcon
                    : lgbtIcon,
              }}
              onLoad={onLoad}
              position={{ lat: parseFloat(campground.latitude), lng: parseFloat(campground.longitude) }}
              onClick={() => {
                handleActiveMarker(campground.id)
              }}>
              {activeMarker === campground.id ? (
                <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                  <Link to={`/campground/${campground.slug}`}>
                    <div className='flex flex-col items-center'>
                      <img className='mb-2 md:mb-0 w-16 md:h-16 object-contain' src={campground.logo} alt={`${campground.name} Logo`} />
                      <h1 style={{ color: 'black' }}>{campground.name}</h1>
                      <span className='block' style={{ color: 'grey' }}>
                        {campground.tagline}
                      </span>
                    </div>
                  </Link>
                </InfoWindow>
              ) : null}
            </Marker>
          ))}
      </GoogleMap>
    </>
  ) : (
    <></>
  )
}

export default CampgroundMap
