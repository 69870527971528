import React from 'react'

const InfoModal = ({ isOpen, onClose, title, content }) => {
  if (!isOpen) return null

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4'>
      <div className='bg-white rounded-lg p-4 max-w-lg w-full'>
        <h2 className='text-xl font-bold mb-4'>{title}</h2>
        <p>{content}</p>
        <button onClick={onClose} className='mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600'>
          OK
        </button>
      </div>
    </div>
  )
}

export default InfoModal
