import React from 'react'
import AdSense from 'react-adsense'
import PageLayout from '../../shared/components/PageLayout'

const Resources = () => {
  return (
    <PageLayout title='Resources'>
      <p>More information coming soon.</p>
    </PageLayout>
  )
}

export default Resources
