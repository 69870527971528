import React, { useState, useEffect } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import postRepository from '../../../repositories/firestore/post.firestore.repository'
import threadRepository from '../../../repositories/firestore/thread.firestore.repository'
import PostItem from './PostItem'
import { useAuth } from '../../../contexts/AuthContext'
import ProfilePhoto from '../../shared/components/ProfilePhoto'
import PageLayout from '../../shared/components/PageLayout'

const PostList = () => {
  const { forumId, threadId } = useParams()
  const { currentUser, userDetails } = useAuth()
  const navigate = useNavigate()
  const [posts, setPosts] = useState([])
  const [newPostContent, setNewPostContent] = useState('')
  const [thread, setThread] = useState(null)
  const [loading, setLoading] = useState(true)
  const [lastVisible, setLastVisible] = useState(null)
  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    const fetchThreadAndPosts = async () => {
      try {
        const fetchedThread = await threadRepository.getThreadById(forumId, threadId)
        setThread(fetchedThread)
        fetchInitialPosts()
      } catch (error) {
        console.error('Failed to fetch thread or posts', error)
      }
    }

    fetchThreadAndPosts()
  }, [forumId, threadId])

  const fetchInitialPosts = async () => {
    setLoading(true)
    const { posts, lastVisiblePost } = await postRepository.getPaginatedPosts(forumId, threadId)
    setPosts(posts)
    setLastVisible(lastVisiblePost)
    setHasMore(posts.length > 0)
    setLoading(false)
  }

  const fetchMorePosts = async () => {
    if (!lastVisible || !hasMore) return
    const { posts: newPosts, lastVisiblePost } = await postRepository.getPaginatedPosts(forumId, threadId, lastVisible)
    setPosts((prevPosts) => [...prevPosts, ...newPosts])
    setLastVisible(lastVisiblePost)
    setHasMore(newPosts.length > 0)
  }

  const handleAddPost = async () => {
    if (!newPostContent.trim()) return
    const post = {
      userId: currentUser.uid,
      content: newPostContent,
      displayName: userDetails.public.displayName || 'Anonymous', // Display name from the auth context or 'Anonymous'
      profilePhotoUrl: userDetails.public.profilePhotoUrl || '', // Profile photo URL from the auth context or empty string
    }
    try {
      const postId = await postRepository.addPost(forumId, threadId, post)
      const newPost = { ...post, id: postId, createdAt: new Date().toISOString() } // Mimic the real post object
      setPosts([...posts, newPost])
      setNewPostContent('')
    } catch (error) {
      console.error('Failed to add post', error)
    }
  }

  const canDeletePost = (post) => {
    return post.userId === currentUser.uid || (userDetails.secure.roles && userDetails.secure.roles.includes('admin'))
  }

  if (loading) return <div>Loading posts...</div>

  return (
    <PageLayout>
      {thread && (
        <div className='mb-4 p-4 bg-white shadow rounded-lg flex items-center'>
          <ProfilePhoto src={thread.profilePhotoUrl} displayName={thread.displayName} size='24' className='mr-4' />
          <div className='flex-grow'>
            <h1 className='text-2xl font-bold mb-1'>{thread.title}</h1>
            <p className='text-gray-700 mb-2'>{thread.content}</p>
            <div className='flex justify-between items-center'>
              <span className='text-sm font-medium'>
                <span className='text-gray-600'>Posted by: </span>
                <Link to={`/user/profile/${thread.userId}`} className='text-blue-600 hover:text-blue-800'>
                  {thread.displayName}
                </Link>
              </span>
            </div>
          </div>
        </div>
      )}
      <button onClick={() => navigate(`/user/forums/${forumId}`)} className='mb-4 mr-2 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600'>
        Back to Threads
      </button>
      <ul>
        {posts.map((post) => (
          <PostItem
            key={post.id}
            post={post}
            forumId={forumId}
            threadId={threadId}
            canDelete={canDeletePost(post)}
            onDelete={(postId) => {
              postRepository
                .deletePost(forumId, threadId, postId)
                .then(() => {
                  setPosts(posts.filter((p) => p.id !== postId)) // Remove post from state
                })
                .catch((error) => console.error('Failed to delete post', error))
            }}
            currentUserId={currentUser.uid}
          />
        ))}
      </ul>
      {hasMore && (
        <button onClick={fetchMorePosts} className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'>
          Load More
        </button>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleAddPost()
        }}
        className='mt-4'>
        <textarea
          value={newPostContent}
          onChange={(e) => setNewPostContent(e.target.value)}
          className='w-full p-2 border border-gray-300 rounded-md'
          placeholder='Write something...'
          required
        />
        <button type='submit' className='mt-2 px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded-md'>
          Add Post
        </button>
      </form>
    </PageLayout>
  )
}

export default PostList
