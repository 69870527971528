import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import ChatSidebar from './components/ChatSidebar'
import ChatRoom from './components/ChatRoom'

const ChatContainer = () => {
  const { chatId } = useParams() // Access the chatId from the URL
  const navigate = useNavigate()
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const selectRoom = (room) => {
    navigate(`/user/chat/${room.id}`) // Navigate to the selected room
  }

  const backToRooms = () => {
    navigate('/user/chat') // Navigate back to the main chat page
  }

  return (
    <div className='flex h-screen overflow-hidden'>
      {/* Sidebar, full-width on mobile when no room is selected */}
      {(!isMobile || !chatId) && (
        <div className={`bg-gray-800 text-white p-4 ${isMobile ? 'w-full' : 'w-64 flex-none'}`}>
          <ChatSidebar onSelectRoom={selectRoom} />
        </div>
      )}

      {/* Chat Room, shown when a room is selected (chatId exists) on mobile */}
      {chatId && (
        <div className='flex-grow'>
          <ChatRoom chatId={chatId} />
        </div>
      )}

      {/* Default Message when no chat room is selected */}
      {!chatId && !isMobile && (
        <div className='flex-grow flex items-center justify-center'>
          <div className='text-xl text-gray-600'>Select a chat room from the list.</div>
        </div>
      )}
    </div>
  )
}

export default ChatContainer
