import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import { Country, State } from 'country-state-city'
import { toast } from 'react-toastify'
import { Timestamp } from 'firebase/firestore'
import eventRepository from '../../../../repositories/firestore/events.firestore.repository'
import campgroundRepository from '../../../../repositories/firestore/campgrounds.firestore.repository'
import { uploadEventCoverImage, deleteEventImage } from '../../../../repositories/storage/events.storage.repository'
import PageLayout from '../../../shared/components/PageLayout'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // import the styles
import DOMPurify from 'dompurify'

const CampgroundAdminEventForm = () => {
  const navigate = useNavigate()
  const { campgroundId, eventId } = useParams()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm()

  const [coverImage, setCoverImage] = useState('')
  const [description, setDescription] = useState('')

  useEffect(() => {
    const today = new Date()
    const defaultStart = new Date(today.setHours(11, 0, 0)).toISOString().substring(0, 16)
    const defaultEnd = new Date(today.setHours(15, 0, 0)).toISOString().substring(0, 16)

    setValue('startDate', defaultStart)
    setValue('endDate', defaultEnd)

    if (eventId) {
      const fetchEventData = async () => {
        try {
          const data = await eventRepository.getEventById(eventId)
          if (data) {
            // Populate form fields
            Object.keys(data).forEach((key) => {
              if (key === 'description') {
                setDescription(data.description) // Set the description state
              } else if (typeof data[key] === 'object' && data[key] !== null && key !== 'startDate' && key !== 'endDate') {
                // For nested objects, handle them separately
                Object.keys(data[key]).forEach((subKey) => {
                  setValue(`${key}.${subKey}`, data[key][subKey])
                })
              } else if (key === 'startDate' || key === 'endDate') {
                // Convert seconds to ISO string for date input
                const dateIso = new Date(data[key].seconds * 1000).toISOString().substring(0, 16)
                setValue(key, dateIso)
              } else {
                setValue(key, data[key])
              }
            })
            setCoverImage(data.coverImage || '')
          }
        } catch (error) {
          console.error('Failed to fetch event data:', error)
        }
      }

      fetchEventData()
    }
  }, [eventId, setValue])

  const onSubmit = async (data) => {
    try {
      const { coverImageFile, ...formData } = data
      let coverImageUrl = ''

      // Sanitize the description
      const sanitizedDescription = DOMPurify.sanitize(description)

      // Convert date strings to Firestore timestamp format
      formData.startDate = convertDateToFirestoreTimestamp(formData.startDate)
      formData.endDate = convertDateToFirestoreTimestamp(formData.endDate)
      formData.description = sanitizedDescription // Use the sanitized description
      formData.campgroundId = campgroundId
      formData.type = 'Campground'

      let eventId = formData.id // Assume formData contains the event ID for existing events.

      // Step 1: If creating a new event, add it first to get an event ID.
      if (!eventId) {
        eventId = await eventRepository.addEvent(formData)
        toast.success('Event added successfully!')
      }

      // Step 2: Upload the new cover image if one has been selected.
      if (coverImageFile && coverImageFile.length > 0) {
        coverImageUrl = await uploadCoverImage(coverImageFile[0], eventId)
      } else if (!coverImage) {
        // If no cover image and no new file uploaded, use the campground's logo
        const campgroundData = await campgroundRepository.getCampgroundById(campgroundId)
        coverImageUrl = campgroundData.logo // Use the campground's logo as a fallback
      }

      // Step 3: Update the event with the new cover image URL or existing formData.
      // This step is necessary for both new events and editing existing events where the cover image might change.
      const updateData = coverImageUrl ? { ...formData, coverImage: coverImageUrl } : formData
      await eventRepository.updateEvent(eventId, updateData)
      toast.success('Event updated successfully!')

      // Cleanup and Navigation
      postSubmissionCleanup()
    } catch (error) {
      console.error('Form submission error:', error)
      toast.error('Failed to process the form.')
    }
  }

  const uploadCoverImage = async (file, eventId) => {
    try {
      const coverImageUrl = await uploadEventCoverImage(file, eventId) // Ensure this uploads and returns the URL
      return coverImageUrl
    } catch (error) {
      console.error('Error uploading cover image:', error)
      toast.error('Failed to upload cover image.')
      throw error
    }
  }

  const convertDateToFirestoreTimestamp = (dateString) => {
    const date = new Date(dateString)
    return Timestamp.fromDate(date)
  }

  const postSubmissionCleanup = () => {
    reset() // Reset form fields after submission
    navigate(`/campground-admin/${campgroundId}/events`) // Redirect to the events list
  }

  return (
    <PageLayout title={'Campground Event and Theme Entry Form'}>
      <div className='w-full mt-10'>
        <div>
          <div className='space-y-2'>
            <form onSubmit={handleSubmit(onSubmit)} className='space-y-4 bg-white p-4 rounded-lg shadow'>
              {/* Event Title */}
              <div>
                <label htmlFor='title' className='block text-sm font-medium text-gray-700'>
                  Event Title
                </label>
                <input
                  {...register('title', { required: true })}
                  type='text'
                  id='title'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                  placeholder='Enter event title'
                />
                {errors.title && <p className='text-red-500 text-xs italic'>Event title is required.</p>}
              </div>

              {/* Event Description */}
              <div>
                <label htmlFor='description' className='block text-sm font-medium text-gray-700'>
                  Description
                </label>
                <ReactQuill theme='snow' value={description} onChange={setDescription} />
                {/* <textarea
                  {...register('description', { required: true })}
                  id='description'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                  rows='4'
                  placeholder='Describe the event'></textarea> */}
                {errors.description && <p className='text-red-500 text-xs italic'>Description is required.</p>}
              </div>

              {/* Start and End Dates */}
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <div>
                  <label htmlFor='startDate' className='block text-sm font-medium text-gray-700'>
                    Start Date & Time
                  </label>
                  <input
                    {...register('startDate', { required: true })}
                    type='datetime-local'
                    id='startDate'
                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                  />
                  {errors.startDate && <p className='text-red-500 text-xs italic'>Start date and time are required.</p>}
                </div>
                <div>
                  <label htmlFor='endDate' className='block text-sm font-medium text-gray-700'>
                    End Date & Time
                  </label>
                  <input
                    {...register('endDate', { required: true })}
                    type='datetime-local'
                    id='endDate'
                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                  />
                  {errors.endDate && <p className='text-red-500 text-xs italic'>End date and time are required.</p>}
                </div>
              </div>
              <p className='mb-4'>Note: Times are not used right now. We suggest choosing dates and leaving the time portion alone.</p>

              {/* Cover Image Upload */}
              <div>
                <label htmlFor='coverImage' className='block text-sm font-medium text-gray-700'>
                  {coverImage ? 'Replace Cover Image' : 'Upload Cover Image (Optional, campground logo will be used if no photo uploaded)'}
                </label>
                <input
                  type='file'
                  id='coverImage'
                  {...register('coverImageFile')}
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                />
                {coverImage && (
                  <div className='mt-2'>
                    <img src={coverImage} alt='Cover Image' style={{ width: '100px', height: '100px' }} />
                  </div>
                )}
              </div>

              {/* Submit Button */}
              <button
                type='submit'
                className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                Save Event
              </button>
            </form>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default CampgroundAdminEventForm
