import React, { useEffect, useState } from 'react'
import blockRepository from '../../../repositories/firestore/block.firestore.repository'
import { useAuth } from '../../../contexts/AuthContext'
import ProfilePhoto from '../../shared/components/ProfilePhoto'

const BlockedUsers = () => {
  const { currentUser } = useAuth()
  const [blockedUsers, setBlockedUsers] = useState([])

  useEffect(() => {
    fetchBlockedUsers()
  }, [currentUser])

  const fetchBlockedUsers = async () => {
    if (currentUser) {
      const userBlocks = await blockRepository.getUserBlockList(currentUser.uid)
      //console.log('Fetched blocks:', userBlocks) // Check what is being fetched
      setBlockedUsers(userBlocks)
    }
  }

  const unblockUser = async (docId) => {
    const isConfirmed = window.confirm('Are you sure you want to unblock this user?')
    if (isConfirmed) {
      try {
        await blockRepository.removeBlockByDocId(docId)
        //console.log(`Unblocked user with document ID ${docId}, refetching list...`)
        fetchBlockedUsers() // Attempt to refresh the list
      } catch (error) {
        console.error(`Error unblocking user with document ID ${docId}:`, error)
      }
    }
  }

  const renderBlockedUsers = (users) => {
    //console.log('Rendering users:', users) // Check the final structure before rendering

    return (
      <div className='space-y-4'>
        {users && users.length > 0
          ? users.map((user, index) => (
              <div key={user.docId} className='bg-white rounded-lg shadow p-4 flex justify-between items-center'>
                <div className='flex items-center space-x-4'>
                  <ProfilePhoto src={user.profilePhotoUrl} displayName={user.displayName} size='10' className='mr-2' />
                  <h3 className='text-lg font-semibold'>{user.displayName}</h3>
                </div>
                <button
                  onClick={() => unblockUser(user.docId)}
                  className='bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 transition-colors duration-200'>
                  Unblock
                </button>
              </div>
            ))
          : 'No Blocked Users'}
      </div>
    )
  }

  return (
    <div className='mt-10'>
      <h2 className='text-2xl font-bold mb-4'>Blocked Users</h2>
      {renderBlockedUsers(blockedUsers)}
    </div>
  )
}

export default BlockedUsers
