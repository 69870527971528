import {
  FaBeer,
  FaUserSecret,
  FaDog,
  FaFireAlt,
  FaHome,
  FaStore,
  FaExpand,
  FaMars,
  FaVenus,
  FaGavel,
  FaDumbbell,
  FaBook,
  FaCaravan,
  FaCampground,
  FaFire,
  FaRecycle,
  FaPaw,
  FaCloud,
  FaDumpster,
  FaGamepad,
  FaFish,
  FaChild,
  FaTransgender,
  FaTransgenderAlt,
} from 'react-icons/fa'
import {
  MdDirectionsBike,
  MdDirectionsBoat,
  MdPool,
  MdLocalLaundryService,
  MdEvent,
  MdRestaurant,
  MdSecurity,
  MdWifi,
  MdWifiTethering,
  MdDelete,
  MdWater,
  MdKitchen,
  MdShower,
  MdOutdoorGrill,
  MdNightShelter,
  MdHotTub,
  MdLocalParking,
  MdHomeWork,
  MdHiking,
  MdEvStation,
  MdSportsBasketball,
  MdPower,
} from 'react-icons/md'

const amenitiesConfig = [
  { id: '21_plus', name: '21+ Only', icon: FaBeer },
  { id: '18_plus', name: '18+ Only', icon: FaUserSecret },
  { id: 'all_ages_welcome', name: 'All Ages Welcome', icon: FaGavel },
  { id: 'biking_trail', name: 'Biking Trail', icon: MdDirectionsBike },
  { id: 'boating_access', name: 'Boating Access', icon: MdDirectionsBoat },
  { id: 'book_movie_library', name: 'Book/Movie Library', icon: FaBook },
  { id: 'cabins_available', name: 'Cabins Available', icon: FaHome },
  { id: 'campground_store', name: 'Campground General Store', icon: FaStore },
  { id: 'clothing_optional_most', name: 'Clothing Optional (Most Areas)', icon: MdOutdoorGrill },
  { id: 'clothing_optional_partial', name: 'Clothing Optional (Partial)', icon: MdNightShelter },
  { id: 'common_area', name: 'Common Outdoor Area', icon: MdEvent },
  { id: 'communal_showers', name: 'Communal Showers', icon: MdShower },
  { id: 'community_campfire', name: 'Community Campfire', icon: FaFireAlt },
  { id: 'dish_washing_area', name: 'Dish Washing Area', icon: MdKitchen },
  { id: 'dog_park', name: 'Dog Park', icon: FaDog },
  { id: 'dry_sauna', name: 'Dry Sauna', icon: MdHotTub },
  { id: 'dump_station', name: 'Dump Station', icon: FaDumpster },
  { id: 'ev_charging_station', name: 'EV Charging Station', icon: MdEvStation },
  { id: 'event_space', name: 'Event Space', icon: MdEvent },
  { id: 'fires_allowed', name: 'Fires Allowed', icon: FaFire },
  { id: 'fishing_access', name: 'Fishing Access', icon: FaFish },
  { id: 'full_hookups', name: 'Full Hookups', icon: MdPower },
  { id: 'game_room', name: 'Game Room', icon: FaGamepad },
  { id: 'gym', name: 'Gym', icon: FaDumbbell },
  { id: 'hiking_trail', name: 'Hiking Trail', icon: MdHiking },
  { id: 'hot_tub', name: 'Hot Tub', icon: MdHotTub },
  { id: 'laundry_facilities', name: 'Laundry Facilities', icon: MdLocalLaundryService },
  { id: 'large_campsites', name: 'Large Campsites Available', icon: FaExpand },
  { id: 'men_only', name: 'Men Only', icon: FaMars },
  { id: 'parking_site', name: 'Parking at Site', icon: MdLocalParking },
  { id: 'permanent_sites', name: 'Permanent Sites', icon: MdHomeWork },
  { id: 'pet_friendly', name: 'Pet Friendly Accommodations', icon: FaPaw },
  { id: 'play_area', name: 'Play Area', icon: FaChild },
  { id: 'pool', name: 'Pool', icon: MdPool },
  { id: 'pull_through_sites', name: 'Pull Through Sites', icon: FaCaravan },
  { id: 'quiet_hours', name: 'Quiet Hours', icon: MdNightShelter },
  { id: 'recycling_available', name: 'Recycling Available', icon: FaRecycle },
  { id: 'restaurant_cafe', name: 'Restaurant/Cafe', icon: MdRestaurant },
  { id: 'security_on_site', name: 'Security on Site', icon: MdSecurity },
  { id: 'shared_kitchen', name: 'Shared Kitchen', icon: MdKitchen },
  { id: 'sports_courts', name: 'Sports Courts', icon: MdSportsBasketball },
  { id: 'steam_room', name: 'Steam Room', icon: FaCloud },
  { id: 'tent_sites', name: 'Tent Sites', icon: FaCampground },
  { id: 'trans_men_welcome', name: 'Trans Men Welcome', icon: FaTransgender },
  { id: 'trans_women_welcome', name: 'Trans Women Welcome', icon: FaTransgenderAlt },
  { id: 'trash_disposal', name: 'Trash Disposal', icon: MdDelete },
  { id: 'water_access', name: 'Water Access', icon: MdWater },
  { id: 'weekend_events', name: 'Weekend Events', icon: MdEvent },
  { id: 'wifi_common_areas', name: 'Wifi in Common Areas', icon: MdWifi },
  { id: 'wifi_campground', name: 'Wifi Throughout Campground', icon: MdWifiTethering },
  { id: 'women_only', name: 'Women Only', icon: FaVenus },
]

export default amenitiesConfig
