import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'
import { initializeApp, getApps } from 'firebase/app'
import { firebaseConfig } from '../../services/firebase/config'

// Initialize Firebase app and storage only if it hasn't been initialized
if (!getApps().length) {
  initializeApp(firebaseConfig)
}

const storage = getStorage()

// Function to upload a file to a specified path in Firebase Storage
const uploadFile = async (filePath, file) => {
  const storageRef = ref(storage, filePath)
  await uploadBytes(storageRef, file)
  return getDownloadURL(storageRef)
}

// Utility function to generate a storage reference path for files
const generateFilePath = (directory, fileName) => {
  const timestamp = new Date().getTime()
  return `${directory}/${fileName}-${timestamp}`
}

export const deleteFile = async (filePath) => {
  const fileRef = ref(storage, filePath)
  try {
    await deleteObject(fileRef)
  } catch (error) {
    console.error(`Error deleting file: ${filePath}`, error)
    throw error
  }
}

const storageRepository = {
  uploadFile,
  generateFilePath,
  deleteFile,
}

export default storageRepository
