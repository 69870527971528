import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import userRepository from '../../../../repositories/firestore/users.firestore.repository'
import campgroundRepository from '../../../../repositories/firestore/campgrounds.firestore.repository'
import PageLayout from '../../../shared/components/PageLayout'
import { toast } from 'react-toastify'

const ManageMember = () => {
  const { userId } = useParams()
  const { register, handleSubmit, watch, setValue } = useForm()
  const [userData, setUserData] = useState({ public: {}, secure: {} })
  const [campgrounds, setCampgrounds] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const data = await userRepository.getUserDataById(userId)
        const camps = await campgroundRepository.getAllCampgrounds()
        setUserData(data)
        setCampgrounds(camps)
        setValue('role', data.secure.roles ? data.secure.roles[0] : '')
        setValue('campgroundId', data.secure.campgroundId || '')
      } catch (err) {
        setError(err.message)
        console.error('Failed to fetch user data:', err)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [userId, setValue])

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await userRepository.assignUserRole(userId, [data.role], data.role === 'campgroundAdmin' ? data.campgroundId : null)
      toast.success('User role updated successfully!')
    } catch (error) {
      console.error('Error updating user role:', error)
      toast.error('Failed to update user role.')
    } finally {
      setLoading(false)
    }
  }

  if (loading) return <PageLayout title='Loading...'>Loading...</PageLayout>
  if (error) return <PageLayout title='Error'>{error}</PageLayout>

  return (
    <PageLayout title='Manage Member'>
      <div className='space-y-8'>
        <h2 className='text-2xl font-bold'>User Public Information</h2>
        <p>Display Name: {userData.public.displayName}</p>
        <h2 className='text-2xl font-bold mt-4'>User Secure Information</h2>
        <p>Email: {userData.secure.email}</p>
        <p>Roles: {userData.secure.roles && userData.secure.roles.join(', ')}</p>
        <p>Campground ID: {userData.secure.campgroundId}</p>

        <form onSubmit={handleSubmit(onSubmit)} className='space-y-4 bg-white p-4 rounded-lg shadow'>
          <div>
            <label className='block text-sm font-medium text-gray-700'>Role:</label>
            <select
              {...register('role')}
              className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'>
              <option value=''>Select a role</option>
              <option value='user'>No Role/User</option>
              <option value='campgroundAdmin'>Campground Admin</option>
              <option value='admin'>Admin</option>
            </select>
          </div>
          {watch('role') === 'campgroundAdmin' && (
            <div>
              <label className='block text-sm font-medium text-gray-700'>Campground:</label>
              <select
                {...register('campgroundId', { required: watch('role') === 'campgroundAdmin' })}
                className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'>
                <option value=''>Select a campground</option>
                {campgrounds.map((camp) => (
                  <option key={camp.id} value={camp.id}>
                    {camp.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <button
            type='submit'
            className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
            Update Role
          </button>
        </form>
      </div>
    </PageLayout>
  )
}

export default ManageMember
