import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import eventsRepository from '../../../../repositories/firestore/events.firestore.repository'
import EventList from '../../home/components/EventList'
import PageLayout from '../../../shared/components/PageLayout'

const EventDetails = (params) => {
  let { id } = useParams()

  const [event, setEvent] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const fetchData = async () => {
    // Example: Scroll to the top when the component mounts

    try {
      const data = await eventsRepository.getEventById(id)

      if (data) {
        setEvent(data)
        setLoading(false)
      } else {
        throw new Error('The requested event could not be found.')
      }
    } catch (error) {
      setError(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    scrollToTop()
    fetchData()
  }, [])

  useEffect(() => {
    scrollToTop()
    fetchData()
  }, [id])

  if (loading) {
    return <div>Loading Event...</div>
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <>
      <PageLayout
        metaTitle={`${event.title} - ${event.startDate.toDate().toLocaleDateString('en-US')}-${event.endDate.toDate().toLocaleDateString('en-US')}`}
        metaDescription={event.description}
        metaImage={event.coverImage}>
        {event && (
          <div className='max-w-7xl flex flex-col space-y-4'>
            <div className='flex flex-col px-4'>
              <div className='hover:underline text-blue-500 mb-4'>
                <Link to='/events'>Event Home</Link>
              </div>
              <div className='flex flex-col-reverse md:flex-row md:justify-between items-center'>
                <div className='flex flex-col items-center md:items-start'>
                  <h2 className='text-3xl font-bold mb-4'>{event.title}</h2>
                  <div className='mb-2 text-xl text-gray-600'>
                    {event.venue} | {event.startDate.toDate().toLocaleDateString('en-US')}-{event.endDate.toDate().toLocaleDateString('en-US')}
                  </div>
                </div>
              </div>
            </div>
            <div className='container mx-auto'>
              <div className='flex flex-col md:flex-row'>
                <div className='flex flex-col w-full md:w-2/3'>
                  <div className='mx-auto w-full py-6 px-4 sm:px-6 lg:px-8 bg-white rounded-lg shadow-md mb-4'>
                    <div className='w-full flex justify-center'>
                      <img src={event.coverImage} alt={event.title} className='mb-6 rounded-lg shadow-lg' />
                    </div>
                    <div className='prose lg:prose-xl mb-8 flex flex-col space-y-4' dangerouslySetInnerHTML={{ __html: event.description }}></div>
                    <div className='hover:underline text-blue-500 mb-4'>
                      <Link to={event.externalLink} target='_blank'>
                        Click Here to Learn More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col w-full md:w-1/3 md:pl-4'>
                  <h3 className='text-lg font-semibold mb-2'>Events Coming Soon</h3>
                  <EventList count={3} vertical={true} />
                </div>
              </div>
            </div>
          </div>
        )}
      </PageLayout>
    </>
  )
}

export default EventDetails
