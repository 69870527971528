import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import threadRepository from '../../../repositories/firestore/thread.firestore.repository'
import forumRepository from '../../../repositories/firestore/forum.firestore.repository'
import ThreadItem from './ThreadItem'
import { useAuth } from '../../../contexts/AuthContext'
import PageLayout from '../../shared/components/PageLayout'

const ThreadList = () => {
  const { forumId } = useParams()
  const { currentUser, userDetails } = useAuth()
  const navigate = useNavigate()
  const [threads, setThreads] = useState([])
  const [forum, setForum] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)
  const [lastVisible, setLastVisible] = useState(null)
  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    const fetchForumDetails = async () => {
      try {
        const fetchedForum = await forumRepository.getForumById(forumId)
        setForum(fetchedForum)
      } catch (error) {
        console.error('Failed to fetch forum details:', error)
      }
    }

    fetchForumDetails()
    fetchInitialThreads()
  }, [forumId])

  const fetchInitialThreads = async () => {
    setLoading(true)
    const { threads, lastVisibleThread } = await threadRepository.getPaginatedThreads(forumId)
    setThreads(threads)
    setLastVisible(lastVisibleThread)
    setHasMore(threads.length > 0)
    setLoading(false)
  }

  const fetchMoreThreads = async () => {
    if (!lastVisible || !hasMore) return
    setLoadingMore(true)
    const { threads: newThreads, lastVisibleThread } = await threadRepository.getPaginatedThreads(forumId, lastVisible)
    setThreads((prevThreads) => [...prevThreads, ...newThreads])
    setLastVisible(lastVisibleThread)
    setHasMore(newThreads.length > 0)
    setLoadingMore(false)
  }

  const handleThreadDeleted = (threadId) => {
    setThreads((currentThreads) => currentThreads.filter((thread) => thread.id !== threadId))
  }

  const canDeleteThread = (thread) => {
    return thread.userId === currentUser.uid || (userDetails.secure.roles && userDetails.secure.roles.includes('admin'))
  }

  if (loading) return <div>Loading...</div>

  return (
    <PageLayout>
      {forum && (
        <div className='mb-4 p-4 bg-white shadow rounded-lg flex justify-between items-center'>
          <div className='flex-grow'>
            <h1 className='text-2xl font-bold mb-1'>{forum.name}</h1>
            <p className='text-gray-700'>{forum.description}</p>
          </div>
          {userDetails.secure.roles && userDetails.secure.roles.includes('admin') && (
            <button
              onClick={() => navigate(`/user/forums/${forumId}/edit`)}
              className='ml-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'>
              Edit Forum
            </button>
          )}
        </div>
      )}
      <button onClick={() => navigate('/user/forums')} className='mb-4 mr-2 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600'>
        Back to Forums
      </button>
      <button
        onClick={() => navigate(`/user/forums/${forumId}/add-thread`)}
        className='mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'>
        Add Thread
      </button>
      <ul>
        {threads.map((thread) => (
          <ThreadItem key={thread.id} thread={thread} forumId={forumId} onThreadDeleted={handleThreadDeleted} canDelete={canDeleteThread(thread)} />
        ))}
      </ul>
      {hasMore && !loadingMore && (
        <button onClick={fetchMoreThreads} className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'>
          Load More
        </button>
      )}
      {loadingMore && <div>Loading more threads...</div>}
    </PageLayout>
  )
}

export default ThreadList
