import { collection, query, where, getDocs, addDoc, deleteDoc, doc, getDoc } from 'firebase/firestore'
import baseRepository from './base.firestore.repository'
import userRepository from './users.firestore.repository'

const blocksCollectionRef = collection(baseRepository.getDb(), 'blocks')

let blockCache = {
  byBlockerId: {}, // Users blocked by the blocker
  byBlockedId: {}, // Users who have blocked the blocker
  cacheDuration: 1000 * 60 * 5, // 5 minutes
}

const loadBlocks = async (userId) => {
  const now = Date.now()
  const refreshBlocker = !blockCache.byBlockerId[userId] || now - blockCache.byBlockerId[userId].lastFetch > blockCache.cacheDuration
  const refreshBlocked = !blockCache.byBlockedId[userId] || now - blockCache.byBlockedId[userId].lastFetch > blockCache.cacheDuration

  let blockedIds = new Set()
  let blockerIds = new Set()

  if (refreshBlocker || refreshBlocked) {
    try {
      const blockerQuery = query(blocksCollectionRef, where('blockerId', '==', userId))
      const blockedQuery = query(blocksCollectionRef, where('blockedId', '==', userId))
      const [blockerSnapshot, blockedSnapshot] = await Promise.all([getDocs(blockerQuery), getDocs(blockedQuery)])

      if (refreshBlocker) {
        blockerSnapshot.forEach((doc) => blockedIds.add(doc.data().blockedId))
      }
      if (refreshBlocked) {
        blockedSnapshot.forEach((doc) => blockerIds.add(doc.data().blockerId))
      }

      // Update the cache
      blockCache.byBlockerId[userId] = { data: blockedIds, lastFetch: now }
      blockCache.byBlockedId[userId] = { data: blockerIds, lastFetch: now }
    } catch (error) {
      console.error('Error loading block information:', error)
    }
  } else {
    // Use the cached data if available
    blockedIds = blockCache.byBlockerId[userId].data
    blockerIds = blockCache.byBlockedId[userId].data
  }

  return { blockedIds, blockerIds }
}

const addBlock = async (blockerId, blockedId) => {
  try {
    // Check if the block already exists
    const existingBlockQuery = query(blocksCollectionRef, where('blockerId', '==', blockerId), where('blockedId', '==', blockedId))
    const querySnapshot = await getDocs(existingBlockQuery)

    if (!querySnapshot.empty) {
      console.log('Block already exists.') // Optionally, handle this case in the UI
      return // Exit function if the block already exists
    }

    const blockedUser = await userRepository.getUserPublicDataById(blockedId)
    const blockData = {
      blockerId,
      blockedId,
      blockedDisplayName: blockedUser.displayName,
      blockedProfilePhotoUrl: blockedUser.profilePhotoUrl || null,
    }

    // Add the block to Firestore
    await addDoc(blocksCollectionRef, blockData)

    // Invalidate the cache
    blockCache.byBlockerId[blockerId] = null
  } catch (error) {
    console.error('Error adding block:', error)
  }
}

const removeBlockByDocId = async (docId) => {
  try {
    const blockDocRef = doc(blocksCollectionRef, docId)
    const blockDoc = await getDoc(blockDocRef)
    if (!blockDoc.exists()) {
      throw new Error('Block document does not exist.')
    }
    const blockerId = blockDoc.data().blockerId

    // Proceed with deletion
    await deleteDoc(blockDocRef)

    // Invalidate the cache
    blockCache.byBlockerId[blockerId] = null

    console.log(`Block removed successfully, cache invalidated.`)
  } catch (error) {
    console.error('Error removing block:', error)
    throw error // Re-throw the error to be handled or logged in the calling function
  }
}

const getUserBlockList = async (userId) => {
  const now = Date.now()
  // Check if cache is available and fresh
  if (!blockCache.byBlockerId[userId] || now - blockCache.byBlockerId[userId].lastFetch > blockCache.cacheDuration) {
    try {
      const blockerQuery = query(blocksCollectionRef, where('blockerId', '==', userId))
      const blockerSnapshot = await getDocs(blockerQuery)

      // Include the document ID in the data structure
      const blockedUsers = blockerSnapshot.docs.map((doc) => ({
        docId: doc.id, // This is the Firestore document ID of the block record
        userId: doc.data().blockedId,
        displayName: doc.data().blockedDisplayName,
        profilePhotoUrl: doc.data().blockedProfilePhotoUrl,
      }))

      // Update the cache with the new list including document IDs
      blockCache.byBlockerId[userId] = { data: blockedUsers, lastFetch: now }
    } catch (error) {
      console.error('Error fetching block information:', error)
      return [] // Return an empty array in case of error
    }
  }
  return blockCache.byBlockerId[userId].data // Return cached data
}

const blockRepository = {
  loadBlocks,
  addBlock,
  removeBlockByDocId,
  getUserBlockList,
}

export default blockRepository
