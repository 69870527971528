import React from 'react'
import { Link } from 'react-router-dom'

const DashboardTile = ({ link, icon: Icon, title, enabled = true }) => {
  // Define common base classes for all states
  const baseClasses = 'bg-white rounded-lg shadow transition p-6'

  // Define specific styles for enabled and disabled states
  const tileClasses = enabled ? `${baseClasses} hover:shadow-lg` : `${baseClasses} cursor-not-allowed text-gray-500`

  // Content classes specifically arranged for mobile first
  const contentClasses = 'flex items-center space-x-4 md:flex-col md:items-center md:space-x-0 md:space-y-4'

  // Handling hover effects and link decorations
  const linkClasses = enabled ? 'hover:text-gray-900' : ''

  return (
    <div className={tileClasses}>
      {enabled ? (
        <Link to={link} className={`${contentClasses} ${linkClasses}`}>
          <Icon className='text-4xl' /> {/* Icon size */}
          <h3 className='text-lg font-semibold'>{title}</h3>
        </Link>
      ) : (
        <div className={`${contentClasses}`}>
          <Icon className='text-4xl' />
          <h3 className='text-lg font-semibold'>{title}</h3>
        </div>
      )}
    </div>
  )
}

export default DashboardTile
