import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'
import { getApp } from 'firebase/app'
import { useNavigate } from 'react-router-dom'
import userRepository from '../../../../repositories/firestore/users.firestore.repository'
import { toast } from 'react-toastify'
import PageLayout from '../../../shared/components/PageLayout'

const LoginForm = () => {
  const navigate = useNavigate()

  const initialValues = {
    email: '',
    password: '',
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
  })

  const handleSubmit = async (values, { setSubmitting }) => {
    const { email, password } = values
    const app = getApp()
    const auth = getAuth(app)

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password)
      console.log('User logged in successfully')

      // Fetching the complete user data, which includes public and secure profiles
      const userData = await userRepository.getUserDataById(userCredential.user.uid)

      // Check and update login points based on the last login date
      if (userData && userData.public) {
        const today = new Date().toISOString().split('T')[0] // Current date in YYYY-MM-DD
        const lastLoginDate = userData.public.lastLoginDate ? new Date(userData.public.lastLoginDate).toISOString().split('T')[0] : ''

        if (today !== lastLoginDate) {
          const newPoints = (userData.public.points?.loginPoints || 0) + 1

          await userRepository.updateUserPointsById(userCredential.user.uid, 'loginPoints', 1)

          // Updating the public profile with new points and the current login date
          await userRepository.updatePublicUserData(userCredential.user.uid, {
            lastLoginDate: new Date().toISOString(), // Update to the current date and time in ISO format
          })

          console.log('Login points updated successfully.')
        }
      }

      // Role-based navigation using secure data
      if (userData.secure && userData.secure.roles) {
        if (userData.secure.roles.includes('admin')) {
          navigate('/admin')
        } else {
          navigate('/user/dashboard')
        }
      } else {
        navigate('/')
      }
    } catch (error) {
      toast.error(error.message)
      console.error('Error during login:', error.message)
    }

    setSubmitting(false)
  }

  const handleForgotPassword = async (email) => {
    const app = getApp()
    const auth = getAuth(app)

    try {
      await sendPasswordResetEmail(auth, email)
      toast.success('Password reset email sent. Check your inbox.')
    } catch (error) {
      toast.error('Failed to send password reset email. ' + error.message)
      console.error('Error sending password reset email:', error)
    }
  }

  return (
    <PageLayout
      showAds={false}
      metaTitle={'Login to Gay Camping Friends'}
      metaDescription={'Login to Gay Camping Friends to see exclusive, members only content.'}>
      <div className='flex flex-col items-center justify-start min-h-screen pt-12 px-4 sm:pt-20'>
        <div className='w-full max-w-md bg-white rounded-lg shadow-md p-6 space-y-4'>
          <h2 className='text-2xl font-bold mb-4 text-center'>Login to Your Account</h2>
          <p className='text-sm text-gray-600 text-center'>Enter your credentials to access your account.</p>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ isSubmitting, values }) => (
              <Form className='space-y-4'>
                {/* Daily Points Notification */}
                <div className='p-4 bg-blue-100 border border-blue-200 rounded text-blue-800'>
                  <h3 className='font-semibold'>Earn a GCF Point Every Day!</h3>
                  <p className='text-sm'>Login daily to receive a GCF Point.</p>
                </div>

                <div>
                  <label htmlFor='email' className='block text-sm font-medium text-gray-700'>
                    Your Email
                  </label>
                  <Field
                    type='email'
                    name='email'
                    id='email'
                    placeholder='name@company.com'
                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                  />
                  <ErrorMessage name='email' component='div' className='text-red-500' />
                </div>

                <div>
                  <label htmlFor='password' className='block text-sm font-medium text-gray-700'>
                    Password
                  </label>
                  <Field
                    type='password'
                    name='password'
                    id='password'
                    placeholder='••••••••'
                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                  />
                  <ErrorMessage name='password' component='div' className='text-red-500' />
                </div>

                <button
                  type='submit'
                  disabled={isSubmitting}
                  className='w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                  {isSubmitting ? 'Logging in...' : 'Login'}
                </button>

                <button
                  type='button'
                  onClick={() => handleForgotPassword(values.email)}
                  disabled={!values.email || isSubmitting}
                  className='mt-2 text-sm text-blue-600 hover:text-blue-800'>
                  Forgot Password?
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </PageLayout>
  )
}

export default LoginForm
