import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom' // Import useNavigate for programmatic navigation
import chatRoomRepository from '../../../../repositories/firestore/chatRoom.firestore.repository'
import { FaChevronDown, FaChevronRight } from 'react-icons/fa' // Importing icons from React Icons

const ChatSidebar = () => {
  const navigate = useNavigate() // Hook for navigation
  const [categories, setCategories] = useState({})
  const [expandedCategories, setExpandedCategories] = useState({})
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    const fetchChatRooms = async () => {
      try {
        const rooms = await chatRoomRepository.getChatRooms()
        organizeChatRooms(rooms)
      } catch (error) {
        console.error('Failed to fetch chat rooms:', error)
      }
    }

    fetchChatRooms()
  }, [])

  const organizeChatRooms = (rooms) => {
    const sortedCategories = {}
    rooms.forEach((room) => {
      const category = room.category || 'General'
      const modeSuffix = room.mode === 'Dirty' ? ' (NSFW)' : '' // Differentiate subcategories
      const categoryWithMode = `${category}${modeSuffix}`

      if (!sortedCategories[categoryWithMode]) {
        sortedCategories[categoryWithMode] = []
      }
      sortedCategories[categoryWithMode].push(room)
    })

    setCategories(sortedCategories)
    setExpandedCategories(Object.keys(sortedCategories).reduce((acc, key) => ({ ...acc, [key]: false }), {}))
  }

  useEffect(() => {
    // Automatically expand or collapse all categories based on the search term
    setExpandedCategories((prev) => Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: searchTerm !== '' }), {}))
  }, [searchTerm])

  const toggleCategory = (category) => {
    setExpandedCategories((prev) => ({ ...prev, [category]: !prev[category] }))
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase())
  }

  const filterChatRooms = (rooms) => {
    if (!searchTerm) return rooms
    return rooms.filter((room) => room.name.toLowerCase().includes(searchTerm))
  }

  const handleSelectRoom = (room) => {
    navigate(`/user/chat/${room.id}`) // Navigate to the chat room route
  }

  return (
    <div className='bg-gray-800 p-1 text-gray-200 flex flex-col h-full'>
      <div className='p-2'>
        <h1 className='text-xl font-bold mb-3'>Chat Rooms</h1>
        <input
          type='text'
          className='w-full p-2 mb-3 rounded bg-gray-700 text-white'
          placeholder='Search chat rooms...'
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <div className='overflow-y-auto flex-grow'>
        {Object.keys(categories)
          .sort()
          .map((category) => (
            <div key={category}>
              <h3 onClick={() => toggleCategory(category)} className='font-bold cursor-pointer hover:text-gray-300 flex items-center mt-2'>
                {expandedCategories[category] ? <FaChevronDown className='mr-2' /> : <FaChevronRight className='mr-2' />}
                {category}
              </h3>
              {expandedCategories[category] && (
                <ul className='list-none p-0 ml-6'>
                  {filterChatRooms(categories[category])
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((room) => (
                      <li key={room.id} className='text-sm cursor-pointer mt-2 hover:bg-gray-700' onClick={() => handleSelectRoom(room)}>
                        {room.name}
                      </li>
                    ))}
                </ul>
              )}
            </div>
          ))}
      </div>
    </div>
  )
}

export default ChatSidebar
