import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import Breadcrumbs from './components/Breadcrumbs'
import campgroundsFirestoreRepository from '../../../../repositories/firestore/campgrounds.firestore.repository'
import categoriesRepository from '../../../../repositories/categories.repository'
import LocationMap from './components/LocationMap'
import ImageGallery from './components/ImageGallery'
import { useAuth } from '../../../../contexts/AuthContext'
import PageLayout from '../../../shared/components/PageLayout'
import CampgroundTripSummary from './components/CampgroundTripSummary'
import EventList from './components/EventList'
import amenitiesConfig from '../../../../config/amenitiesConfig'

function Campground(params) {
  const { currentUser, userDetails } = useAuth()
  let { slug } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const [activeTab, setActiveTab] = useState('details') // Default tab is now set based on URL

  const [firestoreCampground, setFirestoreCampground] = useState(null)
  const [breadcrumb, setBreadcrumb] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [reviews, setReviews] = useState([])
  const [categoryName, setCategoryName] = useState([])
  const [reviewForm, setReviewForm] = useState({
    cleanliness: '',
    friendliness: '',
    atmosphere: '',
    amenities: '',
    cruisiness: '',
    comments: '',
  })

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const tab = searchParams.get('tab')
    setActiveTab(tab || 'details')
  }, [location.search])

  const changeTab = (newTab) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('tab', newTab)
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true })
    setActiveTab(newTab)
  }

  const fetchReviews = async () => {
    try {
      const fetchedReviews = await campgroundsFirestoreRepository.getReviewsByCampgroundSlug(slug)
      setReviews(fetchedReviews)
    } catch (error) {
      console.error('Error fetching reviews:', error)
    }
  }

  useEffect(() => {
    if (currentUser) {
      fetchReviews()
    }
  }, [slug, currentUser]) // Re-fetch when slug or currentUser changes

  useEffect(() => {
    const fetchData = async () => {
      try {
        const firestoreData = await campgroundsFirestoreRepository.getCampgroundBySlug(slug)

        if (firestoreData) {
          // Convert latitude and longitude to numbers
          const convertedData = {
            ...firestoreData,
            latitude: Number(firestoreData.latitude),
            longitude: Number(firestoreData.longitude),
          }

          setFirestoreCampground(convertedData)
          setLoading(false)
        } else {
          throw new Error('The requested campground could not be found.')
        }
      } catch (error) {
        console.error('Error fetching campground data:', error)
        setError(error)
        setLoading(false)
      }
    }

    fetchData()
  }, [slug]) // Added `slug` as a dependency to re-fetch if it changes

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await categoriesRepository.GetCategoryById(firestoreCampground.category)
        setCategoryName(data.name)

        if (data) {
          const breadcrumbData = [
            { label: 'Home', path: '/' },
            { label: data.name, path: `/campground-category/${data.slug}` },
            { label: firestoreCampground.name, path: `/campground/${firestoreCampground.slug}` },
          ]

          setBreadcrumb(breadcrumbData)
          setLoading(false)
        } else {
          throw new Error('The requested campground could not be found.')
        }
      } catch (error) {
        setError(error)
        setLoading(false)
      }
    }

    if (firestoreCampground) fetchData()
  }, [firestoreCampground])

  if (loading) {
    return <div>Loading Campground...</div>
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  const handleReviewSubmit = async (e) => {
    e.preventDefault()
    // Replace this with your actual function to submit a review
    // Assuming you have access to currentUser and userDetails here

    const userMetadata = {
      userId: currentUser.uid, // Or however you access the current user's ID
      userDisplayName: userDetails.public.displayName, // Adjust based on how you store/retrieve this
    }

    // Enhance reviewForm with user metadata and current date
    const reviewDataWithUser = {
      ...reviewForm,
      ...userMetadata,
      datePosted: new Date().toISOString(), // Adding submission date
    }

    try {
      const campground = await campgroundsFirestoreRepository.getCampgroundBySlug(slug)
      if (!campground) throw new Error('Campground not found')

      await campgroundsFirestoreRepository.addReviewToCampground(campground.id, reviewDataWithUser)

      setReviewForm({
        cleanliness: '',
        friendliness: '',
        atmosphere: '',
        amenities: '',
        comments: '',
      })

      fetchReviews()
    } catch (error) {
      console.error('Error submitting review:', error)
    }
  }

  return (
    <>
      <PageLayout
        metaImage={firestoreCampground.logo}
        metaDescription={firestoreCampground.description}
        metaTitle={`${firestoreCampground.name}, ${categoryName} in ${firestoreCampground.address.city}, ${firestoreCampground.address.state} ${firestoreCampground.address.country}`}>
        {firestoreCampground && (
          <div className='max-w-7xl mx-auto'>
            <div className='flex flex-col'>
              {breadcrumb && <Breadcrumbs breadcrumbs={breadcrumb} />}
              <div className='flex flex-col-reverse md:flex-row md:justify-between items-center'>
                <div className='flex flex-col items-center md:items-start'>
                  <h2 className='text-2xl font-bold mb-4'>{firestoreCampground.name}</h2>
                  <div className='mb-2 text-xl text-gray-600'>{firestoreCampground.tagline}</div>
                </div>
                <img className='mb-2 md:mb-0 w-64 md:h-32 object-contain' src={firestoreCampground.logo} alt={`${firestoreCampground.name} Logo`} />
              </div>
            </div>
            <div className='mb-2 flex gap-2 overflow-x-auto whitespace-nowrap'>
              {['details', 'events', 'chat', 'reviews'].map((tab) => (
                <button
                  key={tab}
                  onClick={() => changeTab(tab)}
                  className={`py-2 px-4 text-sm md:text-base ${activeTab === tab ? 'text-blue-700 border-b-2 border-blue-700' : 'text-gray-500'}`}>
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>
            <div>
              {activeTab === 'details' && (
                <div className='container mx-auto'>
                  <div className='flex flex-col md:flex-row'>
                    <div className='flex flex-col w-full md:w-2/3'>
                      <div className='mx-auto w-full py-6 px-4 sm:px-6 lg:px-8 bg-white rounded-lg shadow-md mb-4'>
                        <p>{firestoreCampground.description}</p>
                      </div>
                      <div className='mx-auto w-full py-6 px-4 sm:px-6 lg:px-8 bg-white rounded-lg shadow-md flex flex-col space-y-4  mb-4'>
                        <h3 className='text-lg font-semibold mb-2'>Additional Info</h3>
                        <h4 className='text-md font-semibold mb-2'>Amenities</h4>
                        <ul className='grid grid-cols-2 md:grid-cols-3 gap-4 p-0'>
                          {firestoreCampground.amenities &&
                            firestoreCampground.amenities.map((amenityId) => {
                              const amenity = amenitiesConfig.find((a) => a.id === amenityId)
                              return amenity ? (
                                <li key={amenityId} className='flex items-center'>
                                  <amenity.icon className='text-lg mr-2' />
                                  {amenity.name}
                                </li>
                              ) : null
                            })}
                        </ul>
                        <h4 className='text-md font-semibold mb-2 mt-4'>What to Bring</h4>
                        <p>{firestoreCampground.whatToBring}</p>
                        <h4 className='text-md font-semibold mb-2 mt-4'>Nearby</h4>
                        <p>{firestoreCampground.nearby}</p>
                      </div>
                      {currentUser && <CampgroundTripSummary campgroundId={firestoreCampground.id} />}
                    </div>
                    <div className='flex flex-col w-full md:w-1/3 md:pl-4'>
                      <div className='mx-auto w-full py-6 px-4 sm:px-6 lg:px-8 bg-white rounded-lg shadow-md  mb-4'>
                        <h3 className='text-lg font-semibold mb-2'>Location</h3>
                        <LocationMap lat={firestoreCampground.latitude} lng={firestoreCampground.longitude} title={firestoreCampground.name} />
                        <p>
                          Address:{' '}
                          {`${firestoreCampground.address.address1}${
                            firestoreCampground.address.address2 ? ', ' + firestoreCampground.address.address2 : ''
                          }, ${firestoreCampground.address.city}, ${firestoreCampground.address.state} ${firestoreCampground.address.zip}, ${
                            firestoreCampground.address.country
                          }`}
                        </p>

                        <a href={firestoreCampground.website} target='_blank' rel='noopener noreferrer' className='text-blue-500 hover:underline'>
                          Visit Website
                        </a>
                      </div>
                      <div className='mx-auto w-full py-6 px-4 sm:px-6 lg:px-8 bg-white rounded-lg shadow-md'>
                        <h3 className='text-lg font-semibold mb-2'>Photos</h3>
                        <ImageGallery images={firestoreCampground.images} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'reviews' && <div>Reviews, vibe checks, and cell service/wifi assessments comi ng soon.</div>}
              {activeTab === 'events' && (
                <div>
                  <EventList campgroundId={firestoreCampground.id} sinceDate={new Date()}></EventList>
                </div>
              )}
              {activeTab === 'chat' && <div>Chat coming soon.</div>}
            </div>
          </div>
        )}
      </PageLayout>
    </>
  )
}

export default Campground
