import React, { useEffect } from 'react'
import CampgroundMap from './components/CampgroundMap'
import BlogPostList from './components/BlogPostList'
import SectionHeader from './components/SectionHeader'
import EventList from './components/EventList'
import InfoFooter from './components/InfoFooter'
import Stats from './components/Stats'
import Legend from './components/Legend'
import ProductAd from './components/ProductAd'
import AdSense from 'react-adsense'
import PageLayout from '../../shared/components/PageLayout'

function Home() {
  return (
    <PageLayout
      showAds={false}
      metaTitle={'Gay Campground Directory, Map, Information, and Events - Gay Camping Friends'}
      metaDescription={
        'Gay Camping Friends is your ultimate guide to LGBTQ+ camping. Connect with a vibrant community, find inclusive campgrounds, and share memorable outdoor experiences with friends old and new.'
      }>
      <div className='flex flex-col items-center space-y-4'>
        <CampgroundMap />
        <div className='max-w-7xl'>
          <Legend />

          <Stats />
          <div className='mt-3 mb-3'>
            <AdSense.Google
              client='ca-pub-3505900880382517'
              slot='9099397642'
              style={{ display: 'block' }}
              layout='in-article'
              format='horizontal'
              responsive='true'
            />
          </div>
          <SectionHeader
            title={'Get the Sticker at Your Own Price'}
            subtitle={'These outdoor capable Gay Camping Friends stickers are great for your car, truck, RV, or trailer.'}
          />
          <ProductAd
            imageUrl='https://store.gaycampingfriends.com/cdn/shop/products/20211016_141853_1024x1024@2x.jpg?v=1635436310'
            altText='Gay Camping Friends Sticker'
            productUrl='https://store.gaycampingfriends.com/products/gay-camping-friends-sticker-donation'
            buttonText='Get Your Sticker Now for As Low as $3 Delivered'
          />
          <SectionHeader
            title={'Upcoming Gay Camping Friends Events'}
            subtitle={"We are s'more than just an online group, join us in person for an amazing weekend at one of our meetups!"}
          />
          <EventList sinceDate={new Date(new Date().getFullYear(), 0, 1)} />
          <div className='mt-3 mb-3'>
            <AdSense.Google
              client='ca-pub-3505900880382517'
              slot='6421834235'
              style={{ display: 'block' }}
              layout='in-article'
              format='horizontal'
              responsive='true'
            />
          </div>
          <SectionHeader
            title={'Latest Blog Posts'}
            subtitle={'Discover amazing content, explore our services, and stay up-to-date with the latest news.'}
          />
          <BlogPostList count={3} />
          <InfoFooter />
        </div>
      </div>
    </PageLayout>
  )
}

export default Home
