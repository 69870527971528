import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from '../../../contexts/AuthContext'
import userRepository from '../../../repositories/firestore/users.firestore.repository'
import { toast } from 'react-toastify'
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth'
import PageLayout from '../../shared/components/PageLayout'

const EditAccountForm = () => {
  const { currentUser, userDetails } = useAuth()
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: userDetails.secure || {}, // Initialize form with public user details
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (userDetails && userDetails.secure) {
      Object.keys(userDetails.secure).forEach((key) => {
        setValue(key, userDetails.secure[key])
      })
    }
  }, [userDetails, setValue])

  const onSubmit = async (secureData) => {
    if (!currentUser) return
    setIsLoading(true)

    // Retrieve the current secure data to ensure no data is lost
    const currentSecureData = userDetails.secure || {}

    // Merge the current secure data with the new changes
    // This ensures that only the fields present in the form are updated
    const updatedSecureData = { ...currentSecureData, ...secureData }

    try {
      await userRepository.updateSecureUserData(currentUser.uid, updatedSecureData) // Update with merged data
      toast.success('Account updated successfully')
    } catch (error) {
      console.error('Error updating account:', error)
      toast.error('Failed to update account.')
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmitPassword = async (data) => {
    const { currentPassword, newPassword, confirmPassword } = data

    if (newPassword !== confirmPassword) {
      toast.error('New passwords do not match')
      resetForm()
      return
    }

    setIsLoading(true)

    try {
      const user = currentUser
      const credential = EmailAuthProvider.credential(user.email, currentPassword)
      await reauthenticateWithCredential(user, credential)

      await updatePassword(user, newPassword)
      toast.success('Password updated successfully')
    } catch (error) {
      console.error('Error updating password:', error)
      toast.error(error.message)
    } finally {
      setIsLoading(false)
      resetForm()
    }
  }

  const resetForm = () => {
    reset({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    })
  }

  return (
    <PageLayout title='Edit Account'>
      <p className='mb-3'>This is information that is not shared with other users of the site.</p>
      <div className='space-y-8'>
        <form onSubmit={handleSubmit(onSubmit)} className='space-y-4 bg-white p-4 rounded-lg shadow'>
          <div>
            <label htmlFor='firstName' className='block text-sm font-medium text-gray-700'>
              First Name
            </label>
            <input
              {...register('firstName', { required: true })}
              type='text'
              className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
            />
            {errors.firstName && <p className='text-red-500'>First name is required.</p>}
          </div>
          <div>
            <label htmlFor='lastName' className='block text-sm font-medium text-gray-700'>
              Last Name
            </label>
            <input
              {...register('lastName', { required: true })}
              type='text'
              className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
            />
            {errors.lastName && <p className='text-red-500'>Last name is required.</p>}
          </div>
          <button
            type='submit'
            className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            disabled={isLoading}>
            Update Account
          </button>
        </form>
        <h2 className='text-2xl font-bold mb-4'>Change Password</h2>
        <form onSubmit={handleSubmit(onSubmitPassword)} className='space-y-4 bg-white p-4 rounded-lg shadow'>
          <div>
            <label htmlFor='currentPassword' className='block text-sm font-medium text-gray-700'>
              Current Password
            </label>
            <input
              {...register('currentPassword', { required: true })}
              type='password'
              className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
            />
            {errors.currentPassword && <p className='text-red-500'>Current password is required.</p>}
          </div>
          <div>
            <label htmlFor='newPassword' className='block text-sm font-medium text-gray-700'>
              New Password
            </label>
            <input
              {...register('newPassword', { required: true })}
              type='password'
              className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
            />
            {errors.newPassword && <p className='text-red-500'>New password is required.</p>}
          </div>
          <div>
            <label htmlFor='confirmPassword' className='block text-sm font-medium text-gray-700'>
              Confirm New Password
            </label>
            <input
              {...register('confirmPassword', { required: true })}
              type='password'
              className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
            />
            {errors.confirmPassword && <p className='text-red-500'>Please confirm your new password.</p>}
          </div>
          <button
            type='submit'
            disabled={isLoading}
            className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
            Change Password
          </button>
        </form>
      </div>
    </PageLayout>
  )
}

export default EditAccountForm
