import React from "react";

const SectionHeader = ({ title, subtitle }) => {
  return (
    <div className="bg-gray-100 py-8">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-gray-800 mb-4">{title}</h2>
        <p className="text-xl text-gray-600">{subtitle}</p>
      </div>
    </div>
  );
};

export default SectionHeader;
