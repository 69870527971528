import React from 'react'

function ProductAd({ imageUrl, altText, productUrl, buttonText }) {
  return (
    <div className='bg-gray-100 p-4 md:p-8'>
      <div className='max-w-md mx-auto md:max-w-lg lg:max-w-xl'>
        <img src={imageUrl} alt={altText} className='w-full h-auto rounded-lg shadow-lg mb-4' />
        <div className='text-center'>
          <a
            href={productUrl}
            className='inline-block bg-indigo-600 text-white text-sm md:text-base px-6 py-2 rounded-lg hover:bg-indigo-700 transition-colors'>
            {buttonText}
          </a>
        </div>
      </div>
    </div>
  )
}

export default ProductAd
