import React, { useEffect, useState } from 'react'
import userRepository from '../../../repositories/firestore/users.firestore.repository'
import ProfilePhoto from '../../shared/components/ProfilePhoto'
import { Link, useLocation } from 'react-router-dom'
import PageLayout from '../../shared/components/PageLayout'

const Leaderboard = () => {
  const [leaderboard, setLeaderboard] = useState([])
  const location = useLocation()

  useEffect(() => {
    console.log(location)
    const fetchLeaderboard = async () => {
      const leaderboardData = await userRepository.getUsersLeaderboard()
      setLeaderboard(leaderboardData)
    }

    fetchLeaderboard()
  }, [])

  return (
    <PageLayout title='GCF Point Leaderboard'>
      <div className='space-y-4'>
        {leaderboard.map((user, index) => (
          <Link to={`/user/profile/${user.id}`} key={user.id} className='block'>
            <div className='bg-white rounded-lg shadow hover:shadow-lg transition-shadow duration-300 p-4 flex items-center'>
              <span className='text-lg font-semibold mr-4'>{index + 1}</span>
              <ProfilePhoto src={user.profilePhotoUrl} displayName={user.displayName} size='16' className='flex-shrink-0' />
              <div className='flex-grow ml-4'>
                <h3 className='text-lg font-semibold'>{user.displayName}</h3>
                <p className='text-gray-600'>GCF Points: {user.totalPoints}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </PageLayout>
  )
}

export default Leaderboard
