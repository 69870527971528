import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext' // Adjust path as necessary
import forumRepository from '../../../repositories/firestore/forum.firestore.repository' // Adjust path as necessary
import { FaTrash } from 'react-icons/fa'

const ForumItem = ({ forum, onDelete }) => {
  const { userDetails } = useAuth()

  const isAdmin = userDetails && userDetails.secure.roles.includes('admin')

  return (
    <li className='mb-2 p-2 bg-white shadow rounded flex items-center justify-between'>
      <div className='flex flex-col min-w-0'>
        <Link to={`/user/forums/${forum.id}`} className='text-lg font-semibold text-blue-600 hover:text-blue-800'>
          {forum.name}
        </Link>
        <p className='text-sm text-gray-700'>{forum.description}</p>
      </div>
      {isAdmin && (
        <button onClick={() => onDelete(forum.id)} className='ml-4'>
          <FaTrash className='text-gray-500 hover:text-gray-700 text-sm' />
        </button>
      )}
    </li>
  )
}

export default ForumItem
