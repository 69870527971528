import { collection, query, where, orderBy, doc, addDoc, updateDoc, getDoc, deleteDoc, limit, Timestamp, getDocs } from 'firebase/firestore'
import { startOfMonth, endOfMonth, subMonths } from 'date-fns'
import baseRepository from './base.firestore.repository'

const tripsCollectionRef = collection(baseRepository.getDb(), 'trips')

let tripsCache = {
  allTrips: {
    data: null,
    lastFetch: 0,
    cacheDuration: 1000 * 60 * 5, // 5 minutes
  },
  futureTripsByCampground: {},
}

let cache = {
  lastFetch: 0,
  data: [],
  duration: 1000 * 60 * 5, // Cache duration in milliseconds, here set to 5 minutes
}

//CURRENTLY LIMITED TO 100 RECORDS
const getAllUpcomingTrips = async () => {
  const currentDate = new Date()
  const now = Date.now()

  // Check if cache is still valid
  if (now - tripsCache.allTrips.lastFetch < tripsCache.allTrips.cacheDuration) {
    return tripsCache.allTrips.data // Return cached data if still valid
  }

  try {
    const currentDateTimestamp = Timestamp.fromDate(currentDate) // Convert current date to Firestore Timestamp

    const q = query(
      tripsCollectionRef,
      where('startDate', '>=', currentDateTimestamp), // Only fetch trips starting in the future
      orderBy('startDate', 'asc'),
      limit(100), // Limit to the first 100 upcoming trips
    )

    const tripSnapshots = await getDocs(q)
    const trips = tripSnapshots.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))

    // Update cache
    tripsCache.allTrips = {
      lastFetch: now,
      data: trips,
    }

    return trips
  } catch (error) {
    console.error('Error retrieving upcoming trips:', error)
    throw error
  }
}

const getTripsByUserId = async (userId) => {
  try {
    const q = query(tripsCollectionRef, where('userId', '==', userId), orderBy('startDate', 'asc'))
    const tripSnapshots = await baseRepository.getDocsFromQuery(q)
    return tripSnapshots.map((trip) => ({ id: trip.id, ...trip })) // Assuming trip is already a plain object
  } catch (error) {
    console.error('Error retrieving trips for user:', error)
    throw error
  }
}

// Function to get trips by campground ID
const getTripsByCampgroundId = async (campgroundId) => {
  const currentDateTimestamp = Timestamp.fromDate(new Date())
  const q = query(
    tripsCollectionRef,
    where('campgroundId', '==', campgroundId),
    where('startDate', '>=', currentDateTimestamp),
    orderBy('startDate', 'asc'),
    limit(100),
  )

  const tripSnapshots = await getDocs(q)
  return tripSnapshots.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }))
}

const getFutureTripsByCampgroundId = async (campgroundId) => {
  const now = Date.now()

  // Check cache first
  if (
    tripsCache.futureTripsByCampground[campgroundId] &&
    now - tripsCache.futureTripsByCampground[campgroundId].lastFetch < tripsCache.futureTripsByCampground[campgroundId].cacheDuration
  ) {
    console.log('Cache hit: Returning future trips for campground from cache')
    return tripsCache.futureTripsByCampground[campgroundId].data
  }

  console.log('Cache miss or cache expired: Fetching data from Firestore')

  // Set up the cache if it doesn't exist
  if (!tripsCache.futureTripsByCampground[campgroundId]) {
    tripsCache.futureTripsByCampground[campgroundId] = {
      data: null,
      lastFetch: now, // Ensure lastFetch is initialized properly here
      cacheDuration: 1000 * 60 * 5, // 5 minutes
    }
  }

  const utcNow = Date.UTC(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate(),
    new Date().getUTCHours(),
    new Date().getUTCMinutes(),
    new Date().getUTCSeconds(),
  )
  const nowTimestamp = Timestamp.fromMillis(utcNow)

  const q = query(
    tripsCollectionRef,
    where('campgroundId', '==', campgroundId),
    where('startDate', '>=', nowTimestamp),
    orderBy('startDate', 'asc'),
    limit(100),
  )

  try {
    const snapshot = await getDocs(q)
    const trips = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))

    // Properly update the cache with the new data and current time
    tripsCache.futureTripsByCampground[campgroundId] = {
      lastFetch: now, // Update lastFetch here as well
      data: trips,
      cacheDuration: 1000 * 60 * 5, // This is just a repetition, can be omitted if unchanged
    }
    console.log('Data fetched and cache updated.')

    return trips
  } catch (error) {
    console.error('Error fetching future trips for campground:', error)
    throw error
  }
}

const getTripsByCampgroundByMonthYear = async (campgroundId, year, month) => {
  const cacheKey = `trips-${campgroundId}-${year}-${month}`

  // Check cache first
  if (tripsCache[cacheKey] && Date.now() - tripsCache[cacheKey].lastFetch < tripsCache[cacheKey].cacheDuration) {
    console.log('Returning cached trips for:', cacheKey)
    return tripsCache[cacheKey].data
  }

  console.log('Cache miss or expired for:', cacheKey)

  // Calculate the start of the previous month
  const targetDate = new Date(year, month - 1, 1) // month - 1 because JavaScript months are 0-indexed
  const startDate = startOfMonth(subMonths(targetDate, 1))
  const endDate = endOfMonth(targetDate) // End of the target month

  const startTimestamp = Timestamp.fromDate(startDate)
  const endTimestamp = Timestamp.fromDate(endDate)

  try {
    // Query for trips that start from the first day of the previous month up to the end of the target month
    const q = query(
      tripsCollectionRef,
      where('campgroundId', '==', campgroundId),
      where('startDate', '<=', endTimestamp),
      orderBy('startDate', 'asc'),
    )

    const snapshot = await getDocs(q)
    const trips = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      startDate: new Date(doc.data().startDate.seconds * 1000), // Converting Firestore Timestamp to Date
      endDate: new Date(doc.data().endDate.seconds * 1000), // Converting Firestore Timestamp to Date
    }))

    // Update cache
    tripsCache[cacheKey] = {
      data: trips,
      lastFetch: Date.now(),
      cacheDuration: 1000 * 60 * 5, // 5 minutes
    }

    return trips
  } catch (error) {
    console.error(`Error fetching trips for campground ${campgroundId} from ${startDate} to ${endDate}:`, error)
    throw error
  }
}

const getTripById = async (tripId) => {
  try {
    const tripRef = doc(baseRepository.getDb(), 'trips', tripId)
    const tripSnap = await getDoc(tripRef)

    if (tripSnap.exists()) {
      return { id: tripSnap.id, ...tripSnap.data() }
    } else {
      console.log('No such trip document!')
      return null
    }
  } catch (error) {
    console.error(`Error retrieving trip with ID ${tripId}:`, error)
    throw error
  }
}

const addTrip = async (tripData) => {
  try {
    const docRef = await addDoc(tripsCollectionRef, tripData)
    return docRef.id
  } catch (error) {
    console.error('Error adding trip:', error)
    throw error
  }
}

const updateTrip = async (tripId, tripData) => {
  try {
    const tripRef = doc(baseRepository.getDb(), 'trips', tripId)
    await updateDoc(tripRef, tripData)
    return tripId
  } catch (error) {
    console.error(`Error updating trip with ID ${tripId}:`, error)
    throw error
  }
}

const deleteTrip = async (tripId) => {
  try {
    const tripRef = doc(baseRepository.getDb(), 'trips', tripId)
    await deleteDoc(tripRef)
    return tripId
  } catch (error) {
    console.error(`Error deleting trip with ID ${tripId}:`, error)
    throw error
  }
}

const tripRepository = {
  getAllUpcomingTrips,
  getTripsByUserId,
  getTripsByCampgroundId,
  getFutureTripsByCampgroundId,
  getTripsByCampgroundByMonthYear,
  getTripById,
  addTrip,
  updateTrip,
  deleteTrip,
}

export default tripRepository
