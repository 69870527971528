import React from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'

function LocationMap({ title, lat, lng }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDhgtkMamsxbcA44ndTXN7avyRV8bvIxhY',
  })

  // const [map, setMap] = React.useState(null)

  const onUnmount = React.useCallback(function callback(map) {
    //setMap(null)
  }, [])

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={{
          height: '200px',
          width: 'w-full',
        }}
        center={{ lat, lng }}
        zoom={6}
        onUnmount={onUnmount}
        options={{
          mapTypeControl: false,
          streetViewControl: false,
        }}>
        <Marker title={title} position={{ lat, lng }}></Marker>
      </GoogleMap>
    </>
  ) : (
    <></>
  )
}

export default LocationMap
