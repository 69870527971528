import { collection, query, orderBy, doc, addDoc, updateDoc, getDoc, deleteDoc } from 'firebase/firestore'
import baseRepository from './base.firestore.repository'

const chatRoomsCollectionRef = collection(baseRepository.getDb(), 'chatRooms')

let chatRoomsCache = {
  allChatRooms: {
    data: null,
    lastFetch: 0,
    cacheDuration: 1000 * 60 * 5, // 5 minutes
  },
  byId: {},
}

const getChatRooms = async () => {
  const now = Date.now()
  if (chatRoomsCache.allChatRooms.data && now - chatRoomsCache.allChatRooms.lastFetch < chatRoomsCache.allChatRooms.cacheDuration) {
    return chatRoomsCache.allChatRooms.data
  }

  try {
    const q = query(chatRoomsCollectionRef, orderBy('name', 'asc'))
    const chatRooms = await baseRepository.getDocsFromQuery(q)
    chatRoomsCache.allChatRooms.data = chatRooms
    chatRoomsCache.allChatRooms.lastFetch = now
    return chatRooms
  } catch (error) {
    console.error('Error retrieving chat rooms:', error)
    throw error
  }
}

const getChatRoomById = async (chatRoomId) => {
  const now = Date.now()
  if (chatRoomsCache.byId[chatRoomId] && now - chatRoomsCache.byId[chatRoomId].lastFetch < chatRoomsCache.allChatRooms.cacheDuration) {
    return chatRoomsCache.byId[chatRoomId].data
  }

  try {
    const docRef = doc(chatRoomsCollectionRef, chatRoomId)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const chatRoom = { id: docSnap.id, ...docSnap.data() }
      chatRoomsCache.byId[chatRoomId] = {
        data: chatRoom,
        lastFetch: now,
      }
      return chatRoom
    } else {
      throw new Error('Chat room not found')
    }
  } catch (error) {
    console.error('Error retrieving chat room:', error)
    throw error
  }
}

const addChatRoom = async (chatRoomData) => {
  try {
    const docRef = await addDoc(chatRoomsCollectionRef, chatRoomData)
    // Invalidate all rooms cache
    chatRoomsCache.allChatRooms.data = null
    return docRef.id
  } catch (error) {
    console.error('Error adding chat room:', error)
    throw error
  }
}

const updateChatRoom = async (chatRoomId, chatRoomData) => {
  try {
    const chatRoomRef = doc(chatRoomsCollectionRef, chatRoomId)
    await updateDoc(chatRoomRef, chatRoomData)
    // Invalidate specific chat room and all rooms cache
    chatRoomsCache.byId[chatRoomId] = undefined
    chatRoomsCache.allChatRooms.data = null
    return chatRoomId
  } catch (error) {
    console.error(`Error updating chat room with ID ${chatRoomId}:`, error)
    throw error
  }
}

const deleteChatRoom = async (chatRoomId) => {
  try {
    const chatRoomRef = doc(chatRoomsCollectionRef, chatRoomId)
    await deleteDoc(chatRoomRef)
    // Invalidate specific chat room and all rooms cache
    chatRoomsCache.byId[chatRoomId] = undefined
    chatRoomsCache.allChatRooms.data = null
    return chatRoomId
  } catch (error) {
    console.error(`Error deleting chat room with ID ${chatRoomId}:`, error)
    throw error
  }
}

const chatRoomRepository = {
  getChatRooms,
  getChatRoomById,
  addChatRoom,
  updateChatRoom,
  deleteChatRoom,
}

export default chatRoomRepository
