import React from 'react'
import CountUp from 'react-countup'

const Stats = () => {
  return (
    <div>
      <div className='mx-auto py-6 px-4 sm:px-6 lg:px-8 bg-white rounded-lg shadow-md'>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8'>
          <div className='flex flex-col justify-center items-center h-full'>
            <div className='text-3xl font-bold text-gray-800 mb-4'>
              <CountUp separator=',' delay={2} end={223552} />
            </div>
            <p className='text-xl text-gray-600'>Facebook Members</p>
          </div>

          <div className='flex flex-col justify-center items-center h-full'>
            <div className='text-3xl font-bold text-gray-800 mb-4'>
              <CountUp separator=',' delay={2} end={4836} />
            </div>
            <p className='text-xl text-gray-600'>Discord Members</p>
          </div>
          <div className='flex flex-col justify-center items-center h-full'>
            <div className='text-3xl font-bold text-gray-800 mb-4'>
              <CountUp separator=',' delay={2} end={75845} />
            </div>
            <p className='text-xl text-gray-600'>Daily Active Members</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Stats
