import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import { getApp } from 'firebase/app'
import { toast } from 'react-toastify'
import userRepository from '../../../../repositories/firestore/users.firestore.repository'
import PageLayout from '../../../shared/components/PageLayout'

const SignupForm = () => {
  const navigate = useNavigate()

  const initialValues = {
    firstName: '',
    lastName: '',
    displayName: '',
    pronouns: '',
    email: '',
    password: '',
    confirmPassword: '',
    privacy: false,
    terms: false,
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    displayName: Yup.string(),
    pronouns: Yup.string(),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
    privacy: Yup.boolean().oneOf([true], 'You must accept the Privacy Policy').required('You must accept the Privacy Policy'),
    terms: Yup.boolean().oneOf([true], 'You must accept the Terms and Conditions').required('You must accept the Terms and Conditions'),
  })

  const handleSubmit = async (values, { setSubmitting }) => {
    const { email, password, firstName, lastName, displayName, pronouns } = values
    const app = getApp()
    const auth = getAuth(app)

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password)
      const userId = userCredential.user.uid

      // Splitting userData into public and secure data according to the new structure
      const publicUserData = {
        displayName: displayName || `${firstName} ${lastName}`,
        pronouns,
        // Initialize public data that should be set upon user creation
        // points: {
        //   loginPoints: 0, // Initial loginPoints
        //   signUpPoints: 100, // Initial signUpPoints for signing up
        // },
        lastLoginDate: new Date().toISOString(), // Set the lastLoginDate to now
      }

      const secureUserData = {
        // Secure user data
        email,
        firstName,
        lastName,
      }

      // Adding user data to Firestore under separate documents for public and secure data
      await userRepository.addUser(userId, publicUserData, secureUserData)

      await userRepository.updateUserPointsById(userCredential.user.uid, 'signUpPoints', 100)
      toast.success('Signup Successful')
      navigate('/auth/login')
    } catch (error) {
      toast.error(error.message)
      console.error('Error signing up:', error.message)
    }

    setSubmitting(false)
  }

  return (
    <PageLayout
      title='Create an Account (Beta)'
      showAds={false}
      metaTitle={'Create a Free Gay Camping Friends Account'}
      metaDescription={'Creating a Gay Camping Friends account is free. Sign up here to see exclusive member only content.'}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ setFieldValue, values }) => (
          <Form className='space-y-4 bg-white p-4 rounded-lg shadow'>
            <div>
              <label htmlFor='firstName' className='block text-sm font-medium text-gray-700'>
                First Name
              </label>
              <Field
                name='firstName'
                type='text'
                placeholder='John'
                className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                onChange={(e) => {
                  setFieldValue('firstName', e.target.value)
                  if (!values.displayNameEdited) {
                    setFieldValue('displayName', `${e.target.value} ${values.lastName}`)
                  }
                }}
              />
              <ErrorMessage name='firstName' component='div' className='text-red-500' />
            </div>

            <div>
              <label htmlFor='lastName' className='block text-sm font-medium text-gray-700'>
                Last Name
              </label>
              <Field
                name='lastName'
                type='text'
                placeholder='Doe'
                className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                onChange={(e) => {
                  setFieldValue('lastName', e.target.value)
                  if (!values.displayNameEdited) {
                    setFieldValue('displayName', `${values.firstName} ${e.target.value}`)
                  }
                }}
              />
              <ErrorMessage name='lastName' component='div' className='text-red-500' />
            </div>

            <div>
              <label htmlFor='displayName' className='block text-sm font-medium text-gray-700'>
                Display Name
              </label>
              <Field
                name='displayName'
                type='text'
                placeholder='John Doe'
                className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                onChange={(e) => {
                  setFieldValue('displayName', e.target.value)
                  setFieldValue('displayNameEdited', true)
                }}
              />
              <ErrorMessage name='displayName' component='div' className='text-red-500' />
            </div>

            <div>
              <label htmlFor='pronouns' className='block text-sm font-medium text-gray-700'>
                Pronouns
              </label>
              <Field
                name='pronouns'
                type='text'
                placeholder='e.g., He/Him'
                className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
              />
              <ErrorMessage name='pronouns' component='div' className='text-red-500' />
            </div>

            <div>
              <label htmlFor='email' className='block text-sm font-medium text-gray-700'>
                Your Email (Used to Login)
              </label>
              <Field
                type='email'
                name='email'
                placeholder='name@company.com'
                className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
              />
              <ErrorMessage name='email' component='div' className='text-red-500' />
            </div>

            <div>
              <label htmlFor='password' className='block text-sm font-medium text-gray-700'>
                Password
              </label>
              <Field
                type='password'
                name='password'
                placeholder='••••••••'
                className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
              />
              <ErrorMessage name='password' component='div' className='text-red-500' />
            </div>

            <div>
              <label htmlFor='confirmPassword' className='block text-sm font-medium text-gray-700'>
                Confirm Password
              </label>
              <Field
                type='password'
                name='confirmPassword'
                placeholder='••••••••'
                className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
              />
              <ErrorMessage name='confirmPassword' component='div' className='text-red-500' />
            </div>

            {/* Terms and Privacy Policy Checkboxes */}
            <div className='flex items-start'>
              <div className='flex items-center h-5'>
                <Field
                  type='checkbox'
                  name='privacy'
                  id='privacy'
                  className='w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-indigo-500'
                  required
                />
              </div>
              <div className='ml-3 text-sm'>
                <label htmlFor='privacy' className='font-light text-gray-500'>
                  I accept the{' '}
                  <a className='font-medium text-indigo-600 hover:underline' href='/privacy-policy' target='_blank'>
                    Privacy Policy
                  </a>
                </label>
              </div>
            </div>

            <div className='flex items-start'>
              <div className='flex items-center h-5'>
                <Field
                  type='checkbox'
                  name='terms'
                  id='terms'
                  className='w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-indigo-500'
                  required
                />
              </div>
              <div className='ml-3 text-sm'>
                <label htmlFor='terms' className='font-light text-gray-500'>
                  I agree to the{' '}
                  <a className='font-medium text-indigo-600 hover:underline' href='/terms-conditions' target='_blank'>
                    Terms and Conditions
                  </a>
                </label>
              </div>
            </div>

            <button
              type='submit'
              className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
              Create Account
            </button>
          </Form>
        )}
      </Formik>
    </PageLayout>
  )
}

export default SignupForm
