import React from 'react'
import {
  FaUserEdit,
  FaSuitcaseRolling,
  FaListOl,
  FaUserCog,
  FaAddressBook,
  FaComments,
  FaTags,
  FaStar,
  FaUsers,
  FaEdit,
  FaRss,
  FaUserSlash,
  FaCampground,
  FaChalkboard,
} from 'react-icons/fa'
import PageLayout from '../../shared/components/PageLayout'
import DashboardTile from '../../shared/components/DashboardTile'

const UserHome = () => {
  return (
    <PageLayout title='My Dashboard'>
      <p className='text-center mb-10'>
        Welcome to the Beta user backend. This area is actively under development and may change often as we refine and add features. Items marked as
        Alpha are very early with known issues, but we hope they can be useful. Any issues? Email info@gaycampingfriends.com
      </p>
      <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-4'>
        <DashboardTile link='/user/edit-profile' icon={FaUserEdit} title='Edit Profile' />
        <DashboardTile link='/user/edit-account' icon={FaUserCog} title='Edit Account' />
        <DashboardTile link='/user/trips' icon={FaSuitcaseRolling} title='My Trips' />
        <DashboardTile link='/user/leaderboard' icon={FaListOl} title='Leaderboard' />
        <DashboardTile link='/user/directory' icon={FaAddressBook} title='Member Directory' />
        <DashboardTile link='/user/feed' icon={FaRss} title='Feed' />
        <DashboardTile link='/user/blocked' icon={FaUserSlash} title='Blocked Users (Alpha)' />
        <DashboardTile link='/user/chat' icon={FaComments} title='Chat Rooms (Alpha)' />
        <DashboardTile link='/user/forums' icon={FaChalkboard} title='Forums (Alpha)' />
        <DashboardTile link='#' icon={FaTags} title='Classifieds (Future)' enabled={false} />
        <DashboardTile link='#' icon={FaStar} title='My Reviews (Future)' enabled={false} />
        <DashboardTile link='#' icon={FaUsers} title='My Friends (Future)' enabled={false} />
        <DashboardTile link='#' icon={FaEdit} title='My Edits (Future)' enabled={false} />
        <DashboardTile link='#' icon={FaCampground} title='Virtual Campsite (Future)' enabled={false} />
      </div>
    </PageLayout>
  )
}

export default UserHome
