import React from 'react'
import ProfilePhoto from '../../../shared/components/ProfilePhoto'

const UserOptions = ({ isOpen, user, onClose, onViewProfile, onBlock }) => {
  if (!isOpen) return null

  return (
    <div className='absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50' onClick={onClose}>
      <div className='modal-content bg-white p-5 rounded-lg shadow-lg' onClick={(e) => e.stopPropagation()}>
        <div className='flex flex-col items-center'>
          <ProfilePhoto src={user.profilePhotoUrl} displayName={user.displayName} size='48' />
          <h2 className='text-xl font-semibold mt-3 mb-4'>{user.displayName}</h2>
        </div>
        <div className='flex justify-around mb-4'>
          <button
            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            onClick={() => onViewProfile(user.userId)}>
            View Profile
          </button>
          <button
            className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            onClick={() => onBlock(user.userId)}>
            Block
          </button>
        </div>
        <button
          className='w-full bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 rounded focus:outline-none focus:shadow-outline'
          onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  )
}

export default UserOptions
