import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import { FaCalendarAlt, FaEdit, FaEye, FaBriefcase, FaTags, FaBell, FaAd } from 'react-icons/fa'
import PageLayout from '../../shared/components/PageLayout'
import DashboardTile from '../../shared/components/DashboardTile'
import campgroundRepository from '../../../repositories/firestore/campgrounds.firestore.repository'

function CampgroundAdminDashboard() {
  const { userDetails } = useAuth()
  const { campgroundId } = useParams()
  const [campground, setCampground] = useState(null)

  useEffect(() => {
    if (userDetails.secure.roles.includes('campgroundAdmin') && userDetails.secure.campgroundId !== campgroundId) {
      console.error('Unauthorized access attempt to a different campground.')
    } else {
      campgroundRepository
        .getCampgroundById(campgroundId)
        .then(setCampground)
        .catch((err) => console.error('Failed to fetch campground data:', err))
    }
  }, [campgroundId, userDetails.secure])

  return (
    <PageLayout title={`Manage Campground: ${campground ? campground.name : ''}`}>
      <p className='text-center mb-10'>Welcome to the Campground Management backend. Manage your campground details, events, and more.</p>
      <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-4'>
        {campground && <DashboardTile link={`/campground/${campground.slug}`} icon={FaEye} title='View Listing' />}
        <DashboardTile link={`/campground-admin/${campgroundId}/details`} icon={FaEdit} title='Manage Listing' />
        <DashboardTile link={`/campground-admin/${campgroundId}/events`} icon={FaCalendarAlt} title='Manage Events' />
        <DashboardTile link='#' icon={FaBriefcase} title='Job Listings (Future)' enabled={false} />
        <DashboardTile link='#' icon={FaTags} title='Classifieds (Future)' enabled={false} />
        <DashboardTile link='#' icon={FaBell} title='Updates (Future)' enabled={false} />
        <DashboardTile link='#' icon={FaAd} title='Advertisements (Future)' enabled={false} />
      </div>
    </PageLayout>
  )
}

export default CampgroundAdminDashboard
