import React from 'react'
import { Navigate, Outlet, useParams } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'

const CampgroundAdminRoute = () => {
  const { currentUser, userDetails } = useAuth()
  const { campgroundId } = useParams()

  if (!currentUser) {
    return <Navigate to='/auth/login' replace />
  }

  const userHasRequiredRole =
    userDetails.secure.roles.includes('admin') ||
    (userDetails.secure.roles.includes('campgroundAdmin') && userDetails.secure.campgroundId === campgroundId)

  if (!userHasRequiredRole) {
    return <Navigate to='/' replace />
  }

  return <Outlet /> // Use Outlet to render child routes
}

export default CampgroundAdminRoute
