import React, { useState, useEffect } from 'react'
import userRepository from '../../../../repositories/firestore/users.firestore.repository'
import ProfilePhoto from '../../../shared/components/ProfilePhoto'
import { Link } from 'react-router-dom'
import { Country, State } from 'country-state-city'
import PageLayout from '../../../shared/components/PageLayout'

const _PAGE_SIZE = 50

const MembersList = () => {
  const [users, setUsers] = useState([])
  const [filters, setFilters] = useState({ displayName: '', country: '', state: '' })
  const [lastVisible, setLastVisible] = useState(null)
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [pageIndex, setPageIndex] = useState(0) // Keep track of the current page index

  useEffect(() => {
    setCountries(Country.getAllCountries())
  }, [])

  useEffect(() => {
    if (filters.country) {
      setStates(State.getStatesOfCountry(filters.country))
    } else {
      setStates([])
    }
  }, [filters.country])

  const fetchUsers = async (token) => {
    if (loading) return // Prevent multiple fetches at the same time
    setLoading(true)

    try {
      const { usersList, lastVisible: newLastVisible } = await userRepository.getPagedUsers(_PAGE_SIZE, token, filters)
      setUsers((prevUsers) => (token ? [...prevUsers, ...usersList] : usersList))
      setLastVisible(newLastVisible)
      setHasMore(usersList.length === _PAGE_SIZE)
      setPageIndex(token ? pageIndex + 1 : 0) // Update page index or reset it
    } catch (error) {
      console.error('Failed to fetch users:', error)
      setHasMore(false) // Assume no more users if there's an error
    } finally {
      setLoading(false)
    }
  }

  // Use effect for initial load
  useEffect(() => {
    fetchUsers(null)
  }, [])

  const handleNextPage = () => {
    if (lastVisible && !loading && hasMore) {
      fetchUsers(lastVisible)
    }
  }

  const handleFilterChange = (e) => {
    const { name, value } = e.target
    setFilters((prev) => ({ ...prev, [name]: value }))
  }

  const performSearch = () => {
    fetchUsers(null) // Fetch from the beginning with current filters
  }

  const getCountryName = (isoCode) => {
    const country = Country.getCountryByCode(isoCode)
    return country ? country.name : ''
  }

  const getStateName = (countryCode, stateCode) => {
    const state = State.getStateByCodeAndCountry(stateCode, countryCode)
    return state ? state.name : ''
  }

  return (
    <PageLayout title='Manage Members'>
      <div className='grid grid-cols-1 md:grid-cols-4 gap-4 mb-6'>
        <input
          type='text'
          name='displayName'
          placeholder='Search by name'
          onChange={handleFilterChange}
          value={filters.displayName}
          className='border border-gray-300 rounded-md p-2 col-span-1 md:col-span-3'
        />
        <select
          name='country'
          onChange={handleFilterChange}
          value={filters.country}
          className='border border-gray-300 rounded-md p-2 col-span-1 md:col-span-3'>
          <option value=''>Select a country</option>
          {countries.map((country) => (
            <option key={country.isoCode} value={country.isoCode}>
              {country.name}
            </option>
          ))}
        </select>
        <select
          name='state'
          onChange={handleFilterChange}
          value={filters.state}
          className='border border-gray-300 rounded-md p-2 col-span-1 md:col-span-3'>
          <option value=''>Select a state</option>
          {states.map((state) => (
            <option key={state.isoCode} value={state.isoCode}>
              {state.name}
            </option>
          ))}
        </select>
        <button
          onClick={performSearch}
          className='bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-700 transition-colors duration-200 md:col-start-4'>
          Search
        </button>
      </div>
      <h2 className='text-2xl font-bold mb-4'>Results</h2>
      <div className='text-lg mb-4'>Total Users Loaded: {users.length}</div> {/* Display total user count */}
      {loading ? (
        <p>Loading...</p>
      ) : users.length === 0 ? (
        <p>No users to display.</p>
      ) : (
        <ul className='space-y-2'>
          {users.map((user, index) => (
            <li key={user.id} className='bg-white rounded-lg shadow p-4 flex items-center space-x-4'>
              <ProfilePhoto src={user.profilePhotoUrl} displayName={user.displayName} size='16' className='flex-shrink-0' />
              <div className='flex-grow'>
                <Link to={`/user/profile/${user.id}`} className='text-blue-600 hover:text-blue-800 font-bold'>
                  {user.displayName} {user.pronouns && '(' + user.pronouns + ')'}
                </Link>
                {(user.country || user.state) && (
                  <p className='text-gray-600'>
                    {user.country ? getCountryName(user.country) : ''}
                    {user.state ? ', ' + getStateName(user.country, user.state) : ''}
                  </p>
                )}
                <p className='text-gray-600'>GCF Points: {user.totalPoints}</p>
                <div className='flex mt-2'>
                  <Link to={`/user/profile/${user.id}`} className='text-white bg-blue-500 hover:bg-blue-700 font-medium py-1 px-2 rounded'>
                    View Profile
                  </Link>
                  <Link to={`/admin/member/${user.id}`} className='text-white bg-green-500 hover:bg-green-700 font-medium py-1 px-2 rounded ml-2'>
                    Manage Member
                  </Link>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
      {hasMore && !loading && (
        <button onClick={handleNextPage} className='bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-200 mt-4'>
          Load More
        </button>
      )}
    </PageLayout>
  )
}

export default MembersList
