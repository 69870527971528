import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import CampgroundList from './components/CampgroundList'
import campgroundsRepository from '../../../../repositories/campgrounds.repository'
import campgroundsFirestoreRepository from '../../../../repositories/firestore/campgrounds.firestore.repository'
import categoriesRepository from '../../../../repositories/categories.repository'
import AdSense from 'react-adsense'
import PageLayout from '../../../shared/components/PageLayout'

function CampgroundCategory() {
  let { slug } = useParams()

  const [category, setCategory] = useState(null)
  const [campgrounds, setCampgrounds] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const category = await categoriesRepository.GetCategoryBySlug(slug)
        if (category) {
          setCategory(category)
          //const data = await campgroundsRepository.GetCampgroundsByCategoryId(category.id)
          const data = await campgroundsFirestoreRepository.getCampgroundsByCategory(category.id.toString())
          setCampgrounds(data)
          setLoading(false)
        } else {
          throw new Error('Category was not found.')
        }
      } catch (error) {
        setError(error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <PageLayout
      metaTitle={`List of all ${category.name}`}
      metaDescription={`This is a list of all ${category.name}. Use this list to help find ${category.name} that you would like to visit.`}>
      <div className='max-w-7xl flex flex-col space-y-4'>
        <div className='flex flex-col px-4'>
          <div className='hover:underline text-blue-500 mb-4'>
            <Link to='/'>Map of All Campgrounds</Link>
          </div>
          <div className='flex flex-col-reverse md:flex-row md:justify-between items-center'>
            <div className='flex flex-col items-center md:items-start'>
              <h2 className='text-3xl font-bold mb-4'>{category.name}</h2>
            </div>
          </div>
        </div>
        {campgrounds && <CampgroundList campgrounds={campgrounds} />}
      </div>
    </PageLayout>
  )
}

export default CampgroundCategory
