import React, { useEffect, useState } from 'react'
import EventItem from './EventItem'
import eventsRepository from '../../../../../repositories/firestore/events.firestore.repository'

const EventList = ({ campgroundId, count, sinceDate }) => {
  const [events, setEvents] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await eventsRepository.getLatestCampgroundEvents(campgroundId, count, sinceDate)
        setEvents(data)
        setLoading(false)
      } catch (error) {
        setError(error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <>
      {events && events.length > 0 ? (
        <div className='flex flex-col'>
          {events.map((event) => (
            <EventItem
              key={event.id}
              title={event.title}
              startDate={event.startDate.toDate().toLocaleDateString('en-US')}
              endDate={event.endDate.toDate().toLocaleDateString('en-US')}
              location={event.venue}
              description={event.description}
              graphicSrc={event.coverImage}
              id={event.id}
            />
          ))}
        </div>
      ) : (
        <div className='mx-auto w-full py-6 px-4 sm:px-6 lg:px-8 bg-white rounded-lg shadow-md mb-4'>
          <h3 className='text-lg font-semibold mb-4'>No Events ☹️</h3>
          <p className='mt-2'>
            There are no future events entered into the system for this campground. Please contact the campground directly or visit their website.
          </p>
        </div>
      )}
    </>
  )
}

export default EventList
