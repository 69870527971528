import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import chatRoomRepository from '../../../../repositories/firestore/chatRoom.firestore.repository'
import ToggleSwitch from '../../../shared/components/toggleSwitch/ToggleSwitch'

const ChatRoomForm = () => {
  const navigate = useNavigate()
  const { chatRoomId } = useParams()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      mode: 'Clean', // Set the default mode to 'Clean'
    },
  })

  useEffect(() => {
    if (chatRoomId) {
      const fetchChatRoomData = async () => {
        try {
          const data = await chatRoomRepository.getChatRoomById(chatRoomId)
          if (data) {
            Object.keys(data).forEach((key) => {
              setValue(key, data[key])
            })
          }
        } catch (error) {
          console.error('Failed to fetch chat room data:', error)
          toast.error('Failed to load chat room data.')
        }
      }
      fetchChatRoomData()
    }
  }, [chatRoomId, setValue])

  const onSubmit = async (data) => {
    try {
      let id = chatRoomId
      if (!id) {
        id = await chatRoomRepository.addChatRoom(data)
        toast.success('Chat room added successfully!')
      } else {
        await chatRoomRepository.updateChatRoom(id, data)
        toast.success('Chat room updated successfully!')
      }
      navigate('/admin/chatrooms')
    } catch (error) {
      console.error('Form submission error:', error)
      toast.error('Failed to process the form.')
    }
  }

  return (
    <div className='w-full px-4 mt-10'>
      <h2 className='text-3xl font-bold mb-4'>{chatRoomId ? 'Edit Chat Room' : 'Add New Chat Room'}</h2>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-4'>
        {/* Name Input */}
        <div>
          <label htmlFor='name' className='block text-sm font-medium text-gray-700'>
            Name
          </label>
          <input
            {...register('name', { required: true })}
            type='text'
            id='name'
            className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-blue-500 focus:border-blue-500'
            placeholder='Enter chat room name'
          />
          {errors.name && <p className='text-red-500 text-xs italic'>Name is required.</p>}
        </div>

        {/* Description Input */}
        <div>
          <label htmlFor='description' className='block text-sm font-medium text-gray-700'>
            Description
          </label>
          <textarea
            {...register('description')}
            id='description'
            className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-blue-500 focus:border-blue-500'
            rows='3'></textarea>
        </div>

        {/* Category Select */}
        <div>
          <label htmlFor='category' className='block text-sm font-medium text-gray-700'>
            Category
          </label>
          <select
            {...register('category', { required: true })}
            id='category'
            className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-blue-500 focus:border-blue-500'>
            <option value=''>Select a category</option>
            <option value='Campground'>Campground</option>
            <option value='Event'>Event</option>
            <option value='General'>General</option>
            <option value='Local'>Local</option>
            <option value='Regional'>Regional</option>
          </select>
          {errors.category && <p className='text-red-500 text-xs italic'>Category is required.</p>}
        </div>

        {/* Welcome Message Input */}
        <div>
          <label htmlFor='welcomeMessage' className='block text-sm font-medium text-gray-700'>
            Welcome Message
          </label>
          <textarea
            {...register('welcomeMessage')}
            id='welcomeMessage'
            className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-blue-500 focus:border-blue-500'
            rows='3'></textarea>
        </div>

        {/* Mode ToggleSwitch */}
        <div>
          <label className='block text-sm font-medium text-gray-700'>Chat Mode</label>
          <ToggleSwitch
            leftLabel='Clean'
            rightLabel='Dirty'
            checked={watch('mode') === 'Dirty'}
            onChange={() => setValue('mode', watch('mode') === 'Clean' ? 'Dirty' : 'Clean')}
          />
        </div>

        {/* Submit Button */}
        <button
          type='submit'
          className='mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'>
          {chatRoomId ? 'Update Chat Room' : 'Create Chat Room'}
        </button>
      </form>
    </div>
  )
}

export default ChatRoomForm
