import { collection, query, where, orderBy, doc, addDoc, updateDoc, getDoc, limit, deleteDoc, getDocs } from 'firebase/firestore'
import baseRepository from './base.firestore.repository'

const campgroundsCollectionRef = collection(baseRepository.getDb(), 'campgrounds')

let campgroundCache = {
  all: { data: null, timestamp: 0 },
  byId: {},
  bySlug: {},
  byCategory: {},
  duration: 3600000, // Cache duration of 1 hour in milliseconds
}

const isCacheValid = (cacheEntry) => {
  return cacheEntry && Date.now() - cacheEntry.timestamp < campgroundCache.duration && cacheEntry.data
}

const getCampgroundById = async (campgroundId) => {
  if (isCacheValid(campgroundCache.byId[campgroundId])) {
    return campgroundCache.byId[campgroundId].data
  }

  try {
    const docRef = doc(baseRepository.getDb(), 'campgrounds', campgroundId)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const data = { id: docSnap.id, ...docSnap.data() }
      campgroundCache.byId[campgroundId] = { data, timestamp: Date.now() }
      return data
    } else {
      console.log('No such document!')
      return null
    }
  } catch (error) {
    console.error(`Error retrieving campground with ID ${campgroundId}:`, error)
    throw error
  }
}

const getCampgroundBySlug = async (slug) => {
  if (isCacheValid(campgroundCache.bySlug[slug])) {
    return campgroundCache.bySlug[slug].data
  }

  try {
    const q = query(campgroundsCollectionRef, where('slug', '==', slug), limit(1))
    const result = await baseRepository.getDocsFromQuery(q)
    if (result.length > 0) {
      const campground = result[0]
      campgroundCache.bySlug[slug] = { data: campground, timestamp: Date.now() }
      return campground
    } else {
      throw new Error(`Could not find campground with slug ${slug}`)
    }
  } catch (error) {
    console.error(`Error retrieving campground by slug ${slug}:`, error)
    throw error
  }
}

const getCampgroundsByCategory = async (category) => {
  if (isCacheValid(campgroundCache.byCategory[category])) {
    return campgroundCache.byCategory[category].data
  }

  try {
    const q = query(campgroundsCollectionRef, where('category', '==', category))
    const campgrounds = await baseRepository.getDocsFromQuery(q)
    campgroundCache.byCategory[category] = { data: campgrounds, timestamp: Date.now() }
    return campgrounds
  } catch (error) {
    console.error(`Error retrieving campgrounds by category ${category}:`, error)
    throw error
  }
}

const getAllCampgrounds = async () => {
  if (isCacheValid(campgroundCache.all)) {
    return campgroundCache.all.data
  }

  try {
    const q = query(campgroundsCollectionRef, orderBy('name'))
    const campgrounds = await baseRepository.getDocsFromQuery(q)
    campgroundCache.all = { data: campgrounds, timestamp: Date.now() }
    return campgrounds
  } catch (error) {
    console.error('Error retrieving all campgrounds:', error)
    throw error
  }
}

const updateCampground = async (campgroundId, campgroundData) => {
  try {
    const docRef = doc(baseRepository.getDb(), 'campgrounds', campgroundId)
    await updateDoc(docRef, campgroundData)

    // Invalidate relevant cache
    campgroundCache.byId[campgroundId] = null
    campgroundCache.all = null

    return campgroundId
  } catch (error) {
    console.error(`Error updating campground with ID ${campgroundId}:`, error)
    throw error
  }
}

const addCampground = async (campgroundData) => {
  try {
    const docRef = await addDoc(campgroundsCollectionRef, campgroundData)

    // Invalidate all campgrounds cache to refresh data
    campgroundCache.all = null

    return docRef.id // Return the new campground ID
  } catch (error) {
    console.error('Error adding new campground:', error)
    throw error
  }
}

const addReviewToCampground = async (campgroundId, reviewData) => {
  try {
    // Define the path to the reviews subcollection for the given campground
    const reviewsRef = collection(baseRepository.getDb(), `campgrounds/${campgroundId}/reviews`)
    // Add the review document to the subcollection
    const docRef = await addDoc(reviewsRef, reviewData)
    return docRef.id // Optionally return the newly created review's ID
  } catch (error) {
    console.error('Error adding review to campground:', error)
    throw error
  }
}

const deleteReviewFromCampground = async (campgroundId, reviewId) => {
  try {
    // Define the path to the specific review document
    const reviewRef = doc(baseRepository.getDb(), `campgrounds/${campgroundId}/reviews`, reviewId)
    // Delete the review document
    await deleteDoc(reviewRef)
    return reviewId // Optionally return the ID of the deleted review
  } catch (error) {
    console.error(`Error deleting review with ID ${reviewId}:`, error)
    throw error
  }
}

const getReviewsByCampgroundSlug = async (slug) => {
  try {
    // First, get the campground by its slug to find its ID
    const campground = await getCampgroundBySlug(slug)
    if (!campground) {
      console.log('Campground not found')
      return []
    }

    // Define the path to the reviews subcollection for the found campground
    const reviewsRef = collection(baseRepository.getDb(), `campgrounds/${campground.id}/reviews`)
    const q = query(reviewsRef) // Add any specific queries if needed, e.g., orderBy
    const querySnapshot = await getDocs(q)

    // Map over the documents in the querySnapshot to return the review data
    const reviews = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))

    return reviews
  } catch (error) {
    console.error(`Error retrieving reviews for campground with slug ${slug}:`, error)
    throw error
  }
}

const campgroundRepository = {
  getCampgroundById,
  getCampgroundsByCategory,
  getAllCampgrounds,
  addCampground,
  updateCampground,
  getCampgroundBySlug,
  addReviewToCampground,
  deleteReviewFromCampground,
  getReviewsByCampgroundSlug,
}

export default campgroundRepository
