import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import eventsRepository from '../../../../repositories/firestore/events.firestore.repository'
import PageLayout from '../../../shared/components/PageLayout'

const CampgroundAdminEventsList = () => {
  const [events, setEvents] = useState([])
  const { campgroundId } = useParams()

  const fetchEvents = async () => {
    try {
      const fetchedEvents = await eventsRepository.getCampgroundAdminEvents(campgroundId)
      if (Array.isArray(fetchedEvents)) {
        setEvents(fetchedEvents)
      } else {
        // If fetched data is not an array, log an error or handle as appropriate
        console.error('Fetched events is not an array:', fetchedEvents)
        setEvents([]) // Ensure state is set to an empty array to avoid errors
      }
    } catch (error) {
      console.error('Error fetching events:', error)
      setEvents([]) // Ensure state is reset in case of error
    }
  }

  useEffect(() => {
    fetchEvents()
  }, [])

  const deleteEvent = async (eventId) => {
    // Confirm with the user
    const isConfirmed = window.confirm('Are you sure you want to delete this event?')
    if (isConfirmed) {
      try {
        await eventsRepository.deleteEvent(eventId)
        fetchEvents() // Refresh the events list after deletion
        console.log(`Event with ID ${eventId} deleted successfully.`)
      } catch (error) {
        console.error(`Error deleting event with ID ${eventId}:`, error)
      }
    }
  }

  return (
    <PageLayout title={'Manage Campground Events and Themes'}>
      <div className='w-full mt-10'>
        {/* Header and Add New Event Button Container */}
        <div className='flex justify-between items-center mb-4'>
          <div>
            <Link
              to={`/campground-admin/${campgroundId}/event`}
              className='bg-green-500 text-white py-2 px-6 rounded hover:bg-green-700 transition-colors duration-200'>
              Add New Campground Event or Theme
            </Link>
          </div>
        </div>
        <div className='space-y-2'>
          <div className='mb-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
            {events.map((event) => (
              <div className='bg-white rounded-lg shadow-md p-6' key={event.id}>
                <Link to={`/campground-admin/${campgroundId}/event/${event.id}`}>
                  <img src={event.coverImage} alt={event.title} className='w-full h-auto mb-4 rounded-lg' />
                  <h3 className='text-xl font-semibold mb-2'>{event.title}</h3>
                  <p className='text-gray-500 mb-2'>
                    {event.startDate.toDate().toLocaleDateString('en-US')} - {event.endDate.toDate().toLocaleDateString('en-US')}
                  </p>
                  <p className='text-gray-500 mb-4'>{event.location}</p>
                </Link>
                <button
                  onClick={() => deleteEvent(event.id)}
                  className='bg-red-500 text-white py-2 px-6 rounded hover:bg-red-700 transition-colors duration-200 mt-4 md:mt-0'>
                  Delete
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default CampgroundAdminEventsList
