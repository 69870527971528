import React, { useEffect, useState } from 'react'
import EventItem from './EventItem'
import eventsRepository from '../../../../repositories/firestore/events.firestore.repository'

const EventList = ({ count, vertical = false, sinceDate }) => {
  const [events, setEvents] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const containerStyle = vertical ? 'grid grid-cols-1 gap-4' : 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await eventsRepository.getLatestRegionalEvents(count, sinceDate)
        setEvents(data)
        setLoading(false)
      } catch (error) {
        setError(error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <div className={containerStyle}>
      {events &&
        events.map((event) => (
          <EventItem
            key={event.id}
            title={event.title}
            startDate={event.startDate.toDate().toLocaleDateString('en-US')}
            endDate={event.endDate.toDate().toLocaleDateString('en-US')}
            location={event.venue}
            graphicSrc={event.coverImage}
            id={event.id}
          />
        ))}
    </div>
  )
}

export default EventList
