import React from 'react'
import './toggleswitch.css'

const ToggleSwitch = ({ leftLabel, rightLabel, checked, onChange }) => {
  return (
    <div className='toggle-switch'>
      <div className='label label-left'>{leftLabel}</div>
      <div className='switch'>
        <input type='checkbox' checked={checked} onChange={onChange} />
        <span className='slider round'></span>
      </div>
      <div className='label label-right'>{rightLabel}</div>
    </div>
  )
}

export default ToggleSwitch
