import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import threadRepository from '../../../repositories/firestore/thread.firestore.repository'
import { useAuth } from '../../../contexts/AuthContext'
import { toast } from 'react-toastify'

const AddThread = () => {
  const { forumId, threadId } = useParams() // Capturing possible threadId
  const { currentUser, userDetails } = useAuth()
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)

  useEffect(() => {
    if (threadId) {
      setIsEditMode(true)
      threadRepository
        .getThreadById(forumId, threadId)
        .then((thread) => {
          setTitle(thread.title)
          setContent(thread.content)
        })
        .catch((error) => {
          console.error('Error fetching thread:', error)
          setError('Failed to fetch thread details')
        })
    }
  }, [forumId, threadId])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    const threadData = {
      title,
      content,
      userId: currentUser.uid,
      displayName: userDetails.public.displayName || 'Anonymous',
      profilePhotoUrl: userDetails.public.profilePhotoUrl || '',
    }

    try {
      if (isEditMode) {
        await threadRepository.updateThread(forumId, threadId, threadData)
        toast.success('Thread updated successfully!')
        navigate(`/user/forums/${forumId}/threads/${threadId}`)
      } else {
        const newThreadId = await threadRepository.addThread(forumId, threadData)
        toast.success('Thread added successfully!')
        navigate(`/user/forums/${forumId}/threads/${newThreadId}`)
      }
      setTitle('')
      setContent('')
    } catch (error) {
      setError('Failed to save thread')
      console.error('Error saving thread:', error)
    }
    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit} className='p-4'>
      <h2 className='text-xl font-bold mb-4'>{isEditMode ? 'Edit Thread' : 'Add New Thread'}</h2>
      <div className='mb-4'>
        <label htmlFor='title' className='block text-sm font-medium text-gray-700'>
          Thread Title
        </label>
        <input
          type='text'
          id='title'
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
          required
        />
      </div>
      <div className='mb-4'>
        <label htmlFor='content' className='block text-sm font-medium text-gray-700'>
          Content
        </label>
        <textarea
          id='content'
          value={content}
          onChange={(e) => setContent(e.target.value)}
          className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
          required
        />
      </div>
      {error && <p className='mb-4 text-red-500'>{error}</p>}
      <button type='submit' disabled={loading} className='px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded-md'>
        {loading ? (isEditMode ? 'Updating...' : 'Adding...') : isEditMode ? 'Update Thread' : 'Add Thread'}
      </button>
      <button type='button' onClick={() => navigate(`/user/forums/${forumId}`)} className='ml-4 px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-md'>
        Cancel
      </button>
    </form>
  )
}

export default AddThread
