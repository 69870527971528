import React from 'react'
import { Link } from 'react-router-dom'

const BlogPostItem = ({ id, title, slug, author, date, content, imageSrc, alt }) => {
  return (
    <div className='bg-white rounded-lg shadow-md p-6 flex'>
      <img src={imageSrc} alt={alt} className='w-32 h-32 object-cover rounded-lg mr-4' />
      <div>
        <Link to={`/blog/${slug}`}>
          <h3 className='text-xl font-semibold mb-2'>{title}</h3>
        </Link>
        <p className='text-gray-500 text-sm mb-2'>By {author}</p>
        <p className='text-gray-500 text-sm mb-4'>{date}</p>
      </div>
    </div>
  )
}

export default BlogPostItem
