import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import postRepository from '../../../repositories/firestore/post.firestore.repository'

const AddPost = () => {
  const { forumId, threadId } = useParams() // Make sure you're capturing both forumId and threadId
  const { currentUser, userDetails } = useAuth()
  const navigate = useNavigate()
  const [content, setContent] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    try {
      const postData = {
        content,
        userId: currentUser.uid, // User ID from the auth context
        displayName: userDetails.public.displayName || 'Anonymous', // Display name from the auth context or 'Anonymous'
        profilePhotoUrl: userDetails.public.profilePhotoUrl || '', // Profile photo URL from the auth context or empty string
      }

      await postRepository.addPost(forumId, threadId, postData)
      navigate(`/user/forums/${forumId}/threads/${threadId}`) // Navigate back to the post list after adding
    } catch (error) {
      setError('Failed to add post')
      console.error('Error adding post:', error)
    }
    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit} className='p-4'>
      <h2 className='text-xl font-bold mb-4'>Add New Post</h2>
      <div className='mb-4'>
        <label htmlFor='content' className='block text-sm font-medium text-gray-700'>
          Content
        </label>
        <textarea
          id='content'
          value={content}
          onChange={(e) => setContent(e.target.value)}
          className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
          required
        />
      </div>
      {error && <p className='mb-4 text-red-500'>{error}</p>}
      <button type='submit' disabled={loading} className='px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded-md'>
        {loading ? 'Adding...' : 'Add Post'}
      </button>
      <button
        type='button'
        onClick={() => navigate(`/user/forums/${forumId}/threads/${threadId}`)}
        className='ml-4 px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-md'>
        Cancel
      </button>
    </form>
  )
}

export default AddPost
