// Layout Component
import React from 'react'
import Navbar from './Navbar'
import { Link } from 'react-router-dom'

const Layout = ({ children }) => {
  return (
    // Adjust the main container to be a flex column and make sure the footer is pushed to the bottom
    <div className='flex flex-col min-h-screen bg-gray-100'>
      <Navbar />
      {/* Main content area now allows for flexible expansion */}
      <main className='flex-grow w-full md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>{children}</main>
      <footer className='bg-gray-800 text-white py-4 text-center mt-4'>
        <p>&copy; 2024 Gay Camping Friends. All rights reserved.</p>
        <p>
          <Link to='/privacy-policy' className='text-white hover:text-gray-300'>
            Privacy Policy
          </Link>{' '}
          |{' '}
          <Link to='/terms-conditions' className='text-white hover:text-gray-300'>
            Terms and Conditions
          </Link>
        </p>
      </footer>
    </div>
  )
}

export default Layout
