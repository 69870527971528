import React from 'react'
import { Link } from 'react-router-dom'

const EventItem = ({ title, startDate, endDate, location, graphicSrc, id }) => {
  return (
    <div className='bg-white rounded-lg shadow-md p-6'>
      <Link to={`/events/${id}`}>
        <img src={graphicSrc} alt={title} className='w-full h-auto mb-4 rounded-lg' />
        <h3 className='text-xl font-semibold mb-2'>{title}</h3>
        <p className='text-gray-500 mb-2'>
          {startDate} - {endDate}
        </p>
        <p className='text-gray-500 mb-4'>{location}</p>
      </Link>
    </div>
  )
}

export default EventItem
