import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ProfilePhoto from '../../../shared/components/ProfilePhoto'
import { FaTrash } from 'react-icons/fa'
import UserOptions from './UserOptions'
import blockRepository from '../../../../repositories/firestore/block.firestore.repository'

const Message = ({ message, canDelete, onDelete, currentUserId }) => {
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()

  const toggleModal = () => {
    if (message.userId !== currentUserId) {
      setShowModal(!showModal)
    } else {
      console.log("Can't open own profile modal.")
    }
  }

  const handleViewProfile = (userId) => {
    navigate(`/user/profile/${userId}`)
  }

  const handleBlockUser = (userId) => {
    // Confirm dialog before blocking
    if (window.confirm('Are you sure you want to block this user?')) {
      blockRepository
        .addBlock(currentUserId, userId)
        .then(() => {
          console.log('User blocked successfully.')
          setShowModal(false) // Close modal after blocking
        })
        .catch((error) => console.error('Error blocking user:', error))
    }
  }

  return (
    <div className='break-words bg-white p-2 flex items-start justify-between'>
      <div className='flex' onClick={toggleModal}>
        <ProfilePhoto src={message.profilePhotoUrl} displayName={message.displayName} size='10' className='mr-2' />
        <div className='flex flex-col flex-grow min-w-0'>
          <span className='text-sm font-semibold no-underline text-black'>{message.displayName}</span>
          <span className='text-gray-800'>{message.text}</span>
        </div>
      </div>
      {canDelete && (
        <button
          onClick={(e) => {
            e.stopPropagation()
            onDelete(message.id)
          }}
          className='ml-4'>
          <FaTrash className='text-gray-500 hover:text-gray-700 text-sm' />
        </button>
      )}
      <UserOptions isOpen={showModal} user={message} onClose={toggleModal} onViewProfile={handleViewProfile} onBlock={handleBlockUser} />
    </div>
  )
}

export default Message
