import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import forumRepository from '../../../repositories/firestore/forum.firestore.repository'
import { useAuth } from '../../../contexts/AuthContext'
import ForumItem from './ForumItem' // Import the new component
import PageLayout from '../../shared/components/PageLayout'

const ForumList = () => {
  const navigate = useNavigate()
  const [forums, setForums] = useState([])
  const [loading, setLoading] = useState(true)
  const { userDetails } = useAuth()

  useEffect(() => {
    forumRepository
      .getForums()
      .then((forums) => {
        setForums(forums)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Failed to fetch forums', error)
        setLoading(false)
      })
  }, [forumRepository])

  const handleDeleteForum = async (forumId) => {
    if (window.confirm('Are you sure you want to delete this forum?')) {
      try {
        await forumRepository.deleteForum(forumId)
        setForums((prevForums) => prevForums.filter((forum) => forum.id !== forumId)) // Update state
        alert('Forum deleted successfully.')
      } catch (error) {
        console.error('Error deleting forum:', error)
      }
    }
  }

  if (loading) return <div>Loading...</div>

  return (
    <PageLayout>
      <div className='mb-4 p-4 bg-white shadow rounded-lg'>
        <h1 className='text-2xl font-bold mb-2'>Forums (Alpha)</h1>
        <p className='text-gray-700'>
          Select a forum to view threads and post replies. This is under testing right now so let us know what you think! info@gaycampingfriends.com
        </p>
      </div>
      {userDetails.secure.roles && userDetails.secure.roles.includes('admin') && (
        <button onClick={() => navigate('/user/forums/add')} className='mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'>
          Add Forum
        </button>
      )}
      <ul>
        {forums.map((forum) => (
          <ForumItem key={forum.id} forum={forum} onDelete={handleDeleteForum} />
        ))}
      </ul>
    </PageLayout>
  )
}

export default ForumList
