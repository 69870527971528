import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select from 'react-select'
import campgroundRepository from '../../../repositories/firestore/campgrounds.firestore.repository'
import tripRepository from '../../../repositories/firestore/trips.firestore.repository'
import { toast } from 'react-toastify'
import { useAuth } from '../../../contexts/AuthContext'
import UserTrips from './components/UserTrips'
import PageLayout from '../../shared/components/PageLayout'
import { addDays } from 'date-fns'
import userRepository from '../../../repositories/firestore/users.firestore.repository'

function TripForm() {
  const { currentUser } = useAuth()
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm()

  const [campgrounds, setCampgrounds] = useState([])
  const [refreshTrips, setRefreshTrips] = useState(false)

  useEffect(() => {
    const fetchCampgrounds = async () => {
      try {
        const fetchedCampgrounds = await campgroundRepository.getAllCampgrounds()
        const options = fetchedCampgrounds.map((cg) => ({ value: cg.id, label: cg.name, logo: cg.logo }))
        setCampgrounds(options)
      } catch (error) {
        console.error('Error fetching campgrounds:', error)
        toast.error('Failed to fetch campgrounds.')
        setCampgrounds([])
      }
    }

    fetchCampgrounds()
  }, [])

  const startDate = watch('startDate')
  const endDate = watch('endDate')

  useEffect(() => {
    if (startDate && endDate) {
      if (new Date(startDate) > new Date(endDate)) {
        setError('endDate', { type: 'manual', message: 'End date must be after start date' })
      } else if (new Date(endDate) > addDays(new Date(startDate), 30)) {
        setError('endDate', { type: 'manual', message: 'Maximum stay is 30 days' })
      } else {
        clearErrors('endDate')
      }
    }
  }, [startDate, endDate, setError, clearErrors])

  const onSubmit = async (data) => {
    if (new Date(data.startDate) > new Date(data.endDate)) {
      toast.error('Start date must be before end date.')
      return
    }

    try {
      const tripData = {
        userId: currentUser.uid,
        campgroundId: data.campgroundId.value,
        campgroundName: data.campgroundId.label,
        campgroundLogo: data.campgroundId.logo,
        startDate: data.startDate,
        endDate: data.endDate,
      }

      await tripRepository.addTrip(tripData)
      await userRepository.updateUserPointsById(currentUser.uid, 'tripPoints', 5)
      toast.success('Trip added successfully - you earned 5 GCF points.')
      setRefreshTrips(!refreshTrips) // Refresh to show new trip
    } catch (error) {
      console.error('Form submission error:', error)
      toast.error('Failed to process the form.')
    }
  }

  return (
    <PageLayout title='Plan Your Trip'>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-4 bg-white p-4 rounded-lg shadow'>
        <p>
          Please enter the start and end date for when you plan to be at one of the campgrounds in our directory. The maximum length you can add at
          one time is 30 days. If you plan to be there more than 30 days please add multiple trips.
        </p>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
          <div>
            <label htmlFor='campground' className='block text-sm font-medium text-gray-700'>
              Campground
            </label>
            <Controller
              name='campgroundId'
              control={control}
              rules={{ required: 'Campground selection is required' }}
              render={({ field }) => <Select {...field} options={campgrounds} className='mt-1' />}
            />
            {errors.campgroundId && <p className='text-red-500'>Campground selection is required.</p>}
          </div>
          <div>
            <label htmlFor='startDate' className='block text-sm font-medium text-gray-700'>
              Arrive Day
            </label>
            <Controller
              name='startDate'
              control={control}
              rules={{ required: 'Start date is required' }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  wrapperClassName='w-full'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                />
              )}
            />
            {errors.startDate && <p className='text-red-500'>Start date is required.</p>}
          </div>
          <div>
            <label htmlFor='endDate' className='block text-sm font-medium text-gray-700'>
              Leave Day
            </label>
            <Controller
              name='endDate'
              control={control}
              rules={{ required: 'End date is required' }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  minDate={startDate}
                  maxDate={startDate ? addDays(new Date(startDate), 30) : undefined}
                  wrapperClassName='w-full'
                  className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                />
              )}
            />
            {errors.endDate && <p className='text-red-500'>{errors.endDate.message}</p>}
          </div>
        </div>
        <button
          type='submit'
          className='mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
          Add Trip
        </button>
      </form>
      <UserTrips key={refreshTrips} />
    </PageLayout>
  )
}

export default TripForm
