import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import forumRepository from '../../../repositories/firestore/forum.firestore.repository'
import { toast } from 'react-toastify'

const AddForum = () => {
  const { forumId } = useParams() // This will either be undefined or a specific forum ID
  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (forumId) {
      setLoading(true)
      forumRepository
        .getForumById(forumId)
        .then((forum) => {
          if (forum) {
            setName(forum.name)
            setDescription(forum.description)
          }
          setLoading(false)
        })
        .catch((error) => {
          console.error('Error fetching forum details:', error)
          setLoading(false)
          setError('Failed to fetch forum details')
        })
    }
  }, [forumId])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    try {
      if (forumId) {
        await forumRepository.updateForum(forumId, { name, description })
        toast.success('Forum updated successfully!')
      } else {
        await forumRepository.addForum({ name, description })
        toast.success('Forum added successfully!')
      }
      navigate('/user/forums')
    } catch (error) {
      setError('Failed to process forum')
      toast.error('Error processing forum.')
      console.error('Error processing forum:', error)
    }
    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit} className='p-4'>
      <h2 className='text-xl font-bold mb-4'>{forumId ? 'Edit Forum' : 'Add New Forum'}</h2>
      <div className='mb-4'>
        <label htmlFor='name' className='block text-sm font-medium text-gray-700'>
          Forum Name
        </label>
        <input
          type='text'
          id='name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
          required
        />
      </div>
      <div className='mb-4'>
        <label htmlFor='description' className='block text-sm font-medium text-gray-700'>
          Description
        </label>
        <textarea
          id='description'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
          required
        />
      </div>
      {error && <p className='mb-4 text-red-500'>{error}</p>}
      <button type='submit' disabled={loading} className='px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded-md'>
        {loading ? 'Processing...' : forumId ? 'Update Forum' : 'Add Forum'}
      </button>
      <button type='button' onClick={() => navigate('/user/forums')} className='ml-4 px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-md'>
        Cancel
      </button>
    </form>
  )
}

export default AddForum
