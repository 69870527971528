import React from 'react'

const ProfilePhoto = ({ src, displayName, size = '24', className = '' }) => {
  const imageSize = parseInt(size) * 4 // Tailwind's sizing scale
  const combinedClasses = `rounded-full object-cover ${className}`
  const avatarUrl = src || `https://ui-avatars.com/api/?name=${encodeURIComponent(displayName)}&background=random&color=fff&size=128&bold=true`

  return (
    <img
      src={avatarUrl}
      alt={`${displayName}'s Profile Photo`}
      className={combinedClasses}
      style={{ width: `${imageSize}px`, height: `${imageSize}px` }}
    />
  )
}

export default ProfilePhoto
