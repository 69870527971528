import React from 'react'
import PageLayout from '../../shared/components/PageLayout'

const About = () => {
  return (
    <PageLayout
      title='About Gay Camping Friends'
      metaTitle={'About Gay Camping Friends'}
      metaDescription={'About Gay Camping Friends, the Gay Camping Friends Mission, Learn more about GCF.'}>
      <p>More information coming soon.</p>
    </PageLayout>
  )
}

export default About
