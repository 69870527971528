// messageUtils.js

// Banned words lists for different chat modes
const cleanModeBannedWords = [
  'cum',
  'fuck',
  'cock',
  'whatsapp',
  'tele',
  'telegram',
  't.me', // Add more words as needed for a stricter environment
]

const dirtyModeBannedWords = [
  'tele',
  'telegram',
  'whatsapp',
  't.me', // Words that are somewhat inappropriate but allowed in a less strict environment
]

// Function to check if a message contains banned words
export const isProfane = (text, chatMode) => {
  let bannedWords = []
  if (chatMode === 'Clean') {
    bannedWords = cleanModeBannedWords
  } else if (chatMode === 'Dirty') {
    bannedWords = dirtyModeBannedWords
  }

  return bannedWords.some((word) => text.toLowerCase().includes(word))
}

// Function to validate messages before sending
export const validateMessage = (text, chatMode) => {
  if (text.trim() === '' || text.length > 500) {
    alert('Please ensure your message is not empty and is under 500 characters.')
    return null
  }

  if (isProfane(text, chatMode)) {
    alert('Your message contains inappropriate language.')
    return null
  }

  return text // Return the sanitized text
}

// Sanitize HTML in the message to prevent XSS attacks
export const sanitizeHTML = (text) => {
  const tagBody = '(?:[^"\'>]|"[^"]*"|\'[^\']*\')*'
  const tagOrComment = new RegExp(
    '<(?:' +
      // Comment body.
      '!--(?:(?:-*[^->])*--+|-?)' +
      // Special "raw text" elements whose content should be elided.
      '|script\\b' +
      tagBody +
      '>[\\s\\S]*?</script\\s*' +
      '|style\\b' +
      tagBody +
      '>[\\s\\S]*?</style\\s*' +
      // Regular name
      '|/?[a-z]' +
      tagBody +
      ')>',
    'gi',
  )
  let oldText
  do {
    oldText = text
    text = text.replace(tagOrComment, '')
  } while (text !== oldText)
  return text.replace(/</g, '&lt;')
}

// Prevent URLs in messages
export const preventURLs = (text) => {
  const urlRegex = /https?:\/\/[^\s]+/g
  if (urlRegex.test(text)) {
    alert('Sending URLs is not allowed.')
    return null
  }
  return text
}

// Detect repeated messages or spam-like patterns
export const isSpam = (currentMessage, lastMessage) => {
  return lastMessage && currentMessage === lastMessage
}

// Function to trim text to a specified length without cutting words
export const trimText = (text, maxLength) => {
  if (text.length <= maxLength) return text

  // Find the last space within maxLength
  let trimmedText = text.substr(0, maxLength)
  trimmedText = trimmedText.substr(0, Math.min(trimmedText.length, trimmedText.lastIndexOf(' ')))

  return trimmedText + '...' // Append ellipsis to indicate text has been cut off
}
