import React, { useEffect, useState } from 'react'
import tripRepository from '../../../../repositories/firestore/trips.firestore.repository'
import userRepository from '../../../../repositories/firestore/users.firestore.repository'
import { useAuth } from '../../../../contexts/AuthContext'

const UserTrips = () => {
  const { currentUser } = useAuth()
  const [plannedTrips, setPlannedTrips] = useState([])
  const [pastTrips, setPastTrips] = useState([])

  useEffect(() => {
    fetchTrips()
  }, [currentUser])

  const fetchTrips = async () => {
    if (currentUser) {
      const userTrips = await tripRepository.getTripsByUserId(currentUser.uid)
      const now = new Date()

      const planned = userTrips
        .filter((trip) => new Date(trip.startDate.seconds * 1000) >= now)
        .sort((a, b) => a.startDate.seconds - b.startDate.seconds) // Sort planned trips by soonest start date

      const past = userTrips.filter((trip) => new Date(trip.endDate.seconds * 1000) < now).sort((a, b) => b.startDate.seconds - a.startDate.seconds) // Sort past trips by most recent start date

      setPlannedTrips(planned)
      setPastTrips(past)
    }
  }

  // Function to convert Firestore Timestamp to JavaScript Date object
  const convertTimestampToDate = (timestamp) => {
    return timestamp ? new Date(timestamp.seconds * 1000) : null
  }

  const deleteTrip = async (tripId) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this trip?')
    if (isConfirmed) {
      try {
        await tripRepository.deleteTrip(tripId)
        await userRepository.updateUserPointsById(currentUser.uid, 'tripPoints', -5)
        fetchTrips() // Refresh the trips list after deletion
      } catch (error) {
        console.error(`Error deleting trip with ID ${tripId}:`, error)
      }
    }
  }

  const renderTrips = (trips) => (
    <div className='space-y-4'>
      {trips && trips.length > 0
        ? trips.map((trip, index) => (
            <div key={index} className='bg-white rounded-lg shadow p-4 grid grid-cols-1 md:grid-cols-12 gap-4 items-center'>
              <div className='flex items-center md:col-span-9 space-x-4'>
                <img
                  src={trip.campgroundLogo || 'default-campground.png'}
                  alt={trip.campgroundName}
                  className='w-16 h-16 object-cover rounded-full'
                />
                <div>
                  <h3 className='text-lg font-semibold'>{trip.campgroundName}</h3>
                  <p>Arrive: {trip.startDate ? convertTimestampToDate(trip.startDate).toDateString() : 'N/A'}</p>
                  <p>Leave: {trip.endDate ? convertTimestampToDate(trip.endDate).toDateString() : 'N/A'}</p>
                </div>
              </div>
              <div className='md:col-span-3 flex justify-end'>
                <button
                  onClick={() => deleteTrip(trip.id)}
                  className='bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 transition-colors duration-200'>
                  Delete
                </button>
              </div>
            </div>
          ))
        : 'No Trips to Display'}
    </div>
  )

  return (
    <div className='mt-10'>
      <h2 className='text-2xl font-bold mb-4'>My Trips</h2>
      <div>
        <h3 className='text-xl font-bold mb-2'>Planned Trips</h3>
        {renderTrips(plannedTrips)}
      </div>
      <div>
        <h3 className='text-xl font-bold mb-2 mt-3'>Past Trips</h3>
        {renderTrips(pastTrips)}
      </div>
    </div>
  )
}

export default UserTrips
