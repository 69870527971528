import React from 'react'
import ThisWeekEventList from './components/ThisWeekEventList'
import PageLayout from '../../shared/components/PageLayout'

const ThisWeekEvents = () => {
  return (
    <PageLayout
      title='Campground Events and Themes by Week'
      metaTitle={'All Gay Campground Events Per Week'}
      metaDescription={'View a calendar of the events at all gay campgrounds combined by week.'}>
      <ThisWeekEventList />
    </PageLayout>
  )
}

export default ThisWeekEvents
