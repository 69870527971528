import React, { useState } from 'react'

const ImageGallery = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(null)

  const handleClick = (image) => {
    setSelectedImage(image)
  }

  return (
    <div className='image-gallery grid grid-cols-3 gap-4'>
      {images.map((image, index) => (
        <div key={index} className='image-thumbnail bg-gray-200 rounded overflow-hidden aspect-w-1 aspect-h-1' onClick={() => handleClick(image)}>
          <div className='w-full h-full'>
            <img src={image} alt='Campground Thumbnail' className='object-cover w-full h-full' />
          </div>
        </div>
      ))}
      {selectedImage && (
        <div className='image-modal fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 z-50'>
          <img src={selectedImage} alt='Expanded Graphic' className='w-full h-auto max-h-[500px]' />
          <button onClick={() => setSelectedImage(null)} className='mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600'>
            Close
          </button>
        </div>
      )}
    </div>
  )
}

export default ImageGallery
