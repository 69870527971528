const categories = [
  {
    id: 1,
    slug: 'lgbtq-campgrounds',
    name: 'LGBTQ+ Campgrounds',
  },
  {
    id: 2,
    slug: 'gay-campgrounds',
    name: 'Gay Campgrounds',
  },
  {
    id: 3,
    slug: 'lesbian-campgrounds',
    name: 'Lesbian Campgrounds',
  },
  {
    id: 4,
    slug: 'lgbtq-owned-campgrounds',
    name: 'LGBTQ+ Owned Campgrounds',
  },
]

async function GetCategories() {
  return categories
}

async function GetCategoryById(id) {
  let result = categories.find((category) => category.id == id)
  return result
}

async function GetCategoryBySlug(slug) {
  let result = categories.find((category) => category.slug == slug)
  return result
}

const repository = { GetCategories, GetCategoryById, GetCategoryBySlug }

export default repository
