import React, { useState, useEffect } from 'react'
import userRepository from '../../../../../repositories/firestore/users.firestore.repository'
import ProfilePhoto from '../../../../shared/components/ProfilePhoto'

const CalendarDisplay = ({ summary }) => {
  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const [selectedDay, setSelectedDay] = useState(null)
  const [userDetails, setUserDetails] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  // Create rows for the calendar
  const rows = []
  let currentWeek = []

  if (summary.length > 0) {
    const firstDay = new Date(summary[0].date + 'T00:00:00Z')
    const dayOfWeek = firstDay.getUTCDay() // Get the day of the week for the first day
    currentWeek = Array(dayOfWeek).fill(null) // Fill the first week array with nulls until the first day
  }

  summary.forEach((day) => {
    const date = new Date(day.date + 'T00:00:00Z')
    const dayOfWeek = date.getUTCDay()

    if (dayOfWeek === 0 && currentWeek.length > 0) {
      rows.push(currentWeek)
      currentWeek = []
    }
    currentWeek.push(day)
  })

  // Fill the remainder of the last week with nulls
  while (currentWeek.length < 7) {
    currentWeek.push(null)
  }
  rows.push(currentWeek)

  function getBackgroundColor(day) {
    if (!day) return 'bg-gray-100' // Return a default background for empty cells
    const count = day.count
    switch (count) {
      case 0:
        return 'bg-gray-100'
      case 1:
        return 'bg-green-100'
      case 2:
        return 'bg-green-200'
      case 3:
        return 'bg-green-300'
      case 4:
        return 'bg-green-400'
      default:
        return 'bg-green-500'
    }
  }

  useEffect(() => {
    const fetchUserData = async () => {
      if (selectedDay && selectedDay.userIds.length > 0) {
        setIsLoading(true)
        try {
          const users = await Promise.all(selectedDay.userIds.map((userId) => userRepository.getUserPublicDataById(userId)))
          const userMap = {}
          users.forEach((user) => {
            userMap[user.id] = user
          })
          setUserDetails(userMap)
        } catch (error) {
          console.error('Failed to fetch user data:', error)
        } finally {
          setIsLoading(false)
        }
      } else {
        setUserDetails({})
      }
    }

    fetchUserData()
  }, [selectedDay])

  const handleDayClick = (day) => {
    setSelectedDay(day)
  }

  return (
    <>
      <div className='trip-weekdays grid grid-cols-7 gap-1 text-center font-bold bg-gray-200 p-2 rounded'>
        {weekdays.map((day) => (
          <div key={day} className='trip-weekday py-2'>
            {day}
          </div>
        ))}
      </div>
      {rows.map((week, index) => (
        <div key={index} className='trip-week grid grid-cols-7 gap-1'>
          {week.map((day, i) => (
            <div
              key={i}
              className={`trip-day md:p-2 sm:p-0 text-center hover:bg-blue-100 rounded ${getBackgroundColor(day)}`}
              onClick={() => handleDayClick(day)}>
              {day ? (
                <div>
                  <div className='font-semibold'>{new Date(day.date + 'T00:00:00Z').getUTCDate()}</div>
                  <div className='text-xs text-gray-600'>
                    {day.count > 0 ? (
                      <>
                        <div>{day.count}</div>
                        <div>Going</div>
                      </>
                    ) : (
                      <>
                        <div>‎ </div>
                        <div>‎ </div>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
      ))}
      {selectedDay && (
        <div className='mt-4 p-4 bg-white rounded shadow'>
          <h3 className='text-lg font-bold'>
            Members Going on{' '}
            {new Date(selectedDay.date + 'T00:00:00Z').toLocaleDateString('en-US', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </h3>
          <div className='space-y-4 mt-2'>
            {selectedDay.userIds
              .map((userId) => userDetails[userId])
              .filter((user) => user)
              .sort((a, b) => a.displayName.localeCompare(b.displayName))
              .map((user) => (
                <div key={user.id} className='flex items-center'>
                  <div className='profile-photo-container mr-4'>
                    <ProfilePhoto src={user.profilePhotoUrl} displayName={user.displayName} size='16' />
                  </div>
                  <div className='flex-grow'>
                    <p className='text-sm md:text-base'>
                      <a href={`/user/profile/${user.id}`} className='no-underline text-black hover:text-blue-600'>
                        <strong className='font-semibold'>{user.displayName}</strong>
                      </a>
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  )
}

export default CalendarDisplay
