import storageRepository from './base.storage.repository'

export const uploadEventCoverImage = async (file, eventId) => {
  if (!file) throw new Error('No file provided for upload.')
  const directory = `events/coverImages/${eventId}`
  const filePath = `${directory}/${file.name}`
  const downloadURL = await storageRepository.uploadFile(filePath, file)
  return downloadURL
}

export const uploadEventImage = async (file, eventId) => {
  if (!file) throw new Error('No file provided for upload.')
  const directory = `events/images/${eventId}`
  const filePath = `${directory}/${file.name}`
  const downloadURL = await storageRepository.uploadFile(filePath, file)
  return downloadURL
}

export const deleteEventImage = async (filePath) => {
  try {
    await storageRepository.deleteFile(filePath)
    return true // Return true or some success indicator
  } catch (error) {
    console.error(`Error deleting image: ${filePath}`, error)
    throw error // Propagate the error or handle it as needed
  }
}
