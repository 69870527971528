import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import eventsRepository from '../../../../repositories/firestore/events.firestore.repository'
import campgroundRepository from '../../../../repositories/firestore/campgrounds.firestore.repository'
import PageLayout from '../../../shared/components/PageLayout'
import 'react-quill/dist/quill.snow.css' // import the styles

const CampgroundEventDetails = (params) => {
  let { id } = useParams()

  const [event, setEvent] = useState(null)
  const [campground, setCampground] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const fetchData = async () => {
    try {
      const eventData = await eventsRepository.getEventById(id)
      if (eventData) {
        setEvent(eventData)
        const campgroundData = await campgroundRepository.getCampgroundById(eventData.campgroundId)
        if (campgroundData) {
          setCampground(campgroundData)
        } else {
          throw new Error('Campground not found')
        }
      } else {
        throw new Error('The requested event could not be found.')
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    scrollToTop()
    fetchData()
  }, [])

  useEffect(() => {
    scrollToTop()
    fetchData()
  }, [id])

  if (loading) {
    return <div>Loading Event...</div>
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <>
      <PageLayout metaTitle={`${campground.name} Events - ${event.title}`} metaDescription={event.description} metaImage={event.coverImage}>
        {event && campground && (
          <div className='max-w-7xl flex flex-col space-y-4'>
            <div className='flex flex-col px-4'>
              <div className='flex flex-col-reverse md:flex-row md:justify-between items-center'>
                <div className='flex flex-col items-center md:items-start'>
                  <h2 className='text-3xl font-bold mb-4'>{event.title}</h2>
                  <div className='mb-2 text-xl text-gray-600'>
                    <Link to={`/campground/${campground.slug}`} className='text-blue-600'>
                      {campground.name}
                    </Link>{' '}
                    | {event.startDate.toDate().toLocaleDateString('en-US')}-{event.endDate.toDate().toLocaleDateString('en-US')}
                  </div>
                </div>
              </div>
            </div>
            <div className='container mx-auto'>
              <div className='flex flex-col md:flex-row'>
                <div className='flex flex-col w-full'>
                  <div className='mx-auto w-full py-6 px-4 sm:px-6 lg:px-8 bg-white rounded-lg shadow-md mb-4'>
                    <div className='w-full flex justify-center'>
                      <img src={event.coverImage} alt={event.title} className='mb-6 rounded-lg shadow-lg' />
                    </div>
                    <div className='prose lg:prose-xl mb-8 flex flex-col space-y-4' dangerouslySetInnerHTML={{ __html: event.description }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </PageLayout>
    </>
  )
}

export default CampgroundEventDetails
