import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import chatRepository from '../../../../repositories/firestore/chat.firestore.repository'
import chatRoomRepository from '../../../../repositories/firestore/chatRoom.firestore.repository'
import { useAuth } from '../../../../contexts/AuthContext'
import { FaInfoCircle, FaArrowLeft } from 'react-icons/fa'
import ChatAdComponent from '../../../shared/components/ChatAdComponent'
import './chatroom.css'
import InfoModal from './InfoModal'
import { validateMessage, preventURLs, sanitizeHTML, isSpam } from '../../../../utils/messageUtils'
import Message from './Message'
import blockRepository from '../../../../repositories/firestore/block.firestore.repository'

const ChatRoom = ({ chatId }) => {
  const navigate = useNavigate()
  const { currentUser, userDetails } = useAuth()
  const [chatRoom, setChatRoom] = useState({})
  const [messages, setMessages] = useState([])
  const [newMessage, setNewMessage] = useState('')
  const messagesEndRef = useRef(null)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const lastMessage = useRef('')
  const [isSending, setIsSending] = useState(false) // State to control button disable
  const sendTimeoutRef = useRef(null)
  const messageCount = useRef(0) // Counter for messages sent by the user
  const [adKey, setAdKey] = useState(Date.now()) // State to control ad refresh
  const [blockedUsers, setBlockedUsers] = useState(new Set()) // Store blocked users
  const [usersWhoBlockedMe, setUsersWhoBlockedMe] = useState(new Set()) // Store users who blocked the current user

  const toggleInfoModal = () => setShowInfoModal(!showInfoModal)

  useEffect(() => {
    const fetchInitialMessages = async () => {
      try {
        const fetchedMessages = await chatRepository.fetchRecentMessages(chatId)
        const roomDetails = await chatRoomRepository.getChatRoomById(chatId)
        const { blockedIds, blockerIds } = (await blockRepository.loadBlocks(currentUser.uid)) || { blockedIds: new Set(), blockerIds: new Set() } // Provide default values

        if (roomDetails.welcomeMessage) {
          fetchedMessages.unshift({
            id: 'welcome-message',
            displayName: 'Welcome Message',
            text: roomDetails.welcomeMessage,
            profilePhotoUrl: '',
            timestamp: new Date(),
          })
        }

        setBlockedUsers(blockedIds)
        setUsersWhoBlockedMe(blockerIds)
        setMessages(fetchedMessages.reverse())
        setChatRoom(roomDetails)
        scrollToBottom()
      } catch (error) {
        console.error('Error fetching messages:', error)
      }
    }

    fetchInitialMessages()

    const unsubscribe = chatRepository.subscribeToMessages(chatId, async (update) => {
      if (update.type === 'added') {
        // Check and refresh block lists only if needed
        const { blockedIds, blockerIds } = await blockRepository.loadBlocks(currentUser.uid) // Optionally, optimize to avoid this call on every message
        setBlockedUsers(new Set(blockedIds))
        setUsersWhoBlockedMe(new Set(blockerIds))

        setMessages((prevMessages) => {
          const existingIds = new Set(prevMessages.map((msg) => msg.id))
          if (!existingIds.has(update.message.id) && !blockedUsers.has(update.message.userId) && !usersWhoBlockedMe.has(update.message.userId)) {
            return [...prevMessages, update.message].sort((a, b) => a.timestamp.seconds - b.timestamp.seconds)
          }
          return prevMessages
        })
      } else if (update.type === 'removed') {
        setMessages((prevMessages) => prevMessages.filter((message) => message.id !== update.id))
      }
      scrollToBottom()
    })

    return () => {
      unsubscribe()
      if (sendTimeoutRef.current) clearTimeout(sendTimeoutRef.current)
    }
  }, [chatId]) // Update the dependency to use `chatId`

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleSendMessage = async (e) => {
    e.preventDefault()

    if (isSending) return // Prevent sending if already in the process

    setIsSending(true) // Disable the send button

    if (isSpam(newMessage, lastMessage.current)) {
      alert('Please do not spam the chat.')
      setIsSending(false)
      return
    }

    const chatMode = chatRoom?.mode || 'Clean'
    let cleanMessage = validateMessage(newMessage, chatMode)
    cleanMessage = cleanMessage && preventURLs(cleanMessage) && sanitizeHTML(cleanMessage)

    if (cleanMessage) {
      const messageData = {
        text: cleanMessage,
        userId: currentUser.uid,
        displayName: userDetails.public.displayName || 'Anonymous',
        profilePhotoUrl: userDetails.public.profilePhotoUrl || '',
        timestamp: new Date(),
      }

      try {
        await chatRepository.postMessage(chatId, messageData)
        lastMessage.current = newMessage // Update the last message sent
        setNewMessage('')
        scrollToBottom()

        // Ad refresh logic
        messageCount.current++
        if (messageCount.current >= 10) {
          messageCount.current = 0 // Reset the counter
          setAdKey(Date.now()) // Update the adKey to trigger ad refresh
        }
      } catch (error) {
        console.error('Error sending message:', error)
      }
    }

    // Re-enable the send button after a delay
    sendTimeoutRef.current = setTimeout(() => {
      setIsSending(false)
    }, 2000) // Adjust the delay as needed
  }

  const handleDeleteMessage = async (messageId) => {
    if (window.confirm('Are you sure you want to delete this message?')) {
      try {
        await chatRepository.deleteMessage(chatId, messageId)
        setMessages((prevMessages) => prevMessages.filter((message) => message.id !== messageId))
      } catch (error) {
        console.error('Error deleting message:', error)
      }
    }
  }

  const handleCloseChat = () => {
    navigate('/user/chat')
  }

  const canDeleteMessage = (message) => {
    return message.userId === currentUser.uid || (userDetails.secure.roles && userDetails.secure.roles.includes('admin'))
  }

  const filteredMessages = messages.filter((message) => !blockedUsers.has(message.userId) && !usersWhoBlockedMe.has(message.userId)) // Filter messages from blocked users or users who have blocked the current user

  return (
    <div className='bg-white p-4 flex flex-col h-full fixed inset-0 md:relative md:inset-0 md:flex-1'>
      <div className='flex-none flex items-center'>
        {/* Back button is first on the left */}
        <button onClick={handleCloseChat} className='md:hidden p-2 rounded-full text-blue-500 hover:bg-blue-100'>
          <FaArrowLeft size={20} />
        </button>
        {/* Chat room title next to the button */}
        <h1 className='text-xl font-bold ml-2'>
          {chatRoom ? chatRoom.name : 'Loading...'} {chatRoom && chatRoom.mode === 'Dirty' ? '(NSFW)' : ''}
        </h1>
        <div className='ml-auto'>
          <button onClick={toggleInfoModal} className='p-2 rounded-full text-blue-500 hover:bg-blue-100'>
            <FaInfoCircle size={20} />
          </button>
        </div>
      </div>
      <div className='flex-grow overflow-y-auto p-2'>
        {filteredMessages.map((message) => (
          <Message
            key={message.id}
            message={message}
            canDelete={canDeleteMessage(message)}
            onDelete={handleDeleteMessage}
            currentUserId={currentUser.uid} // Assuming currentUser is your authenticated user object
          />
        ))}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={handleSendMessage} className='flex-none flex items-center gap-2'>
        <input
          type='text'
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          className='flex-grow p-2 border border-gray-300 rounded'
          placeholder='Type a message...'
          maxLength='500' // Limit characters to 500
        />
        <button
          type='submit'
          className={`text-white px-4 py-2 rounded ${isSending ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}
          disabled={isSending}>
          {isSending ? 'Wait' : 'Send'}
        </button>
      </form>
      <div className='text-left text-sm px-2'>{`${newMessage.length}/500`}</div>

      <div className='chat-ad-container'>
        <div>
          <ChatAdComponent slot='6518132610' key={adKey} />
        </div>
      </div>

      {/* Info Modal */}
      <InfoModal
        isOpen={showInfoModal}
        onClose={toggleInfoModal}
        title='Chat Room Information'
        content={chatRoom?.description || 'No additional information.'}
      />
    </div>
  )
}

export default ChatRoom
